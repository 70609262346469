export const Stores = [
  {label: 'HDS', value: 'hds'},
  {label: 'Ashley', value: 'ashley'},
  {label: 'Ashley Outlet', value: 'ashley_outlet'}
];

export enum Store {
  hds = 'hds',
  ashley = 'ashley',
  ashley_outlet = 'ashley_outlet'
}

export const Modules = ["panel", "arqueo", "agenda", "users", "history", "tags"];

export const ModuleTranslation: {[key: string]: string} = {
  panel: "Panel",
  users: "Usuarios",
  history: "Historial",
  arqueo: "Arqueo",
  agenda: "Agenda",
  tags: "Etiquetas"
}

export const Logos: {[key: string]: string} = {
  hds: "/assets/images/hds.png",
  ashley: "/assets/images/ashley.png",
  ashley_outlet: "/assets/images/ashley.png"
}

export const DATE_ES_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface SelectOption {
  label: string;
  value: any;
}

/******************** Users ********************************/

export interface User {
  id: number;
  id_dpto?: number;
  _dpto?: string;
  name: string;
  email: string;
  pwd?: string;
  pwd_confirm?: string;
  status?: string;
  access?: string[];
}

export interface Access {
  type: string,
  label: string,
  active: boolean
}

export interface Accesses {
  [key: string]: Access[]
}

/******************** DEPTOS ********************************/

export interface Dpto {
  id: number;
  name: string;
  _users: User[];
}

/******************** HISTORY ********************************/

export interface HistoryRecord {
  id: number,
  author: string,
  subject: string,
  module: string,
  description?: string,
  creation: string
}

/******************** ARQUEO ********************************/

export interface Sale {
  id: number,
  id_sb: string,
  invoice: number,
  store: Store,
  total: string,
  paid: string,
  paid_same_date?: string,
  paid_in_advance?: string,
  paid_same_month?: string,
  paid_with_gc?: number;
  paid_with_nc?: number;
  cxc?: string, //amount not paid in same day than sales date
  cxc_month?: string, //amount not paid in same month than sales date
  tax?: string,
  tax2?: string,
  shipping_fee?: string,
  status: number,
  id_customer: string,
  attention: string,
  attentions_total?: number;
  discount?: string,
  user: string,
  shipped?: string,
  commission?: string,
  name?: string,
  public_note?: string,
  paid_date?: string,
  shipping_date?: string,
  created: string,
  customer?: Customer,
  customer_name: string,
  items?: Item[],
  items_price?: string,
  items_price_no_dicount?: string,
  items_other?: Item[],
  items_other_sum?: number,
  transactions: Transaction[],
  salesPersons: {[key: string]: string},
  transactions_paid_same_date?: Transaction[],
  transactions_paid_same_month?: Transaction[],
  transactions_gc?: Transaction[],
  transactions_nc?: Transaction[],
  saf_cancelled_total?: number,
  saf_total?: number,
  saf_transactions?: Transaction[],
  expanded?: boolean,
  totalPromo?: number,
  stype?: string,
  samount?: string,
  sale_type?: SaleType
}

export enum SaleType {
  items = 'items',
  services = 'services'
}

export const SaleSTypes = [
  {label: 'Ninguno', value: ''},
  {label: 'Sin Comisión', value: 'scommission'},
  {label: 'Contract', value: 'contract'}
]

export const InvoiceStatus: {[key: number]: string} = {
  15: 'Cancelado', //Cancelled
  11: 'Pagado', //paid in full,
  9: 'No pagado' //unpaid
}

export enum InvoiceStatuses {
  Cancelled = 15,
  Paid = 11,
  NOT_PAID = 9
}

export const InvoiceStatusLabel: {[key: number]: string} = {
  15: 'label-danger', //Cancelled
  11: 'label-success', //paid in full,
  9: 'label-warning' //unpaid
}

// export const PaymentTypes: string[] = [
//   'WF Zelle', 'BB Banorte Visa MX', 'BB Banorte Visa', 'Check WF', 'Transfer Banorte BB MX', 'Banorte Transfer', 'Check BBVA', 'Cash USD', 'Check Santander', 'Cash MX', 'WF Transfer', 'Cash', 'Check Chase', 'Paypal Visa', 'Vía nomina', 'Check JPMORGAN', 'Check Bank Of America', 'Check BBVA', 'Check BMO Harris Bank', 'Stripe Visa'
// ];
export const PaymentTypes: string[] = [
  'cash', 'nc', 'wf_trans', 'mx_trans', 'us_check', 'mx_check', 'cc_bb', 'cc_ps', 'cc_stripe', 'cc_paypal', 'unknown'
];

export const SaldoOriginLabels: {[key:string]: string} = {
  'gc': 'Gift Card',
  'modification': 'Modificación',
  'cancellation': 'Cancelación',
  'vc': 'Vale de caja',
  'other': 'Otro'
}

export interface SalesSum {
  shipping: number,
  tax: number,
  net: number,
  advance: number,
  cxc: number,
  discount: number,
  saf: number,
  scommission: number,
  contract: number,
  gcnc: number,
  services: number
}

export interface TableTotals {
  type: string,
  total: number
}

export interface Transaction {
  sale_id: string,
  reference?: string,
  amount: string,
  date: string,
  client?: string
  invoice?: number,
  type?: string
}

export interface Item {
  sale_id: string;
  name?: string;
  price: string;
  price_fixed: string;
  price_discounted: string;
  price_discounted_fixed: string;
  type?: string;
  quantity?: number;
  id_item?: string;
  id_sb?: string; //item sb id on invoice
  url_s?: string;
  url_m?: string;
  url?: string;
  location_name?: string;
  location_alias?: string;
  _selected?: number;
  _available?: number;
}

export interface ItemShort {
  item_id: string;
  item_sale_id: string;
  quantity: number;
}

export interface Customer {
  sale_id: string,
  name: string,
  email?: string,
  vat?: string,
  phone?: string
}

export interface ExchangeRate {
  date: string,
  price: string
}

export interface Client {
  id?: number,
  name: string,
  vat: string,
  email: string,
  phone: string,
  created_at?: string,
  transactions?: SaldoTransaction[],
  transactionsSum?: number,
  expanded?: boolean
}

export interface SBCustomer {
  id: number,
  store: Store,
  name: string,
  vat: string,
  email: string,
  phone: string,
  modified: string,
  last_sync: string
}

export enum SaldoOrigin {
  'gc' = 'gc', 
  'modification' = 'modification', 
  'cancellation' = 'cancellation',
  'vc' = 'vc',
  'other' = 'other'
}

export enum SaldoType {
  'charge' = 'charge', 
  'deposit' = 'deposit'
}

export enum SaldoStatus {
  'active' = 'active',
  'cancelled' = 'cancelled'
}

// export interface SAF {
//   gc?: SaldoTransaction[],
//   modification?: SaldoTransaction[],
//   cancellation?: SaldoTransaction[],
//   vc?: SaldoTransaction[],
//   other?: SaldoTransaction[]
// }

export interface SaldoTransaction {
  id?: number,
  client_id: number,
  client_name?: string,
  invoice: number,
  date: string,
  date_formated?: string,
  amount: string,
  type: SaldoType,
  store: Store,
  type_label?: string,
  origin?: SaldoOrigin,
  origin_label?: string,
  reference?: string,
  created_at?: string,
  isCharge?: boolean,
  by_user?: number,
  status?: SaldoStatus
}

export const MOMENT_FORMAT_DATE = "YYYY-MM-DD";
export const MOMENT_FORMAT_DATE_READABLE = "MMM DD YYYY";
export const MOMENT_FORMAT_TIME = "HH:mm";