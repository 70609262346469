import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faAddressBook, faSpinner } from '@fortawesome/free-solid-svg-icons';


import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { SBCustomer, Store, Stores } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { ArqueoService } from '../../arqueo.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-clients-import',
  templateUrl: './clients-import.component.html',
  styleUrls: ['./clients-import.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class ClientsImportComponent implements OnInit {

  loading = false;
  importing = false;
  clientsIcon = faAddressBook;
  loadingIcon = faSpinner;
  filters = {
    date: new FormControl(),
    dateFormated: '',
    store: Store.hds
  };
  stores = [...Stores];
  searchText = "";
  customersNames: string[] = [];
  searchSubscription?: Subscription;
  search$: Subject<any> = new Subject();
  clientInput = '';
  searchControl = new FormControl();
  customers: SBCustomer[] = [];
  selectedCustomers: SBCustomer[] = [];
  importResults: any = null;

  constructor(
    public commonService: CommonService,
    private arqueoService: ArqueoService,
    private messageService: MessageService
  ) { }



  ngOnInit(): void {
    //this.initSearchSubscription();

    // this.searchControl.valueChanges.pipe(
    //   filter(value => value !== '')
    // ).subscribe(name => {
    //   this.search$.next(name);
    // })
    this.getCustomers();
  }

  getCustomers() {
    this.loading = true;
    this.arqueoService.getSBCustomers()
      .subscribe((customers: SBCustomer[]) => {
        this.customers = customers;
        this.loading = false;
      }, error => {
        this.loading = false;
        this.commonService.showErrorMessage(error, this.messageService);
      });
  }

  // initSearchSubscription() {
  //   this.searchSubscription = this.search$.pipe(
  //     debounceTime(800),
  //     distinctUntilChanged(),
  //     switchMap((searchText: string) => this.arqueoService.sbQuicksSearchCustomer(searchText))
  //   ).subscribe((customers: string[]) => {
  //     this.customersNames = customers;
  //   }, error => {
  //     this.commonService.showErrorMessage(error, this.messageService);
  //   });
  // }

  // onSearch() {
  //   this.loading = true;
  //   this.filters.dateFormated = (this.filters.date.value) ? moment(this.filters.date.value).format("YYYY-MM-DD") : "";
  //   this.arqueoService.sbSearchCustomer(this.searchControl.value, this.filters.dateFormated)
  //     .subscribe((customers: SBCustomer[]) => {
  //       this.customers = customers;
  //       this.loading = false;
  //     }, error => {
  //       this.loading = false;
  //       this.commonService.showErrorMessage(error, this.messageService);
  //     });
  // }

  onImportCustomers() {
    const duplicated = this.getDuplicated();
    if (duplicated === 0) {
      this.importing = true;
      this.loading = true;
      const ids = this.selectedCustomers.map(customer => customer.id);
      this.arqueoService.importCustomers(ids).subscribe((response: any) => {
        //{inserted: insertedRecords, duplicatedCustomers: duplicatedCustomers, errorCustomers: errorCustomers};
        this.importResults = {
          success: response.inserted || 0,
          error: response.errorCustomers,
          duplicated: response.duplicatedCustomers
        };
        this.importing = false;
        this.loading = false;
      }, error => {
        this.commonService.showErrorMessage(error, this.messageService);
        this.importing = false;
        this.loading = false;
      });
    } else {
      this.commonService.showErrorMessage(`Error, tienes ${duplicated} clientes duplicados`, this.messageService);
    }
    
  }

  getDuplicated(): number {
    let names = this.selectedCustomers.map(customer => customer.name);
    const total = names.length;
    const unique = [...new Set(names)].length;
    return total - unique;
  }

  resetImport() {
    this.importResults = null;
    //this.selectedCustomers = [];
  }

}
