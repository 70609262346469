import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../shared/scss/structure.columns.scss'],
  providers: [MessageService]
})
export class DashboardComponent implements OnInit, OnDestroy {

  viewsReady = false;
  dummy = 0;
  widgetsAccess = {
    history: false,
    dailySales: true,
    monthlySales: true
  }

  constructor( 
    public commonService: CommonService, 
    private authService: AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.setWidgetAccess();
  }

  setWidgetAccess() {
    const access = this.authService.user.value?.access || [];
    if (!access.includes('panel:view')) {
      this.router.navigate(["/"]);
    }
    this.widgetsAccess.history = access.includes("history:view");
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  testPhpApi() {
    this.authService._testPhpApi().subscribe({
      next: response => {
        this.commonService.showSuccessMessage(response.test);
      }
    });
  }

  ngOnDestroy(): void {
  }

}
