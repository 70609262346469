import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faAddressBook, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {DialogService} from 'primeng/dynamicdialog';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { Client, Store, Stores } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { ArqueoService } from '../arqueo.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ClientsImportComponent } from './clients-import/clients-import.component';
import { AddClientComponent } from './add-client/add-client.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-arqueo-clientes',
  templateUrl: './arqueo-clientes.component.html',
  styleUrls: ['./arqueo-clientes.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    DialogService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class ArqueoClientesComponent implements OnInit {

  loading = false;
  clientsIcon = faAddressBook;
  loadingIcon = faSpinner;
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };
  stores = [...Stores];
  searchText = "";
  customersNames: string[] = [];
  searchSubscription?: Subscription;
  search$: Subject<any> = new Subject();

  clients: Client[] = [];

  constructor(
    public commonService: CommonService,
    private arqueoService: ArqueoService,
    private messageService: MessageService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getClients();
    
  }

  getClients() {
    this.loading = true;
    this.arqueoService.getClients().subscribe(clients => {
      this.clients = clients;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.commonService.showErrorMessage(error, this.messageService);
    });
  }

  showImportViews() {
    const ref = this.dialogService.open(ClientsImportComponent, {
      header: 'Importar clientes desde SB',
      width: '70%'
    });
  }

  showAddViews() {
    const ref = this.dialogService.open(AddClientComponent, {
      header: 'Agregar Cliente',
      width: '50%'
    });
    ref.onClose.subscribe(() => {
      this.getClients();
    });
  }

  showEditClient(client: Client) {
    const ref = this.dialogService.open(AddClientComponent, {
      data: {
        client: client
      },
      header: 'Editar Cliente',
      width: '50%'
    });
    ref.onClose.subscribe(() => {
      this.getClients();
    });
  }

}
