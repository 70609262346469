import { Component, OnDestroy, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { HistoryRecord } from 'src/app/shared/common.enums';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'dash-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class DashboardHistoryComponent implements OnInit, OnDestroy {

  records: HistoryRecord[] = [];
  loading = false;
  loadingIcon = faSpinner;
  historySubscription?: Subscription;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getRecords();

    this.historySubscription = this.dashboardService.getHistorySubject().subscribe((records: HistoryRecord[]) => {
      if (records) {
        this.records = records;
      }
    });
  }

  getRecords() {
    this.loading = true;
    this.dashboardService.getHistoryRecords().subscribe(records => {
      this.records = records;
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.historySubscription?.unsubscribe();
  }

}
