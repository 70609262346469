import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HistoryRecord } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';

// interface HistoryRecord {
//   id: number,
//   author: string,
//   subject: string,
//   module: string,
//   description?: string,
//   creation: string
// }

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  //records: HistoryRecord[] = [];

  constructor(private http: HttpClient, private commonService: CommonService){}

  getRecords() : Observable<HistoryRecord[]>{
    return this.http.get<HistoryRecord[]>(
      `${environment.endpoint}history/`,
    ).pipe( 
      catchError(this.commonService.handleRequestError), 
      tap(records => {
        //this.records = records;
      })
    );
  }
}