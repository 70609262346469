import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SalesService } from '../../sales.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesConfigMailTemplateComponent } from './sales-config-mail-template/sales-config-mail-template.component';

@Component({
  selector: 'app-sales-config-mails',
  templateUrl: './sales-config-mails.component.html',
  styleUrls: ['./sales-config-mails.component.css']
})
export class SalesConfigMailsComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  seguimiento: any
  frecuencias: any[] = []

  constructor(
    public config: DynamicDialogConfig,
    public salesServices: SalesService,
    public dialogService: DialogService

  ) { }

  ngOnInit(): void {
    this.getFrecuenciaBySeguimiento(this.config.data.id)
  }


  getFrecuenciaBySeguimiento(Id: number){
    this.salesServices.getFrecuenciaBySeguimiento(Id).pipe(takeUntil(this.destroy$)).subscribe(
      (frecuencias) => {
        this.frecuencias = frecuencias.msg;
        //console.log(this.frecuencias)
      })
  }

  agregarTemplate(values: any, idioma:string){
    const ref = this.dialogService.open(SalesConfigMailTemplateComponent, {
      header: `Template del paso No. ${values.paso}. Idioma: ${idioma}`,
      width: '60%',
      data: {values, idioma}
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getFrecuenciaBySeguimiento(this.config.data.id)
    });
  } 

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
