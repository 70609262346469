import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {  catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { LoggedUser } from './logged-user.model';

interface AuthResponseData {
    auth: boolean,
    error: string,
    name: string,
    email: string,
    id: string,
    token: string,
    expiresIn: string,
    access: string[]
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user = new BehaviorSubject<LoggedUser | null>(null);

    loggedIn = false;

    constructor( private http: HttpClient ){}

    isAuthenticated() {
        const promise = new Promise(
            (resolve, reject) => {
                // setTimeout( () => {
                //     console.log("isAuthenticated", this.loggedIn);
                    
                // }, 800 );
                resolve( this.loggedIn );
            }
        )
        return promise;
    }

    login() {
        this.loggedIn = true;
    }

    autoLogin(){
        const userData = this.getUserFromLocalStorage();
        if (!userData) {
          return;
        }
        //console.log(userData);
        const loadedUser = new LoggedUser( 
            userData.email, 
            userData.name, 
            userData.id, 
            userData._token,
            new Date( userData._tokenExpirationDate ),
            userData.access
        );

        if( loadedUser.token ){
            this.loggedIn = true;
            this.user.next( loadedUser );
            this.refreshUserAccess(loadedUser.id);
        }
    }

    getUserFromLocalStorage() {
      var userDataString = localStorage.getItem('userData');
      userDataString = userDataString ? userDataString : "";
      if( !userDataString ){
          return null;
      }
      const userData: {
          email: string,
          name: string,
          id: string,
          _token: string,
          _tokenExpirationDate: string,
          access: string[]
      } = JSON.parse( userDataString );
      if( !userData ){
          return null;
      }
      return userData;
    }

    refreshUserAccess(user_id: string) {
      this.http.get<any>(
        `${environment.endpoint}/auth/access/${user_id}`
      ).subscribe((access: string[]) => {
        let currentUserData = this.getUserFromLocalStorage();
        if (currentUserData) {
          currentUserData.access = access;
          const expirationDate = new Date( currentUserData._tokenExpirationDate );
          const updatedUser = new LoggedUser( 
            currentUserData.email, currentUserData.name, currentUserData.id, currentUserData._token, expirationDate, access
          );
          this.user.next(updatedUser);
          localStorage.setItem( "userData", JSON.stringify(updatedUser));
        }
      });
    }

    realLogin( email: string, password: string ){
        return this.http.post<AuthResponseData>(
            `${environment.endpoint}/auth`,
            {
                email: email, 
                password: password 
            },
            {
                headers: new HttpHeaders({
                    // 'Access-Control-Allow-Origin' : 'hds-dev.inefra.com',
                    'Content-Type' : 'application/json',
                    'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                })
            }
        ).pipe( 
            catchError( this.handleLoginError ), 
            tap( resData => {
                //const expirationDate = new Date( new Date().getTime() + +resData.expiresIn * 1000);
                const expirationDate = new Date( resData.expiresIn );
                const user = new LoggedUser( 
                  resData.email, resData.name, resData.id, resData.token, expirationDate, resData.access 
                );
                this.user.next(user);
                localStorage.setItem( "userData", JSON.stringify(user));
            })
        );
        // return this.http.get<AuthResponseData>( 
        //     environment.endpoint
        // );
    }

    handleLoginError( errorRes: HttpErrorResponse ){
        console.log("errorRes", errorRes);
        let errorMessage = "Ocurrió un error desconocido!, por favor inténtelo de nuevo";
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        errorMessage = errorRes.error.error;
        // switch( errorRes.error.error ){
        //     case 'Invalid':
        //         errorMessage = "Invalid data!";
        //         break;
        // }
        return throwError(errorMessage);
    }

    logout() {
        this.loggedIn = false;
        localStorage.removeItem("userData");
        this.user.next(null);
    }

    _testPhpApi() {
        return this.http.get<any>(`${environment.phpEndpoint}/?method=test`).pipe( 
            catchError( this.handleLoginError ), 
        );
    }

}