import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Agenda, AgendaStatus, Invoice } from '../../rtm.enums';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { faCalendarAlt, faCircleDollarToSlot, faPercentage, faSpinner, faStoreAlt, faUserCircle, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { RtmService } from '../../rtm.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { InvoiceStatuses, Item } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { Listbox } from 'primeng/listbox';
import { MessageService } from 'primeng/api';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AgendaAddButtonComponent } from '../../rtm-agenda/agenda-add-button/agenda-add-button.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-rtm-invoice',
  templateUrl: './rtm-invoice.component.html',
  styleUrls: ['./rtm-invoice.component.scss'],
  providers: [
    MessageService
  ]
})
export class RtmInvoiceComponent implements OnInit, OnDestroy {

  @ViewChild('alert') alert!: AlertV2Component;
  @ViewChild('agendaBtnOptions') agendaBtnOptions?: AgendaAddButtonComponent;
  @ViewChild('agenda_options_select') agenda_options_select?: Listbox;

  private destroy$ = new Subject();
  invoice: Invoice;
  loadingIcon = faSpinner;
  loading = true;
  agendas: Agenda[] = [];
  items: Item[] = [];
  others: Item[] = [];
  isReadyToDispatch = false;
  isCancelled = false;

  icons = {
    store: faStoreAlt,
    date: faCalendarAlt,
    client: faUserCircle,
    shipped: faPercentage,
    total: faCircleDollarToSlot,
    attention: faUserSecret
  }

  agendaOptions = [
    { name: "Entrega", code: "entrega" },
    // { name: "Pedido Especial", code: "pedido_especial" },
    // { name: "Tapicería", code: "tapiceria" },
    // { name: "Otro", code: "otro" },
  ];

  constructor(
    private rtmService: RtmService,
    private alertService: AlertService,
    private config: DynamicDialogConfig,
    private commonService: CommonService,
    public msgService: MessageService
  ) {
    this.invoice = this.config.data?.invoice;
    this.isCancelled = this.invoice.status === InvoiceStatuses.Cancelled;
  }

  ngOnInit(): void {
    this.init_dependencies();
  }

  init_dependencies() {
    let invoice_items_ids: any[] = [];
    this.rtmService.getInvoiceItems(this.invoice.id_sb).pipe(
      switchMap(items => {
        this.items = items.filter(i => i.type === 'item');
        this.others = items.filter(i => i.type !== 'item');
        invoice_items_ids = items.filter(item => !!item.id_item).map(item => item.id_item);
        if (invoice_items_ids.length > 0) {
          return this.rtmService.getInventoryItems(invoice_items_ids, this.invoice.store);
        }
        return of(null);
      }),
      switchMap(inventory_items => {
        if (inventory_items) {
          this.updateLocalItems(inventory_items);
          const notFoundIds = invoice_items_ids.filter(id => !inventory_items.find(i => i.id_sb == id));
          if (notFoundIds && notFoundIds.length > 0) {
            this.commonService.showWarningMessage(`Estamos sincronizando la venta con el SB, esto puede tardar
              un par de minutos, por favor espere...`, this.msgService, "Sincronización necesaria", (notFoundIds.length * 8) * 1000);
            return this.rtmService.syncInvoicesAndItems(this.invoice.id_sb, notFoundIds, this.invoice.store);
          }
        }
        return of([]);
      }),
      switchMap(inventory_items => {
        if (inventory_items && inventory_items.length > 0) {
          this.updateLocalItems(inventory_items);
        }
        return this.rtmService.getInvoiceAgenda(this.invoice!.id);
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (agendas) => {
        this.agendas = agendas;
        this.updateItemsAvailability();
        this.loading = false;
      },
      error: errorMessage => {
        this.loading = false;
      }
    });
  }

  updateLocalItems(inventory_items: any[]) {
    for (const item of this.items) {
      const matchedItem = inventory_items.find(i => i.id_sb === item.id_item);
      if (matchedItem) {
        item.name = matchedItem.name;
        item.url_s = matchedItem.url_s;
        item.url_m = matchedItem.url_m;
        item.url = matchedItem.url;
        item.location_name = matchedItem.location_name;
        item.location_alias = matchedItem.location_alias;
      }
    }
  }

  onEntregaAdded(event: any) {
    this.updateAgenda();
  }

  updateAgenda() {
    this.rtmService.getInvoiceAgenda(this.invoice.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agendas => {
        this.agendas = agendas;
        this.updateItemsAvailability();
      }
    });
  }

  updateItemsAvailability() {
    if (this.agendas.length > 0) {
      for (const item of this.items) {
        const blocked = this.agendas.map(agenda => {
          return agenda.items.filter(i => i.id_item_sale_sb === item.id_sb).reduce((acc, i) => acc + i.quantity, 0);
        }).reduce((acc, quantity) => acc + quantity, 0);
        item._available = (item.quantity || 0) - blocked;
      }
    } else {
      for (const item of this.items) {
        item._available = (item.quantity || 0);
      }
    }
    this.checkForDispatchability();
  }

  checkForDispatchability() {
    this.isReadyToDispatch = false;
    if (!this.invoice.shipping_date) {
      const itemsAvailable = this.items.filter(item => item._available && item._available > 0);
      if (itemsAvailable.length === 0) {
        //All items have been included in an agenda
        const pendingAgendas = this.agendas.filter(agenda => agenda.status !== AgendaStatus.Completado);
        this.isReadyToDispatch = pendingAgendas.length === 0;
      }
    }
  }

  onMarkAsDispatched() {
    if (this.alert) {
      const body = `¿Estás seguro de marcar como enviado el invoice ${this.invoice.document_number}?<br>
        <b>Esta acción no se puede revertir</b>`;
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: body,
        confirmLabel: 'Confirmar',
        confirmDanger: true,
        onConfirm: () => {
          this.markAsDispatched();
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  markAsDispatched() {
    this.rtmService.markAsDispatched(this.invoice.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: invoice => {
        this.invoice.shipping_date = invoice.shipping_date;
        this.checkForDispatchability();
        this.commonService.showSuccessMessage(`Invoice marcado como enviado correctamente`, this.msgService);
        this.alertService.finishConfirmationAlertv2(this.alert);
      },
      error: errorMessage => {
        this.commonService.showErrorMessage(errorMessage, this.msgService);
        this.alertService.finishConfirmationAlertv2(this.alert);
      }
    })
  }

  onDateConfirmed(agenda: Agenda) {
    const listedAgenda = this.agendas.find(a => a.id === agenda.id);
    if (listedAgenda) {
      listedAgenda.expected_date = agenda.expected_date;
      listedAgenda.status = agenda.status;
    }
    this.commonService.showSuccessMessage(`Fecha promesa confirmada correctamente`, this.msgService);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
