import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RtmService } from '../../../rtm.service';
import { Subject } from 'rxjs';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/mods/arqueo/arqueo-sales/arqueo.sales.component';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/common.service';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Agenda } from '../../../rtm.enums';

@Component({
  selector: 'app-agenda-confirmar-fecha',
  templateUrl: './agenda-confirmar-fecha.component.html',
  styleUrls: ['./agenda-confirmar-fecha.component.scss'],
  providers: [
    MessageService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class AgendaConfirmarFechaComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  agenda: Agenda;
  loadingIcon = faSpinner;
  saving = false;
  minTime = "08:00";
  maxTime = "18:00";
  expected_date?: string;
  expected_time?: string;

  constructor(
    private rtmService: RtmService,
    private commonService: CommonService,
    private config: DynamicDialogConfig,
    private msgService: MessageService,
    private dialogRef: DynamicDialogRef
  ) {
    this.agenda = this.config.data?.agenda;
    this.expected_date = this.agenda.expected_date;
    this.expected_time = this.agenda.expected_time;
  }

  ngOnInit(): void {
  }

  onConfirmDate() {
    if (!this.agenda.expected_date) {
      this.commonService.showErrorMessage(`La fecha de entrega es requerida`, this.msgService);
      return;
    }
    this.saving = true;
    this.rtmService.confirmAgendaDate(this.agenda!.id, {expected_date: this.expected_date, expected_time: this.expected_time}).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agenda => {
        this.agenda.expected_date = this.expected_date;
        this.agenda.expected_time = this.expected_time;
        this.saving = false;
        if (agenda) {
          this.dialogRef.close(agenda);
        } else {
          this.commonService.showErrorMessage(`Error, por favor inténtelo de nuevo`, this.msgService);
        }
      },
      error: errorMessage => {
        this.saving = false;
        this.commonService.showErrorMessage(errorMessage, this.msgService);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
