import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MyErrorStateMatcher } from 'src/app/auth/auth.component';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Customer, DATE_ES_FORMATS, SaldoTransaction, Stores } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ArqueoService } from '../../arqueo.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ClientValidator } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-saldo-add',
  templateUrl: './saldo-add.component.html',
  styleUrls: ['./saldo-add.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: DATE_ES_FORMATS}
  ]
})
export class SaldoAddComponent implements OnInit, OnDestroy {

  loading = false;
  loadingIcon = faSpinner;
  saveIcon = faSave;
  editingMode: boolean = false;
  types = [
    {label: 'Ingreso', value: 'deposit'},
    {label: 'Egreso', value: 'charge'}
  ];
  origins = [
    {label: 'Ninguno', value: ''},
    {label: 'Gift Card', value: 'gc'},
    {label: 'Vale de Caja', value: 'vc'},
    {label: 'Modificación', value: 'modification'},
    {label: 'Cancelación', value: 'cancellation'},
    {label: 'Otro', value: 'other'},
  ];
  confirmationBox = {
    title: "Confirmación Requerida",
    defaultFocus: "reject",
    icon: "", //"pi pi-exclamation-triangle",
    show: false,
    question: ""
  }
  stores = [...Stores];

  transactionForm = new FormGroup({
    invoice: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    amount: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    store: new FormControl('', [Validators.required]),
    origin: new FormControl(''),
    reference: new FormControl('')
  });
  matcher = new MyErrorStateMatcher();
  search$: Subject<any> = new Subject();
  searchSubscription?: Subscription;
  clients: {[key: string]: any}[] = [];
  transaction?: SaldoTransaction;
  searchText = "";
  searchControl = new FormControl('', [Validators.required, ClientValidator]);
  validClientSelected = false;

  constructor(
    private commonService: CommonService,
    private messageService: MessageService,
    private arqueoService: ArqueoService,
    public confirmationService: ConfirmationService,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.initSearchSubscription();
    this.searchControl.valueChanges.subscribe(client => {
      if (client) {
        if (client.hasOwnProperty('id')) {
          this.validClientSelected = true;
        } else {
          this.validClientSelected = false;
          this.search$.next(client);
        }
      } else {
        this.validClientSelected = false;
      }
    });

    if (this.config.data && this.config.data.transaction) {
      this.editingMode = true;
      const client = this.config.data.client;
      const transaction = this.config.data.transaction;
      this.searchControl.setValue({id: client.id, name: client.name});
      this.transactionForm.get('name')?.setValue(client.name);
      this.transactionForm.get('store')?.setValue(transaction.store);
      //this.transactionForm.get('email')?.setValue(transaction.);
      this.transactionForm.get('invoice')?.setValue(transaction.invoice);
      this.transactionForm.get('date')?.setValue(transaction.date);
      this.transactionForm.get('type')?.setValue(transaction.type);
      this.transactionForm.get('amount')?.setValue(transaction.amount);
      this.transactionForm.get('origin')?.setValue(transaction.origin);
      this.transactionForm.get('reference')?.setValue(transaction.reference);
    }
    
  }

  initSearchSubscription() {
    this.searchSubscription = this.search$.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      switchMap((searchText: string) => this.arqueoService.quickSearchClient(searchText))
    ).subscribe((clients: {[key: string]: any}[]) => {
      this.clients = clients;
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
    })
  }

  get invoice() {
    return this.transactionForm.get('invoice') as FormControl;
  }

  get date() {
    return this.transactionForm.get('date') as FormControl;
  }

  get amount() {
    return this.transactionForm.get('amount') as FormControl;
  }

  get store() {
    return this.transactionForm.get('store') as FormControl;
  }

  get type() {
    return this.transactionForm.get('type') as FormControl;
  }

  get origin() {
    return this.transactionForm.get('origin') as FormControl;
  }

  get reference() {
    return this.transactionForm.get('reference') as FormControl;
  }

  get client() {
    return this.searchControl;
  }

  get typeLabel() {
    if(this.transaction && this.transaction.type) {
      for (let type of this.types) {
        if (type.value === this.transaction?.type.toString()) {
          return type.label;
        }
      }
    }
    return '';
  }

  get originLabel() {
    if(this.transaction && this.transaction.origin) {
      for (let origin of this.origins) {
        if (origin.value === this.transaction?.origin.toString()) {
          return origin.label;
        }
      }
    }
    return '';
  }

  onSelectClient(client: {[key: string]: any}) {
    //console.log('select changed', client);
  }

  displayClientName(client: any) {
    if (client) {
      return client.name;
    }
  }

  onSubmit() {
    if (this.loading) return;

    if (this.transactionForm.valid) {

      const date = moment(this.date.value).format("YYYY-MM-DD") || "";

      if (this.client.value.id) {
        if (date && date !== '') { 

          let transaction: SaldoTransaction = {
            client_id: this.client.value.id,
            client_name: this.client.value.name,
            invoice: this.invoice.value,
            store: this.store.value,
            date: date,
            date_formated: moment(this.date.value).locale('es').format("d MMM yyyy"),
            type: this.type.value,
            amount: this.amount.value,
            origin: this.origin.value,
            reference: this.reference.value
          };

          if (this.editingMode) {
            transaction.id = this.config.data.transaction.id;
            this.updateTransaction(transaction);
          } else {
            this.addTransaction(transaction);
          }
        } else {
          this.commonService.showErrorMessage("La fecha no fue seleccionada correctamente, inténtelo de nuevo", this.messageService);
        }
      } else {
        this.commonService.showErrorMessage("El cliente no fue seleccionado correctamente o no existe en la base de datos", this.messageService);
      }
    } else {
      this.commonService.showErrorMessage("Verifique los campos del formulario", this.messageService);
    }
  }

  addTransaction(transaction: SaldoTransaction) {
    this.transaction = transaction;
    this.transaction.type_label = this.typeLabel;
    this.transaction.origin_label = this.originLabel;
    this.confirmationBox.question = `¿Está seguro de agregar la siguiente transacción?`;
    this.confirmationBox.show = true;
  }

  updateTransaction(transaction: SaldoTransaction) {
    this.transaction = transaction;
    this.transaction.type_label = this.typeLabel;
    this.transaction.origin_label = this.originLabel;
    this.confirmationBox.question = `¿Está seguro de actualizar la transacción?`;
    this.confirmationBox.show = true;
  }

  disableForm() {
    this.origin.disable();
    this.type.disable();
    this.amount.disable();
    this.reference.disable();
    this.invoice.disable();
    this.date.disable();
    this.client.disable();
    this.store.disable();
  }

  enableForm() {
    this.origin.enable();
    this.type.enable();
    this.amount.enable();
    this.reference.enable();
    this.invoice.enable();
    this.date.enable();
    this.client.enable();
    this.store.enable();
  }

  confirmTransaction(){
    if (this.transaction) {
      this.disableForm();
      this.confirmationBox.show = false;
      this.loading = true;
      if (this.editingMode) {
        this.confirmEditTransaction(this.transaction);
      } else {
        this.confirmAddTransaction(this.transaction);
      }
    } else {
      this.commonService.showErrorMessage("Error al obtener datos del formulario, inténtelo nuevamente", this.messageService);
    }
  }

  confirmEditTransaction(transaction: SaldoTransaction) {
    this.arqueoService.updateSaldoTransaction(transaction).subscribe(() => {
      this.commonService.showSuccessMessage("La transacción se actualizó correctamente", this.messageService);
      this.enableForm();
      this.loading = false;
    }, error => {
      this.loading = false;
      this.enableForm();
      this.commonService.showErrorMessage(error, this.messageService);
    })
  }

  confirmAddTransaction(transaction: SaldoTransaction) {
    this.arqueoService.addSaldoTransaction(transaction).subscribe(() => {
      this.commonService.showSuccessMessage("La transacción se guardó correctamente", this.messageService);
      this.enableForm();
      this.transactionForm.reset();
      this.loading = false;
    }, error => {
      this.loading = false;
      this.enableForm();
      this.commonService.showErrorMessage(error, this.messageService);
    })
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

}
