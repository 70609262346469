import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowsUpDown, faEye, faPlusCircle, faSave, faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { RtmService } from '../../rtm.service';
import { takeUntil } from 'rxjs/operators';
import { RtmTemplateDynamicField, RtmTemplateDynamicFieldLabels, RtmTemplateField, RtmTemplateFieldType, RtmTemplateFieldTypeLabel, Template } from '../../rtm.enums';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
  providers: [
    MessageService
  ]
})
export class TemplateModalComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  contentReady = false;
  template?: Template;
  templateForm: FormGroup;
  saving = false;
  loadingIcon = faSpinner;
  saveIcon = faSave;
  addIcon = faPlusCircle;
  moveIcon = faArrowsUpDown;
  deleteIcon = faTrashAlt;
  viewIcon = faEye;
  dynamicFields = RtmTemplateDynamicField;
  dynamicFieldsList: RtmTemplateDynamicField[] = [];
  fieldTypes = RtmTemplateFieldType;
  fields: RtmTemplateField[] = [];
  fieldTypeLabels = RtmTemplateFieldTypeLabel;
  dynamicFieldLabels = RtmTemplateDynamicFieldLabels;
  alert?: AlertV2Component;
  previewLink = "";

  modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered'}, { list: 'bullet' }]
    ]
  };

  constructor(
    private rtmService: RtmService,
    private config: DynamicDialogConfig,
    private commonService: CommonService,
    private msgService: MessageService,
    private dialogRef: DynamicDialogRef,
    private alertService: AlertService
  ) {
    this.commonService.alertRef$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(alert => {
      if (alert) {
        this.alert = alert;
      }
    });

    this.template = this.config.data?.template;
    this.templateForm = new FormGroup({
      name: new FormControl("", {
        updateOn: 'change',
        validators: [Validators.required]
      }),
    });
    this.previewLink = `${environment.phpEndpoint}/reports/template/?tid=${this.template?.id}&preview`;

    this.dynamicFieldsList = Object.values(this.dynamicFields);
  }

  ngOnInit(): void {
    this.fillForm();
  }

  fillForm() {
    if (this.template) {
      this.templateForm.get('name')?.setValue(this.template.name);
      this.fields = this.template.fields || [];
    }
    setTimeout(() => {
      this.contentReady = true;
    }, 1000);
  }

  onSubmit() {
    if (this.templateForm.invalid) {
      this.commonService.showErrorMessage("Todos los campos son requeridos", this.msgService);
      return;
    } else if (this.fields.length === 0) {
      this.commonService.showErrorMessage("Debes agregar al menos un campo", this.msgService);
      return;
    } 
    let data = this.__getFormData();
    this.saving = true;
    if (this.template) {
      this.onEditTemplate(data);
    } else {
      this.onAddTemplate(data);
    }
  }

  onAddTemplate(data: Partial<Template>) {
    this.rtmService.createTemplate(data).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: template => {
        this.saving = false;
        if (template) {
          this.dialogRef.close("created");
        } else {
          this.commonService.showErrorMessage("No se ha podido guardar la plantilla, por favor inténtelo nuevamente", this.msgService);
        }
      },
      error: errorMessage => {
        this.saving = false;
        this.commonService.showErrorMessage(errorMessage, this.msgService);
      }
    });
  }

  onEditTemplate(data: Partial<Template>) {
    this.rtmService.updateTemplate(this.template!.id, data).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: template => {
        if (template) {
          this.template = template;
          this.fields = template.fields || [];
          this.commonService.showSuccessMessage("Plantilla actualizada correctamente", this.msgService);
        } else {
          this.commonService.showErrorMessage("No se ha podido guardar los cambios, por favor inténtelo nuevamente", this.msgService);
        }
        this.saving = false;
      },
      error: errorMessage => {
        this.saving = false;
        this.commonService.showErrorMessage(errorMessage, this.msgService);
      }
    });
  }

  onDeleteTemplate() {
    if (this.alert) {
      const body = `¿Estás seguro de eliminar la plantilla?`;
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: body,
        confirmLabel: 'Eliminar',
        confirmDanger: true,
        onConfirm: () => {
          this.deleteTemplate();
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  deleteTemplate() {
    this.rtmService.deleteTemplate(this.template!.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => {
        this.dialogRef.close("deleted");
        this.alertService.finishConfirmationAlertv2(this.alert!);
      },
      error: errorMsg => {
        this.commonService.showErrorMessage(errorMsg, this.msgService);
        this.alertService.finishConfirmationAlertv2(this.alert!);
      }
    })
  }

  updateContentField(event: any) {
    this.templateForm.get('template')?.setValue(event.html);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    this.updatePositions();
  }

  updatePositions() {
    let pos = 0;
    for (const field of this.fields) {
      field.position = pos;
      pos++;
    }
  }

  addCustomField(field_type: RtmTemplateFieldType) {
    const pos = this.fields.length;
    this.fields.push({
      name: "Nuevo campo",
      position: pos,
      isDynamic: false,
      isShared: false,
      isRequired: true,
      showOnlyValue: false,
      type: field_type,
      isSignature: () => false,
      isDynamicEditable: () => false
    });
    this.updatePositions();
  }

  addDynamicField(type: RtmTemplateDynamicField) {
    const pos = this.fields.length;
    this.fields.push({
      name: this.dynamicFieldLabels[type],
      position: pos,
      isDynamic: true,
      isShared: false,
      isRequired: true,
      showOnlyValue: false,
      dynamicType: type,
      isSignature: () => false,
      isDynamicEditable: () => false
    });
    this.updatePositions();
  }

  onRemoveField(position: number) {
    if (this.alert) {
      const body = `¿Estás seguro de eliminar el campo?`;
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: body,
        confirmLabel: 'Eliminar',
        confirmDanger: true,
        onConfirm: () => {
          this.removeField(position);
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    } else {
      this.removeField(position);
    }
  }

  removeField(position: number) {
    const field = this.fields.find(f => f.position === position);
    if (field) {
      if (field.id) {
        //Delete on db
        this.rtmService.deleteTemplateField(field.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe({
          next: () => {
            this.fields = this.fields.filter(f => f.id !== field.id);
            this.alertService.finishConfirmationAlertv2(this.alert!);
          },
          error: errorMsg => {
            this.commonService.showErrorMessage(errorMsg, this.msgService);
            this.alertService.finishConfirmationAlertv2(this.alert!);
          }
        })
      } else {
        //Delete locally
        this.fields = this.fields.filter(f => f.position !== position);
        this.alertService.finishConfirmationAlertv2(this.alert!);
      }
    } else {
      this.alertService.finishConfirmationAlertv2(this.alert!);
    }
  }

  private __getFormData() {
    let data = this.templateForm.value;
    data["fields"] = this.fields;
    return data;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
