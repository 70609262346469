import { CommonService } from './../../../../shared/common.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesService } from '../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface Seguimiento {
  accion: string;
}

@Component({
  selector: 'app-sales-clientes-util',
  templateUrl: './sales-clientes-util.component.html',
  styleUrls: ['./sales-clientes-util.component.css']
})
export class SalesClientesUtilComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  text = ''
  
  Subject = ''

  loading = true

  form: FormGroup
  seguimientos: Seguimiento[] = [];  // Definición de seguimientos para el *ngFor
  nombre = this.config.data.nombre
  salesOpportunity = this.config.data.salesOpportunity

  constructor(
    public fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public salesService: SalesService,
    public commonService: CommonService,
  ) {
    // Inicializar FormGroup y agregar el control following
    this.form = this.fb.group({
        accion: [''],
        text: [''],
        email: [''],
        Subject: [''],
        mes: [false],
        cortinas: [false],
        file: [null],
        salesOpportunity: [this.salesOpportunity]
    })
  }

  ngOnInit(): void {
    this.getMailToSend();
    this.getSeguimientos();  // Llamar para cargar los seguimientos al iniciar el componente
  }

  getSeguimientos(): void {
    this.salesService.getAccion().pipe(takeUntil(this.destroy$)).subscribe(
      (acciones) => {
        // Filtrar seguimientos activos y asignarlos a la variable `seguimientos`
        this.seguimientos = acciones.filter((accion: any) => accion.status === 'Activo');
      },
      (error) => {
        console.error('Error al obtener seguimientos:', error);
      }
    );
  }

  getMailToSend(): void {
    const { idFollowing, step, idioma, idCliente } = this.config.data;
    this.salesService.getMailsToSend(idFollowing, step, idioma, idCliente).pipe(takeUntil(this.destroy$)).subscribe(
      (Mail) => {
        this.text = Mail.mail || 'No se encontró el contenido del mensaje';
        this.Subject = Mail.subject || 'No se encotro asunto'
        this.form.controls['text'].setValue(this.text);
        this.form.controls['email'].setValue(Mail.cliente.email)
        this.form.controls['Subject'].setValue(this.nombre +' '+ this.Subject)
        this.loading = false
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {this.form.patchValue(
        {file:{ name: file.name, type: file.type, size: file.size, base64: reader.result }}
      )}
      reader.onerror = () => {
        this.commonService.showErrorMessage("Error al leer el archivo, si el problema continua intente con otro")
        this.loading = false
      }
    }
  }
  
  
  onSubmit(): void {
    this.loading = true
  
    const { accion, text, email, Subject, file, mes, cortinas, salesOpportunity } = this.form.value
  
    const data: any = {
      accion,
      text,
      email,
      Subject,
      ClienteID: this.config.data.idCliente,
      mes,
      cortinas,
      file,
      salesOpportunity,
      idFollowup: this.config.data.id
    }
      
    this.salesService.SendFollowingMail(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg)
        this.loading = false
        this.ref.close(response.success === true)
      },
      (err) => {
        this.commonService.showErrorMessage(err)
        this.loading = false
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
