import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-prices-view-data',
  templateUrl: './prices-view-data.component.html',
  styleUrls: ['./prices-view-data.component.css']
})
export class PricesViewDataComponent implements OnInit {

  failedUpdates: any = []
  successUpdates:any =[]

  constructor(
    private confing: DynamicDialogConfig
  ) { 
    //console.log(this.confing.data)
    this.failedUpdates = this.confing.data.failedUpdates
    this.successUpdates = this.confing.data.successUpdates
  }

  ngOnInit(): void {
  }

}
