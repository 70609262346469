import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAddressBook, faAngleDoubleLeft, faAngleDoubleRight, faBookOpen, faChartColumn, faLayerGroup, faMoneyBill, faMoneyBill1Wave, faMoneyCheckDollar, faScaleUnbalancedFlip } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { CommonService } from 'src/app/shared/common.service';
import { ArqueoService } from './arqueo.service';

@Component({
  selector: 'app-arqueo',
  templateUrl: './arqueo.component.html',
  styleUrls: ['./arqueo.component.scss', '../../shared/scss/structure.columns.scss'],
  providers: [MessageService]
})
export class ArqueoComponent implements OnInit, OnDestroy {

  fullSize = false;
  salesIcon = faMoneyBill1Wave;
  balanceIcon = faScaleUnbalancedFlip;
  clientsIcon = faAddressBook;
  fondoFijoIcon = faMoneyBill;
  acumuladosIcon = faChartColumn;
  catalagosIcon = faBookOpen;
  faExpand = faAngleDoubleRight;
  faCollapse = faAngleDoubleLeft;
  showTools = false;
  viewSettings = {
    menuCollapsed: false
  }
  syncSubscription?: Subscription;
  featuresAccess = {
    sales: false,
    saf: false,
    clients: false,
    fondo: false,
    accumulated: false
  }

  constructor(
    private router: Router, 
    private arqueoService: ArqueoService, 
    public alertService: AlertService, 
    private messageService: MessageService,
    private commonService: CommonService,
    public authServie: AuthService
  ) { }

  ngOnInit(): void {
    this.commonService.fullSize$.subscribe(fullSize => {this.fullSize = fullSize});

    this.syncSubscription = this.arqueoService.arqueoSync$.subscribe(data => {
      //console.log(`Arqueo sync status received from server:`, data);
      if (data && data.store) {
        if (this.commonService.currentStore$.getValue() === data.store) {
          this.commonService.showSuccessMessage(`La información ha sido sincronizada desde el SB, actualice sus datos`, this.messageService, 5000);
          this.arqueoService.syncingDate = false;
          this.arqueoService.pendingLocalUpdate = true;
        }
      }
    });

    this.setModuleAccess();
    this.redirectToModulePage();
  }

  setModuleAccess() {
    const access = this.authServie.user.value?.access || [];
    this.featuresAccess.sales = access.includes('arqueo:sales:view');
    this.featuresAccess.saf = access.includes('arqueo:saf:view');
    this.featuresAccess.clients = access.includes('arqueo:clients:view');
    this.featuresAccess.fondo = access.includes('arqueo:fondo:view');
    this.featuresAccess.accumulated = access.includes('arqueo:accumulated:view');
  }

  redirectToModulePage() {
    if (this.featuresAccess.sales) {
      this.router.navigate(['/arqueo/ventas']);
    } else if (this.featuresAccess.saf) {
      this.router.navigate(['/arqueo/saldo-a-favor']);
    } else if (this.featuresAccess.clients) {
      this.router.navigate(['/arqueo/clientes']);
    } else if (this.featuresAccess.fondo) {
      this.router.navigate(['/arqueo/fondo-fijo']);
    } else if (this.featuresAccess.accumulated) {
      this.router.navigate(['/arqueo/acumulados']);
    } else {
      this.router.navigate(['/panel']);
    }
  }

  expandCollapseSubmenu(){
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }

  ngOnDestroy(): void {
    this.syncSubscription?.unsubscribe();
  }

}
