import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  providers: [MessageService]
})
export class AuthComponent implements OnInit {

  loggingIn = false
  faSpinner = faSpinner
  errorMessage = "";
  userEmail: string = "dev@inefra.com";
  userPwd: string = "789551";
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  constructor(public authService: AuthService, private router: Router, private _snackBar: MatSnackBar, private messageService: MessageService) { }

  ngOnInit(): void {
    //console.log("authComponent on init");
  }

  showErrorOnSnackbar(message: string) {
    // this._snackBar.openFromComponent(SnackbarComponent, {
    //   duration: 4000,
    //   panelClass: ["snack-danger"],
    //   data: {message: message, classType: "danger"}
    // });
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
  }

  onSubmit() {

    this.errorMessage = "";

    if (this.emailFormControl.invalid || this.passwordFormControl.invalid) {
      return;
    }

    this.loggingIn = true;

    let email = this.emailFormControl.value;
    let password = this.passwordFormControl.value;

    this.authService.realLogin(email, password).subscribe(
      resData => {
        if (resData.auth) {

          this.authService.loggedIn = true;
          this.router.navigate(['/panel']);

        } else {
          this.errorMessage = resData.error;
          this.loggingIn = false;
          this.passwordFormControl.reset();
          this.showErrorOnSnackbar(resData.error);
        }
        console.log(resData);
      }, errorMessage => {
        this.passwordFormControl.reset();
        this.showErrorOnSnackbar(errorMessage);
        this.errorMessage = errorMessage;
        this.loggingIn = false;
        console.log(errorMessage);
      }
    );

    //form.reset( { email: form.value["email"], password: "" } );

    // setTimeout( () => {

    //   if( form.value["username"]=="dev" ){
    //     //Fake correct login
    //     this.authService.login();
    //     this.router.navigate(["/"]);
    //     this.authService.errorMessage = "";
    //   } else {
    //     //Fake incorrect login
    //     this.authService.logout();
    //     this.authService.errorMessage = "Username or password are incorrect";
    //   }

    // }, 1000);

  }

}
