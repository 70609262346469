import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { Client } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { MyErrorStateMatcher } from 'src/app/shared/shared.module';
import { ArqueoService } from '../../arqueo.service';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
  providers: [MessageService]
})
export class AddClientComponent implements OnInit {

  client: Client = {
    name: '',
    vat: '',
    email: '',
    phone: ''
  }
  matcher = new MyErrorStateMatcher();
  clientForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email]),
    phone: new FormControl(''),
    vat: new FormControl('')
  });

  loading = false;
  loadingIcon = faSpinner;
  saveIcon = faSave;
  editingMode: boolean = false;

  constructor(
    private commonService: CommonService,
    private messageService: MessageService,
    private arqueoService: ArqueoService,
    public config: DynamicDialogConfig
  ) { }

  get name() {
    return this.clientForm.get('name') as FormControl;
  }

  get email() {
    return this.clientForm.get('email') as FormControl;
  }

  get phone() {
    return this.clientForm.get('phone') as FormControl;
  }

  get vat() {
    return this.clientForm.get('vat') as FormControl;
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.editingMode = true;
      const client = this.config.data.client;
      this.name.setValue(client.name);
      this.email.setValue(client.email);
      this.phone.setValue(client.phone);
      this.vat.setValue(client.vat);
    }
  }

  onSubmit() {
    if (this.clientForm.valid) {
      let client: Client = {
        name: this.name.value,
        email: this.email.value,
        vat: this.vat.value,
        phone: this.phone.value,
      }
      this.loading = true;
      if (this.editingMode) {
        client.id = this.config.data.client.id;
        this.editClient(client);
      } else {
        this.addClient(client);
      }
    } else {
      this.commonService.showErrorMessage("Verifique los campos del formulario", this.messageService);
    }
  }

  editClient(client: Client) {
    this.arqueoService.editClient(client).subscribe(() => {
      this.commonService.showSuccessMessage('Cliente actualizado correctamente', this.messageService);
      this.loading = false;
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
      this.loading = false;
    });
  }

  addClient(client: Client) {
    this.arqueoService.addClient(client).subscribe(() => {
      this.commonService.showSuccessMessage('Cliente agregado correctamente', this.messageService);
      this.clientForm.reset();
      this.loading = false;
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
      this.loading = false;
    });
  }
}
