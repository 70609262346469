import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { Client, Customer, ExchangeRate, Item, SaldoTransaction, Sale, SBCustomer, Store, Transaction } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArqueoService {

  SYNC_IMMEDIATELY: boolean = true;

  public arqueoSync$: Subject<any> = new Subject();
  socket = io(environment.socketio);
  syncingDate: boolean = false;
  pendingLocalUpdate: boolean = false;

  sales: Sale[] = [];
  cxcSales: Sale[] = [];
  cxcTransactions: Transaction[] = [];

  constructor(private http: HttpClient, private commonService: CommonService) {
    this.socket.on('arqueo_synced', (status) =>{
      this.arqueoSync$.next(status);
    });
  }

  getSalesFromFilters(filters: any) : Observable<Sale[]>{
    return this.http.get<Sale[]>(
      `${environment.endpoint}arqueo/sales/?date=${filters.dateFormated}&store=${filters.store}`,
    ).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getSalesFromMonth(filters: any) : Observable<Sale[]>{
    return this.http.get<Sale[]>(
      `${environment.endpoint}arqueo/sales/month/?date=${filters.dateFormated}&store=${filters.store}`,
    ).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getSalesFromIds(store: string, sales_ids: string[]) : Observable<Sale[]>{
    return this.http.post<Sale[]>(
      `${environment.endpoint}arqueo/sales`,
      {
        ids: sales_ids,
        store: store
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSalesFromSAF(filters: any) {
    return this.http.get<Sale[]>(
      `${environment.endpoint}arqueo/sales/saf/?date=${filters.dateFormated}&store=${filters.store}`,
    ).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getCustomers(customers_ids: string[]) : Observable<Customer[]>{
    return this.http.post<Customer[]>(
      `${environment.endpoint}arqueo/sales/customers`,
      {ids: customers_ids}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  } 

  getSalesItems(sales_ids: string[]): Observable<Item[]> {
    return this.http.post<Item[]>(
      `${environment.endpoint}arqueo/sales/items`,
      {ids: sales_ids}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSalesTransactions(sales_ids: string[] | null, date?: string, return_type: string = 'array'): Observable<Transaction[]> {
    return this.http.post<Transaction[]>(
      `${environment.endpoint}arqueo/sales/transactions`,
      {
        ids: sales_ids,
        date: date || null,
        return_type: return_type
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSalesTransactionsFromPeriod(store: string, from: string, to: string): Observable<{[key:string]: Transaction[]}> {
    return this.http.post<{[key:string]: Transaction[]}>(
      `${environment.endpoint}arqueo/sales/transactions/period`,
      {
        store: store,
        from: from,
        to: to
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getExchangeRate(date: any) : Observable<ExchangeRate | null>{
    return this.http.get<ExchangeRate | null>(
      `${environment.endpoint}arqueo/exchange/?date=${date}`,
    ).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  setExchangeRate(rate: ExchangeRate): Observable<any> {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/exchange`,
      {
        date: rate.date,
        price: rate.price
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  setSamount(sale_id: number, samount: string): Observable<any> {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/samount`,
      {
        sale_id: sale_id,
        samount: samount
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  syncDate(date: string, store:string) {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/sync/date`,
      {
        date: date,
        store: store
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  DEV_syncDateNow(store: string) {
    return this.http.get<any>(
      `${environment.endpoint}arqueo/sync/date/dev/?store=${store}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  // Sales

  updateSpecialType(sale: Sale, stype: string) {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/sale/stype/`,
      {
        sale_id: sale.id,
        stype: sale.stype
      }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  // Saldo a favor

  addSaldoTransaction(transaction: SaldoTransaction) {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/client/saldo/`,
      {transaction: transaction}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  updateSaldoTransaction(transaction: SaldoTransaction) {
    return this.http.put<any>(
      `${environment.endpoint}arqueo/client/saldo/`,
      {transaction: transaction}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getClientsWithSaldo(client_id?: number) {
    return this.http.get<Client[]>(
      `${environment.endpoint}arqueo/clients/saldo/${client_id || ''}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSaldoTransactionsFromInvoices(invoices_ids: number[]) {
    return this.http.post<SaldoTransaction[]>(
      `${environment.endpoint}arqueo/saldo/invoices`,
      {ids: invoices_ids}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  // getClientWithSaldo(client_id: string) {
  //   return this.http.get<Client[] | []>(
  //     `${environment.endpoint}arqueo/client/saldo/${client_id}`
  //   ).pipe(
  //     catchError(this.commonService.handleRequestError)
  //   )
  // }

  // Clientes

  sbQuicksSearchCustomer(name: string) {
    return this.http.get<any>(
      `${environment.endpoint}arqueo/sb/customers/quick-search/?name=${name}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  sbSearchCustomer(name?: string, date?: string) {
    return this.http.get<SBCustomer[]>(
      `${environment.endpoint}arqueo/sb/customers/search/?name=${name}&date=${date}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSBCustomers() {
    return this.http.get<SBCustomer[]>(
      `${environment.endpoint}arqueo/sb/customers/`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  importCustomers(customers_ids: number[]) {
    return this.http.post<SBCustomer[]>(
      `${environment.endpoint}arqueo/sb/customers/`,
      {ids: customers_ids}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getClients() {
    return this.http.get<Client[]>(
      `${environment.endpoint}arqueo/clients/`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addClient(client: Client) {
    return this.http.post<any>(
      `${environment.endpoint}arqueo/client/`,
      {client: client}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editClient(client: Client) {
    return this.http.put<any>(
      `${environment.endpoint}arqueo/client/`,
      {client: client}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  quickSearchClient(name: string) {
    return this.http.get<any>(
      `${environment.endpoint}arqueo/clients/quick-search/?name=${name}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

}