import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Item } from 'src/app/shared/common.enums';
import { AgendaEntregaComponent } from '../agenda-entrega/agenda-entrega.component';
import { AgendaType, Invoice } from '../../rtm.enums';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Listbox } from 'primeng/listbox';

@Component({
  selector: 'agenda-add-button',
  templateUrl: './agenda-add-button.component.html',
  styleUrls: ['./agenda-add-button.component.scss'],
  providers: [
    DialogService
  ]
})
export class AgendaAddButtonComponent implements OnInit, OnDestroy {

  @ViewChild('agenda_options') agenda_options?: OverlayPanel;
  @ViewChild('agenda_options_select') agenda_options_select?: Listbox;
  @Output() onEntregaAdded = new EventEmitter<any>();
  @Input() invoice?: Invoice;
  @Input() items?: Item[];
  @Input() others?: Item[];

  private destroy$ = new Subject();

  constructor(
    private dialogService: DialogService
  ) { }

  agendaOptions = [
    { name: "Entrega", code: "entrega" },
    // { name: "Pedido Especial", code: "pedido_especial" },
    // { name: "Tapicería", code: "tapiceria" },
    // { name: "Otro", code: "otro" },
  ];

  ngOnInit(): void {}

  onAddAgenda(event: any) {
    const agenda_type = event.value.code;
    console.log("Add agenda", agenda_type);
    
    if (agenda_type === AgendaType.Entrega) {
      this.addEntrega();
    }

    this.agenda_options?.hide();
    this.agenda_options_select?.uncheckAll();
  }

  addEntrega() {
    const ref = this.dialogService.open(AgendaEntregaComponent, {
      data: {
        invoice: this.invoice,
        items: this.items,
        others: this.others
      },
      header: `Agregar Entrega`,
      width: '80%'
    });
    ref.onClose.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.onEntregaAdded.emit(true);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
