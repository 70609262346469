import { Component, OnInit } from '@angular/core';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-fondo-fijo',
  templateUrl: './fondo-fijo.component.html',
  styleUrls: ['./fondo-fijo.component.scss']
})
export class ArqueoFondoFijoComponent implements OnInit {

  fondo_usd = [
    {cantidad: '', billete: '$100', valor: '$0'},
    {cantidad: '', billete: '$50', valor: '$0'},
    {cantidad: '5', billete: '$20', valor: '$100'},
    {cantidad: '1', billete: '$10', valor: '$10'},
    {cantidad: '4', billete: '$5', valor: '$20'},
    {cantidad: '20', billete: '$1', valor: '$20'},
  ];

  fondo_mxn = [
    {cantidad: '4', billete: '$100', valor: '$400'},
    {cantidad: '1', billete: '$50', valor: '$50'},
    {cantidad: '1', billete: '$20', valor: '$20'},
    {cantidad: '1', billete: '$10', valor: '$10'},
    {cantidad: '1', billete: '$5', valor: '$5'},
    {cantidad: '15', billete: '$1', valor: '$15'},
  ];

  fondoIcon = faMoneyBill;
  hasAccess = false;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.hasAccess = this.authService.user.value?.access.includes("arqueo:fondo:view") || false;
  }

}
