import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { TagsEditProductComponent } from '../../tags-edit-product/tags-edit-product.component';
import { TagsProcessProdutService } from '../../tags-process-product.service';
import { Up4Products, Up16Products, Up80Products, Up2Products } from '../../tags.enums';

@Component({
  selector: 'app-tag-hds-products',
  templateUrl: './tag-hds-products.component.html',
  styleUrls: ['./tag-hds-products.component.css'],
  providers: [DialogService]
})
export class TagHdsProductsComponent implements OnInit {

  ref: DynamicDialogRef | undefined;

  Up2List: Up2Products[] = [];
  up4List: Up4Products[] = [];
  up16List: Up16Products[] = [];
  up80List: Up80Products[] = [];

  filteredList: any[] = []; // Lista filtrada que se mostrará en la vista
  
  constructor(
    public config: DynamicDialogConfig, 
    public dialogService: DialogService,
    private productProcess: TagsProcessProdutService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    // Llenar las listas desde el servicio
    this.Up2List = this.productProcess.getUp2Products();
    this.up4List = this.productProcess.getUp4Products();
    this.up16List = this.productProcess.getUp16Products();
    this.up80List = this.productProcess.getUp80Products();

    // Filtrar la lista según el tipo de lista recibido
    this.filterList();
  }

  filterList() {
    const listType = this.config.data?.listType;
    if (listType === "up4") {
      this.filteredList = this.up4List;
    } else if (listType === "up16") {
      this.filteredList = this.up16List;
    } else if (listType === "up80") {
      this.filteredList = this.up80List;
    }else if (listType === "up2") {
      this.filteredList = this.Up2List;
    }
  } 

  editProduct(product: any, index: number): void {
    this.ref = this.dialogService.open(TagsEditProductComponent, {
      data: { product },
      header: 'Editar Producto',
      width: '50%'
    });

    this.ref.onClose.subscribe((updatedProduct) => {
      if (updatedProduct) {
        // Actualizar el producto en la lista filtrada
        this.filteredList[index] = updatedProduct; // Reemplaza el producto actualizado
      }
    });
  }

  deleteProduct(index: number): void {
    const listType = this.config.data?.listType;
    if (listType === "up4") {
      this.up4List.splice(index, 1);
      this.filteredList = [...this.up4List]; // Actualiza la lista filtrada
    } else if (listType === "up16") {
      this.up16List.splice(index, 1);
      this.filteredList = [...this.up16List]; // Actualiza la lista filtrada
    } else if (listType === "up80") {
      this.up80List.splice(index, 1);
      this.filteredList = [...this.up80List]; // Actualiza la lista filtrada
    } else if (listType === "up2") {
      this.Up2List.splice(index, 1);
      this.filteredList = [...this.Up2List]; // Actualiza la lista filtrada
    }
    this.commonService.showSuccessMessage(`Producto Eliminado`);
  }
}
