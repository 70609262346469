import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleDoubleLeft, faAngleDoubleRight, faCalendarWeek, faListAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { CommonService } from 'src/app/shared/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rtm',
  templateUrl: './rtm.component.html',
  styleUrls: ['./rtm.component.css', '../../shared/scss/structure.columns.scss']
})
export class RtmComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  faAgenda = faCalendarWeek;
  faInvoices = faListAlt;
  faTemplates = faPaperPlane;
  faExpand = faAngleDoubleRight;
  faCollapse = faAngleDoubleLeft;
  showTools = false;
  viewSettings = {
    menuCollapsed: false
  }
  featureAccess = {
    agenda: false,
    invoices: false,
    templates: false
  }
  fullSize: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public alertService: AlertService,
    private commonService: CommonService
  ) { 
    this.commonService.fullSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(fullSize => {
      this.fullSize = fullSize;
    })
  }

  ngOnInit(): void {
    this.setFeatureAccess();
    this.redirectToFeaturePage();
  }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.agenda = access.includes("agenda:view");
    this.featureAccess.invoices = access.includes("agenda:invoices:view");
    this.featureAccess.templates = access.includes("agenda:templates:manage");
  }

  redirectToFeaturePage() {
    if (this.featureAccess.agenda) {
      this.router.navigate(['/agenda/lista']);
    } else if (this.featureAccess.invoices) {
      this.router.navigate(['/agenda/invoices']);
    } else if (this.featureAccess.templates) {
      this.router.navigate(['/agenda/plantillas']);
    } else {
      this.router.navigate(['/panel']);
    }
  }

  expandCollapseSubmenu(){
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
