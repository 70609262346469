import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { faArrowRight, faChartColumn, faExclamationTriangle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { of, Subscription } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { Item, PaymentTypes, SaldoTransaction, Sale, Store, Stores, Transaction } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { ArqueoService } from '../arqueo.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

interface pdfHolder {
  loading: boolean,
  doc: jsPDF | null
}

@Component({
  selector: 'app-arqueo-accumulated',
  templateUrl: './arqueo-accumulated.component.html',
  styleUrls: ['./arqueo-accumulated.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class ArqueoAccumulatedComponent implements OnInit, OnDestroy {

  loading = false;
  loadingIcon = faSpinner;
  acumuladosIcon = faChartColumn;
  arrowIcon = faArrowRight;
  exclamationIcon = faExclamationTriangle;

  stores = [...Stores];
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };

  salesPersons: {[key: string]: number} = {};
  salesPersonsSales: {[key: string]: Sale[]} = {};
  salesPersonsTotals: {[key: string]: number} = {};
  salesPersonsPerDay: {[key: string]: any} = {};
  salesPersonsCxC: {[key: string]: number} = {};
  salesPersonsGC: {[key: string]: number} = {};
  salesPersonsNC: {[key: string]: number} = {};
  salesPersonsGCInvoices: {[key: string]: string[]} = {};
  salesPersonsNCInvoices: {[key: string]: string[]} = {};
  salesPersonsCommisionable: {[key: string]: number} = {};
  salesPersonsSIC: {[key: string]: number} = {};
  totalCxC = 0;
  totalComisionable = 0;
  totalGC = 0;
  total = 0;
  totalNC = 0;
  salesPersonsArray: string[] = [];
  days: number[] = [];
  alive = true;
  aliveSubscription?: Subscription;

  totalBrute = 0;
  eir_total = 0;
  eir_daily: {[key: string]: number} = {};
  contract_total = 0;
  contract_daily: {[key: string]: number} = {};
  scommission_total = 0;
  scommission_daily: {[key: string]: number} = {};
  ncgc_total = 0;
  ncgc_daily: {[key: string]: number} = {};
  other_income = 0;
  sic_total = 0;
  shipping_total = 0;
  shipping_daily: {[key: string]: number} = {};
  iva_total = 0;
  iva_daily: {[key: string]: number} = {};
  taxes_total = 0;
  sales_total = 0;
  sales_daily: {[key: string]: number} = {};
  saf_total = 0;
  saf_daily: {[key: string]: number} = {};
  cobranza_total = 0;
  cobranza_daily: {[key: string]: number} = {};
  income_total = 0;
  cxc_daily: {[key: string]: number} = {};
  cxc_total = 0;
  cobranza_transactions: Transaction[] = [];
  payment_types_hash: {[key: string]: any} = {};
  other_values_total = 0; // cxc + cash + vc
  transfers_total = 0;
  checks_total = 0;
  cc_total = 0;
  universo_369_total = 0;
  nomina_total = 0;
  values_total = 0;

  //SAF
  saf_deposits: {[key: string]: Transaction[]} = {};
  saf_deposits_transactions: Transaction[] = [];

  //PDF
  pdf_files: {[key: string]: pdfHolder} = {
    resume: {
      loading: false,
      doc: null
    },
    daily: {
      loading: false,
      doc: null
    }
  };
  
  constructor(
    public arqueoService: ArqueoService, 
    public commonService: CommonService, 
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    moment.locale('es');
    this.filters.store = this.commonService.currentStore$.getValue();
    this.stores.push({label: 'HDS + Ashley + Ashley Outlet', value: ""});
    this.arqueoService.sales = [];
  }

  init_payment_types() {
    for (let type of [...PaymentTypes]) {
      this.payment_types_hash[type] = {total: 0, daily: {}};
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.filters.date.value;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.filters.date.setValue(ctrlValue);
    datepicker.close();
  }

  onChangeStore() {
    this.commonService.currentStore$.next(this.filters.store);
    this.arqueoService.sales = [];
    this.aliveSubscription?.unsubscribe();
    this.loading = false;
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  onGenerateAccumulated() {
    this.filters.dateFormated = moment(this.filters.date.value).format("YYYY-MM") || "";
    this.getSales();
  }

  getSales() {
    if (!this.loading) {
      this.resetVariables();
      this.loading = true;

      const monthDays = moment(this.filters.date.value).daysInMonth();
      for (let i = 1; i <= monthDays; i++) {
        this.days.push(i);
      }

      this.aliveSubscription = this.arqueoService.getSalesFromMonth(this.filters).pipe(
        tap(sales => {
          this.arqueoService.sales = this.recalculateTotalWithTaxes(sales);
        }),
        switchMap(() => {
          // GET sales items
          const sales_ids = this.arqueoService.sales.map((sale: {id_sb: string}) => sale.id_sb);
          return this.arqueoService.getSalesItems(sales_ids);
        }),
        tap(items => {
          console.log(items.filter(i => i.type === "other").map(i => ({id: i.sale_id, price: i.price})));
          // SET sales items, calculate discount
          this.setSalesItems(items);
        }),
        switchMap(() => {
          // GET sales transactions
          const sales_ids = this.arqueoService.sales.map((sale: {id_sb: string}) => sale.id_sb);
          return this.arqueoService.getSalesTransactions(sales_ids);
        }),
        tap(transactions => {
          // SET sales transactions, calculate cxc and paid in advance
          this.setSalesTransactions(transactions);
        }),
        switchMap(() => {
          const month = moment(this.filters.date.value).format("MM");
          const year = moment(this.filters.date.value).format("YYYY");
          const days = moment(this.filters.date.value).daysInMonth();
          const from = `${year}-${month}-01`;
          const to = `${year}-${month}-${days}`;
          return this.arqueoService.getSalesTransactionsFromPeriod(this.filters.store, from, to);
        }),
        tap(monthTransactions => {
          this.setCobranza(monthTransactions);
        }),
        switchMap(() => {
          const cancelled_invoices = this.arqueoService.sales.filter(sale => sale.status === 15).map(sale => sale.invoice);
          if (cancelled_invoices.length > 0) {
            return this.arqueoService.getSaldoTransactionsFromInvoices(cancelled_invoices);
          }
          return of([]);
        }),
        tap(transactions => {
          this.setSaldoToCancelledInvoices(transactions);
        }),
      ).subscribe(() => {

        // SET sales persons totals
        this.setSalesPersonsTotals();

        // Calculate totals
        this.calculateTotals();

       //console.log(`sales =>`, this.arqueoService.sales);
        this.commonService.showSuccessMessage(`Resumen generado correctamente`, this.messageService);

        this.loading = false;
      }, error => {
        this.loading = false;
        this.commonService.showErrorMessage(error, this.messageService, 4500);
      });
    }
  }

  resetVariables() {
    this.salesPersons = {};
    this.salesPersonsTotals = {};
    this.salesPersonsPerDay = {};
    this.totalCxC = 0;
    this.totalGC = 0;
    this.totalNC = 0;
    this.total = 0;
    this.sic_total = 0;
    this.totalComisionable = 0;
    this.salesPersonsTotals = {};
    this.salesPersonsCxC = {};
    this.salesPersonsGC = {};
    this.salesPersonsNC = {};
    this.salesPersonsGCInvoices = {};
    this.salesPersonsNCInvoices = {};
    this.salesPersonsSIC = {};
    this.salesPersonsArray = [];
    this.salesPersonsSales = {};
    this.days = [];

    this.totalBrute = 0;
    this.eir_total = 0;
    this.eir_daily = {};
    this.contract_total = 0;
    this.contract_daily = {};
    this.scommission_total = 0;
    this.scommission_daily = {};
    this.ncgc_total = 0;
    this.ncgc_daily = {};
    this.other_income = 0;
    this.shipping_total = 0;
    this.shipping_daily = {};
    this.iva_total = 0;
    this.iva_daily = {};
    this.taxes_total = 0;
    this.sales_total = 0;
    this.sales_daily = {};
    this.saf_total = 0;
    this.saf_daily = {};
    this.cobranza_total = 0;
    this.cobranza_daily = {};
    this.income_total = 0;
    this.cxc_daily = {};
    this.cxc_total = 0;
    this.cobranza_transactions = [];
    this.payment_types_hash = {};
    this.other_values_total = 0; // cxc + cash + vc
    this.transfers_total = 0;
    this.checks_total = 0;
    this.cc_total = 0;
    this.universo_369_total = 0;
    this.nomina_total = 0;
    this.values_total = 0;
  
    //SAF
    this.saf_deposits = {};
    this.saf_deposits_transactions = [];
  
    //PDF
    this.pdf_files = {
      resume: {
        loading: false,
        doc: null
      },
      daily: {
        loading: false,
        doc: null
      }
    };

    this.init_payment_types();
  }

  getTableColumns() {
    return this.salesPersonsArray.length + 2;
  }

  // Sales calculations

  recalculateTotalWithTaxes(sales: Sale[]) {
    sales.filter((sale: Sale) => parseFloat(sale.tax || '0') > 0 || parseFloat(sale.tax2 || '0') > 0).forEach((sale: Sale) => {
      const sum = (parseFloat(sale.tax || '0') > 0) ? parseFloat(sale.total) + parseFloat(sale.tax || '0') : 
        parseFloat(sale.total) + parseFloat(sale.tax2 || '0');
      //console.log('Total + tax', `SUBTOTAL: ${sale.total} + ${sale.tax} | ${sale.tax2} = ${sum.toFixed(2)}`);
      sale.total = sum.toFixed(2);
    });
    return sales;
  }

  setSalesItems(items: Item[]) {
    this.arqueoService.sales.forEach((sale: Sale) => {
      sale._is_sic = this.isSIC(sale);
      sale.items = items.filter(item => item.sale_id === sale.id_sb)
      //sale.items_other = sale.items.filter(item => item.type === 'other' && item.name?.toLowerCase().indexOf('discount') === -1);
      sale.items_other = sale.items.filter(item => item.type === 'other' && parseFloat(item.price) > 0);
      sale.items_price = sale.items.filter(item => item.type === 'item').map(item => item.price).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');
      //Changed to item.price_fixed to match invoice 6385 HDS
      const discount = sale.items.filter(item => parseFloat(item.price) < 0).map(item => parseFloat(item.price)).reduce((prev, curr) => {
        return prev + curr;
      }, 0).toFixed(2);
      sale.discount = discount;
      sale.items_price_no_dicount = sale.items_price;
      sale.items_price = (parseFloat(sale.items_price) + parseFloat(discount)).toString();
      //Sum of services
      if (sale.items_other.length > 0) {
        sale.items_other_sum = sale.items_other.map(item => parseFloat(item.price)).reduce((prev, curr) => {
          return prev + curr;
        }, 0);
      }

      //validate not greater than sale total
      if (parseFloat(sale.items_price) > parseFloat(sale.total)) {
        sale.items_price = sale.total;
      }
    });
  }

  setSalesTransactions(transactions: Transaction[]) {
    const report_month = moment(this.filters.date.value).format("M");
    const report_year = moment(this.filters.date.value).format("YYYY");
    this.arqueoService.sales.forEach((sale: Sale) => {
      const sale_date = moment(sale.created).format("YYYY-MM-DD");
      sale.transactions = transactions.filter(transaction => transaction.sale_id === sale.id_sb);
      sale.transactions_paid_same_date = sale.transactions.filter(transaction => moment(transaction.date).format("YYYY-MM-DD") === sale_date && parseFloat(transaction.amount || '0') > 0 );
      sale.paid_same_date = sale.transactions_paid_same_date.map(trans => trans.amount).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');
      
      //sale.transactions_paid_same_month = sale.transactions.filter(transaction => moment(transaction.date).format("M") === report_month && moment(transaction.date).format("YYYY") === report_year && parseFloat(transaction.amount || '0') > 0 );
      sale.transactions_paid_same_month = sale.transactions.filter(transaction => moment(transaction.date).format("M") === report_month && moment(transaction.date).format("YYYY") === report_year);
      sale.paid_same_month = sale.transactions_paid_same_month.map(trans => trans.amount).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');

      sale.transactions_sic = sale.transactions.filter(t => this.isTransactionSIC(t));

      sale.cxc_month = (parseFloat(sale.total) - parseFloat(sale.paid_same_month)).toFixed(2);

      sale.attentions_total = sale.attention.split('/').length;

      if (sale.status !== 15) {
        if (parseFloat(sale.paid_same_date).toFixed(2) !== parseFloat(sale.total).toFixed(2)) {
          if (sale.transactions_paid_same_date.length === 0) {
            //all is cxc
            sale.cxc = sale.total;
          } else {
            sale.paid_in_advance = sale.paid_same_date;
            sale.cxc = (parseFloat(sale.total) - parseFloat(sale.paid_same_date)).toFixed(2);
          }
          //console.log(`Day: ${moment(sale.created).format("YYYY-MM-DD")} - Invoice ${sale.invoice} - CXC ${sale.cxc}`);
        }

        //Cambio de lógica para buscar abonos no solo anticipos del mismo día
        // if (parseFloat(sale.paid) < parseFloat(sale.total)) {
        //   if (parseFloat(sale.paid)) {
        //     sale.paid_in_advance = sale.paid;
        //     sale.cxc = (parseFloat(sale.total) - parseFloat(sale.paid)).toString();
        //   } else {
        //     sale.cxc = sale.total; //all is cxc
        //   }
        // }

        //Calculate total paid with NC/GC
        let totalPromo = 0;
        const items_price = parseFloat(sale.items_price || "0");
        for (let transaction of sale.transactions_paid_same_month || []) {
          if (this.isPromo(transaction)) {
            const transaction_amount = parseFloat(transaction.amount);
            if ( items_price < transaction_amount) {
              totalPromo = items_price;
            } else {
              totalPromo += transaction_amount;
            }
            
          }
        }
        sale.totalPromo = parseFloat(totalPromo.toFixed(2));

        //Total paid with GC and NC
        let paid_with_gc = 0;
        let paid_with_nc = 0;
        let paid_with_sic = 0;
        for (let transaction of sale.transactions) {
          if (this.isGC(transaction)) {
            if (parseFloat(transaction.amount) > parseFloat(sale.commission || '0')) {
              paid_with_gc += parseFloat(sale.commission || '0');
            } else {
              paid_with_gc += parseFloat(transaction.amount);
            }
            (!sale.transactions_gc) ? sale.transactions_gc = [transaction] : sale.transactions_gc.push(transaction);
          } else if (this.isNC(transaction)) {
            if (parseFloat(transaction.amount) > parseFloat(sale.commission || '0')) {
              paid_with_nc = parseFloat(sale.commission || '0');
            } else {
              paid_with_nc += parseFloat(transaction.amount);
            }
            (!sale.transactions_nc) ? sale.transactions_nc = [transaction] : sale.transactions_nc.push(transaction);
          }
          if (this.isTransactionSIC(transaction)) {
            if (parseFloat(transaction.amount) > parseFloat(sale.commission || '0')) {
              paid_with_sic = parseFloat(sale.commission || '0');
            } else {
              paid_with_sic += parseFloat(transaction.amount);
            }
          }
        }
        this.totalGC += paid_with_gc;
        sale.paid_with_gc = paid_with_gc;
        this.totalNC += paid_with_nc;
        sale.paid_with_nc = paid_with_nc;
        sale.paid_with_sic = paid_with_sic;
      }
    });
  }

  setCobranza(monthTransactions: {[key: string]: Transaction[]}) {
    if (monthTransactions) {
      const month_invoices_sb = this.arqueoService.sales.map(sale => sale.id_sb);
      for (let sb_id in monthTransactions) {
        if (!month_invoices_sb.includes(sb_id)) {
          for (let trans of monthTransactions[sb_id]) {
            let day = moment(trans.date).format("D");
            if (!this.cobranza_daily[day]) {
              this.cobranza_daily[day] = 0;
            }
            let amount = parseFloat(trans.amount || '0');
            this.cobranza_daily[day] += amount;
            this.cobranza_total += amount;
            if (amount > 0) {
              this.cobranza_transactions.push(trans);
            }
            //console.log(`day ${day}`, trans);
          }
          //console.log(`Payments for invoices of other month - cobranza`, monthTransactions[sb_id]);
        }
      }
    }
  }
  isPromo(trans: Transaction) {
    let isPromo = false;
    for (let key of ['coupon', 'gc', 'nc', 'vc', 'vale']) {
      if (trans.reference?.toLowerCase().indexOf(key) !== -1){
        isPromo = true;
        break;
      }
    }
    return isPromo;    
  }

  isNC(trans: Transaction) {
    let isNC = false;

    //if (trans.reference?.indexOf('#SIC') === -1) {
      for (let key of ['nc', 'vc', 'vale']) {
        if (trans.reference?.toLowerCase().indexOf(key) !== -1 && trans.reference?.toLowerCase().indexOf('trans') === -1){
          isNC = true;
          break;
        }
      }
    // } else {
    //   //console.log(trans.reference);
    // }

    return isNC;
  }

  isGC(trans: Transaction) {
    let isGc = false;
   
    //if (trans.reference?.indexOf('#SIC') === -1) {
      for (let key of ['coupon', 'gc']) {
        if (trans.reference?.toLowerCase().indexOf(key) !== -1){
          isGc = true;
          break;
        }
      }
    // } else {
    //   //console.log(trans.reference);
    // }
    return isGc;    
  }

  isTransactionSIC(trans: Transaction) {
    return trans.reference?.toLowerCase().indexOf("#sic") !== -1;
  }

  isSIC(sale: Sale) {
    return sale.name?.indexOf('#SIC') !== -1;
  }

  setSaldoToCancelledInvoices(transactions: SaldoTransaction[]) {
    //filter only deposits
    transactions = transactions.filter(trans => trans.type.toString() === 'deposit');
    if (transactions.length > 0) {

      //let hash: {[key: number]: Transaction[]} = {};
      let hash: {[key: number]: any} = {};
      for (let transaction of transactions) {
        // if (!hash[transaction.invoice]) hash[transaction.invoice] = [];
        // hash[transaction.invoice].push({
        //   invoice: transaction.invoice,
        //   amount: transaction.amount,
        //   date: transaction.date,
        //   reference: `SAF: ${transaction.reference}`,
        //   client: transaction.client_name,
        //   sale_id: '0',
        //   type: 'deposit'
        // });
        let origin = transaction.origin;
        if (origin) {
          if (!this.saf_deposits[origin]) {
            this.saf_deposits[origin] = [];
          }
          if (!hash[transaction.invoice]) hash[transaction.invoice] = {total: 0, trans: []};
          let trans = {
            invoice: transaction.invoice,
            amount: transaction.amount,
            date: transaction.date,
            reference: `SAF: ${transaction.reference}`,
            client: transaction.client_name,
            sale_id: '0'
          };
          this.saf_deposits[origin].push(trans);
          this.saf_deposits_transactions.push(trans);
          hash[transaction.invoice]['total'] += parseFloat(transaction.amount);
          hash[transaction.invoice]['trans'].push(trans);
        }
      }

      //this.arqueoService.sales.filter(sale => sale.status === 15).forEach(sale => {
      this.arqueoService.sales.forEach(sale => {
        if (hash[sale.invoice]) {
          //console.log(`set on invoice ${sale.invoice} the amount of ${hash[sale.invoice]['total']}`);
          sale.saf_total = hash[sale.invoice]['total'];
          sale.saf_transactions = hash[sale.invoice]['trans'];
        }
      });


    //   this.arqueoService.sales.filter(sale => sale.status === 15).forEach(sale => {
    //     if (hash[sale.invoice]) {
    //       sale.transactions.push(...hash[sale.invoice]);
    //       sale.transactions_paid_same_date?.push(...hash[sale.invoice]);
          
    //       let saf_total = 0;
    //       // sale.transactions.filter(trans => trans.type && trans.type === 'deposit').forEach(trans => {
    //       //   saf_total += parseFloat(trans.amount);
    //       // });
    //       for (let transaction of sale.transactions) {
    //         saf_total += parseFloat(transaction.amount);
    //       }
    //       sale.saf_total = saf_total;
    //     }
    //   });
    }
  }

  setSalesPersonsTotals() {
    const filterNoCancelled = (sale: Sale) => sale.status !== 15 && sale.items_price && parseFloat(sale.items_price) > 0 && sale.stype !== 'contract';

    //Set sales persons sums
    for (let sale of this.arqueoService.sales.filter(filterNoCancelled)) {

      // if ((sale.totalPromo || 0) > parseFloat(sale.items_price || '0')) {
      //   continue;
      // }

      let item_price = parseFloat(sale.items_price || '0') - (sale.totalPromo || 0);
      //let item_price = parseFloat(sale.items_price || '0') - (sale.totalPromo || 0);
      if (sale._is_sic) {
        //item_price = parseFloat(sale.items_price || '0') - (sale.paid_with_sic || 0);
        //this.sic_total += item_price;
        this.sic_total += sale.paid_with_sic || 0;
      }

      if (item_price < 0) {
        item_price = 0;
      }

      if (sale.stype === 'scommission') {
        if ((sale.transactions_gc && sale.transactions_gc.length > 0) ||
            (sale.transactions_nc && sale.transactions_nc.length > 0)) {
          // total will be calculated from nc and gc
        } else {

          const samount = parseFloat(sale.samount || '0');
          if (!sale.samount || samount === 0 || sale.samount == sale.total) {
            continue;
          }
          //console.log(item_price, sale.items_price);
          item_price = parseFloat(sale.items_price || '0') - samount;
        }
      }

      if (sale.attention.indexOf('/') !== -1) {
        //shared sale
        let persons = sale.attention.split('/');
        //const amount = (parseFloat(sale.items_price || '0') / 2).toFixed(2);
        const amount = (item_price / persons.length).toFixed(2);
        for (let person of persons) {
          if (!this.salesPersons[person]) this.salesPersons[person] = 0;
          if (!this.salesPersonsSIC[person]) this.salesPersonsSIC[person] = 0;
          if (!this.salesPersonsSales[person]) this.salesPersonsSales[person] = [];
          this.salesPersons[person] += parseFloat(amount);
          this.salesPersonsSales[person].push(sale);
          sale.salesPersons[person] = amount;
          if (sale._is_sic) {
            const amount_sic = ((sale.paid_with_sic || 0) / persons.length).toFixed(2);
            this.salesPersonsSIC[person] += parseFloat(amount_sic);
          }
        }
      } else {
        if (!this.salesPersons[sale.attention]) this.salesPersons[sale.attention] = 0;
        if (!this.salesPersonsSIC[sale.attention]) this.salesPersonsSIC[sale.attention] = 0;
        if (!this.salesPersonsSales[sale.attention]) this.salesPersonsSales[sale.attention] = [];
        
        this.salesPersons[sale.attention] += item_price;
        this.salesPersonsSales[sale.attention].push(sale);
        sale.salesPersons[sale.attention] = item_price.toString();
        // this.salesPersons[sale.attention] += parseFloat(sale.items_price || '0');
        // sale.salesPersons[sale.attention] = sale.items_price || '';
        if (sale._is_sic) {
          this.salesPersonsSIC[sale.attention] += sale.paid_with_sic || 0; //item_price;
        }
      }
    }

    //SetSalesPersons grand total
    for (let sale of this.arqueoService.sales.filter(filterNoCancelled)) {
      if (sale.salesPersons) {
        let day = moment(sale.created).format("D");
        for (let person in sale.salesPersons) {
          if (!this.salesPersonsTotals[person]) {
            this.salesPersonsTotals[person] = 0;
          }
          this.salesPersonsTotals[person] += parseFloat(sale.salesPersons[person] || '0');

          if (!this.salesPersonsPerDay[day]) this.salesPersonsPerDay[day] = {};
          if (!this.salesPersonsPerDay[day][person]) this.salesPersonsPerDay[day][person] = 0;

          this.salesPersonsPerDay[day][person] += parseFloat(sale.salesPersons[person] || '0');

          if (sale.total != sale.paid) {
            const dif = Math.abs(parseFloat(sale.total) - parseFloat(sale.paid));
            if (dif > 0.01) {
              if (!this.salesPersonsCxC[person]) this.salesPersonsCxC[person] = 0;
              this.salesPersonsCxC[person] += parseFloat(sale.salesPersons[person] || '0');
              this.totalCxC += parseFloat(sale.salesPersons[person] || '0');
            }
          }

          //total sales persons
          const persons = sale.attentions_total || 1;

          //Calculate total paid with gc per sales person
          if (sale.paid_with_gc && sale.paid_with_gc > 0) {
            if (!this.salesPersonsGC[person]) {
              this.salesPersonsGC[person] = 0;
            }

            if ( persons > 1) {
              const amount = (sale.paid_with_gc / persons).toFixed(2);
              this.salesPersonsGC[person] += parseFloat(amount);
            } else {
              this.salesPersonsGC[person] += sale.paid_with_gc;
            }

            if (!this.salesPersonsGCInvoices[person]) {
              this.salesPersonsGCInvoices[person] = [];
            }
            this.salesPersonsGCInvoices[person].push(sale.invoice.toString());
          }

          //Calculate total paid with nc per sales person
          if (sale.paid_with_nc && sale.paid_with_nc > 0) {
            console.log(person, sale.paid_with_nc, sale.invoice);
            if (!this.salesPersonsNC[person]) {
              this.salesPersonsNC[person] = 0;
            }

            if (persons > 1) {
              const amount = (sale.paid_with_nc / persons).toFixed(2);
              this.salesPersonsNC[person] += parseFloat(amount);
            } else {
              this.salesPersonsNC[person] += sale.paid_with_nc;
            }
            

            if (!this.salesPersonsNCInvoices[person]) {
              this.salesPersonsNCInvoices[person] = [];
            }
            this.salesPersonsNCInvoices[person].push(sale.invoice.toString());
          }

        }
      }

      if (sale.total != sale.paid) {
        //this.totalCxC += parseFloat(sale.total || '0') - parseFloat(sale.paid || '0');
      }

    }
   //console.log("Total CxC", this.totalCxC);

   //console.log('Grant sales persons total', this.salesPersonsTotals);
   //console.log('Grand sales persons per day', this.salesPersonsPerDay);
   //console.log('Grand sales persons CxC', this.salesPersonsCxC);
  }

  calculateTotals() {
    // const filterNoCancelled = (sale: Sale) => sale.status !== 15;
    // const salesTotal = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.commission || '0')).reduce((prev, curr) => {
    //   return prev + curr; 
    // }, 0);
    let salesTotal = 0;
    for (let person in this.salesPersonsTotals) {
      salesTotal += this.salesPersonsTotals[person];
      this.salesPersonsArray.push(person);
    }

    this.total = salesTotal;
    //this.totalComisionable = salesTotal - this.totalCxC;

    //Calculate total commisionable
    for (let person of this.salesPersonsArray) {
      //this.salesPersonsCommisionable[person] = (this.salesPersonsTotals[person] || 0) - (this.salesPersonsCxC[person] || 0) - (this.salesPersonsGC[person] || 0) - (this.salesPersonsNC[person] || 0) + (this.salesPersonsSIC[person]);
      this.salesPersonsCommisionable[person] = (this.salesPersonsTotals[person] || 0) - (this.salesPersonsCxC[person] || 0) + (this.salesPersonsSIC[person]);
      this.totalComisionable += this.salesPersonsCommisionable[person];
    }

   //console.log(`Total: ${salesTotal}`);
   //console.log(`Vta Comisionable: ${salesTotal - this.totalCxC}`);

    const filterNoCancelled = (sale: Sale) => sale.status !== 15;

    //TotalBrute
    this.totalBrute = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.total || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate E/I/R
    this.eir_total = this.arqueoService.sales.filter(filterNoCancelled).map(sale => {
      if (sale.items_other_sum && sale.stype !== 'scommission') {
        return sale.items_other_sum || 0;
      }
      return 0;
    }).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate contract
    this.contract_total = this.arqueoService.sales.filter(filterNoCancelled).filter(sale => sale.stype === 'contract').map(sale => parseFloat(sale.total || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate sin commision
    this.scommission_total = this.arqueoService.sales.filter(filterNoCancelled).filter(sale => {
      return sale.stype === 'scommission' &&
        (!sale.transactions_gc || sale.transactions_gc.length === 0) &&
        (!sale.transactions_nc || sale.transactions_nc.length === 0)
    }).map(sale => parseFloat(sale.samount || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate NC/Promo GC
    this.ncgc_total = this.arqueoService.sales.filter(filterNoCancelled).filter(sale => sale.totalPromo || 0 > 0).map(sale => sale.totalPromo || 0)
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate total other income
    this.other_income = this.eir_total + this.contract_total + this.scommission_total + this.ncgc_total;

    //Calculate shipping
    this.shipping_total = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.shipping_fee || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    //Calculate IVA
    this.iva_total = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.tax || '0') + parseFloat(sale.tax2 || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    // Calculate taxes total
    this.taxes_total = this.shipping_total + this.iva_total;

    // Calculate sales total
    // this.sales_total = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.total || '0')).reduce((prev, curr) => {
    //   return prev + curr;
    // }, 0);

    // Calculate saf total
    this.saf_total = this.arqueoService.sales.map(sale => sale.saf_total || 0).reduce((prev, curr) => {
      return prev + curr;
    }, 0);

    // Calculate total income
    //this.income_total = this.totalComisionable + this.other_income + this.taxes_total + this.cobranza_total;
    this.income_total = this.total + this.other_income + this.taxes_total + this.cobranza_total;

    // let payment_types_totals = {
    //   cash: 0,
    //   nc: 0,
    //   wf_trans: 0,
    //   mx_trans: 0,
    //   us_check: 0,
    //   mx_check: 0,
    //   cc_bb: 0,
    //   cc_ps: 0,
    //   cc_stripe: 0,
    //   cc_paypal: 0,
    //   unknown: 0
    // }

    this.arqueoService.sales.filter(sale => sale.status !== 15 && sale.cxc_month).forEach(sale => {
      let day = moment(sale.created).format("D");
      // CxC
      if (!this.cxc_daily[day]) {
        this.cxc_daily[day] = 0;
      }
      this.cxc_daily[day] += parseFloat(sale.cxc_month || '0');
    });

    this.cxc_total = this.arqueoService.sales.filter(sale => sale.status !== 15 && sale.cxc_month && parseFloat(sale.cxc_month)).map(sale => {
        //console.log("cxs sale", sale);
        return parseFloat(sale.cxc_month || '0');
      }).reduce((prev, cur) => {
      return prev + cur;
    }, 0);
    
    // Set daily
    this.arqueoService.sales.forEach(sale => {
      let day = moment(sale.created).format("D");

      if (sale.status !== 15) {
        // E/I/R
        if (sale.items_other_sum && sale.items_other_sum > 0 && sale.stype !== 'scommission') {    
          if (!this.eir_daily[day]) {
            this.eir_daily[day] = 0;
          }
          this.eir_daily[day] += sale.items_other_sum;
        }

        // Contract
        if (sale.stype === 'contract') {
          if (!this.contract_daily[day]) {
            this.contract_daily[day] = 0;
          }
          this.contract_daily[day] += parseFloat(sale.total);
        }

        // Sin comisión
        if (sale.stype === 'scommission') {
          if (!this.scommission_daily[day]) {
            this.scommission_daily[day] = 0;
          }
          this.scommission_daily[day] += parseFloat(sale.samount || '0');
        }

        // NC / GC
        if (sale.totalPromo && sale.totalPromo > 0) {
          if (!this.ncgc_daily[day]) {
            this.ncgc_daily[day] = 0;
          }
          this.ncgc_daily[day] += sale.totalPromo;
        }

        // Shipping
        if (!this.shipping_daily[day]) {
          this.shipping_daily[day] = 0;
        }
        this.shipping_daily[day] += parseFloat(sale.shipping_fee || '0');

        // IVA
        if (!this.iva_daily[day]) {
          this.iva_daily[day] = 0;
        }
        this.iva_daily[day] += parseFloat(sale.tax || '0') + parseFloat(sale.tax2 || '0');

        // Sales total
        if (!this.sales_daily[day]) {
          this.sales_daily[day] = 0;
        }
        this.sales_daily[day] += parseFloat(sale.total || '0');

        this.calculate_payment_types(sale.transactions_paid_same_month);

      } else {
        // All sales
        // saf total
        if (!this.saf_daily[day]) {
          this.saf_daily[day] = 0;
        }
        this.saf_daily[day] += sale.saf_total || 0;
      }  
    });

    this.calculate_payment_types(this.cobranza_transactions);

    // otros valores
    const cash_total = this.payment_types_hash['cash']['total'] || 0;
    const nc_total = this.payment_types_hash['nc']['total'] || 0;
    this.other_values_total = this.cxc_total + cash_total + nc_total; // cxc + cash + vc

    this.transfers_total = (this.payment_types_hash['wf_trans']['total'] || 0) + (this.payment_types_hash['mx_trans']['total'] || 0);
    this.checks_total = (this.payment_types_hash['us_check']['total'] || 0) + (this.payment_types_hash['mx_check']['total'] || 0);
    this.universo_369_total = this.payment_types_hash['u369']['total'] || 0;
    this.nomina_total = this.payment_types_hash['nomina']['total'] || 0;
    this.cc_total = (this.payment_types_hash['cc_bb']['total'] || 0) + (this.payment_types_hash['cc_ps']['total'] || 0) + (this.payment_types_hash['cc_stripe']['total'] || 0) + (this.payment_types_hash['cc_paypal']['total'] || 0) + this.universo_369_total;
    this.values_total = this.other_values_total + this.transfers_total + this.checks_total + this.cc_total + this.nomina_total;

   //console.log(`EIR total: ${this.eir_total}`, this.eir_daily);
   //console.log(`Contract total: ${this.contract_total}`, this.contract_daily);
   //console.log(`SinComision total: ${this.scommission_total}`, this.scommission_daily);
   //console.log(`NCGC total: ${this.ncgc_total}`, this.ncgc_daily);
   //console.log(`OTHER INCOME: ${this.other_income}`);
   //console.log(`Shipping total: ${this.shipping_total}`, this.shipping_daily);
   //console.log(`IVA total: ${this.iva_total}`, this.iva_daily);
   //console.log(`TAXES: ${this.taxes_total}`);
   //console.log(`SALES TOTAL: ${this.sales_total}`, this.sales_daily);
   //console.log(`SAF TOTAL: ${this.saf_total}`, this.saf_daily);
   //console.log(`Cobranza TOTAL: ${this.cobranza_total}`, this.cobranza_daily);
   //console.log(`INCOME TOTAL: ${this.income_total}`);
   //console.log(`CxC total: ${this.cxc_total}`, this.cxc_daily);
   console.log(`Payments hash`, this.payment_types_hash);
   //console.log(`Cobranza transactions`, this.cobranza_transactions);
   //console.log(`OTHER VALUES TOTAL: ${this.other_values_total}`);
   //console.log(`TRANSFERS TOTAL: ${this.transfers_total}`);
   //console.log(`CHECKS TOTAL: ${this.checks_total}`);
   //console.log(`CC TOTAL: ${this.cc_total}`);
   //console.log(`VALUES TOTAL: ${this.values_total}`);
    console.log("Comissionable = salesPersonsTotals - salesPersonsCxC - salesPersonsGC - salesPersonsNC + salesPersonsSIC");
    console.log("salesPersons", this.salesPersons);
    console.log("salesPersonsSales", this.salesPersonsSales);
    console.log("salesPersonsTotals", this.salesPersonsTotals);
    console.log("salesPersonsCXC", this.salesPersonsCxC);
    console.log("salesPersonsGC", this.salesPersonsGC);
    console.log("salesPersonsNC", this.salesPersonsNC);
    console.log("salesPersonsSIC", this.salesPersonsSIC);
    // for (const salesPerson in this.salesPersonsSales) {
    //   console.log(`Total ${salesPerson}`, this.salesPersonsSales[salesPerson].reduce((acc, sale) => acc + parseFloat(sale.items_price || "0"), 0));
    // }
  }

  calculate_payment_types(transactions?: Transaction[]) {
    transactions?.forEach(trans => {
      const amount = parseFloat(trans.amount || '0');
      const day = moment(trans.date).format("D");
      const reference = trans.reference || 'unknown';
      const type = this.getPaymentType(reference);
      if (type === 'nc') console.log(type, amount, trans);
     //console.log('MAPPING', reference, type);
      if (!this.payment_types_hash[type]['daily'][day]) {
        this.payment_types_hash[type]['daily'][day] = 0;
      }
      this.payment_types_hash[type]['daily'][day] += amount;
      this.payment_types_hash[type]['total'] += amount;
    });
  }

  getPaymentType(reference: string): string {
    //['WF Deposit', 'Cash USD', 'BB Banorte Visa', 'NC#1002', 'Paypal Visa', 'NC#986', 'Paypal American Express', 'Check BBVA #5076', 'Paypal AMEX', 'Paypal MC', 'WF Zelle', 'Check BBVA #5336', 'NC#993 ', 'Cash MX', 'Check Midfrst Bank #3501']
    reference = reference.toLowerCase();
    if (reference.indexOf('check') !== -1) {
      return "us_check";
    } else if (reference.indexOf('cheque') !== -1) { //reference.indexOf('bbva')
      return "mx_check";
    } else if (reference.indexOf('wf') !== -1) {
      return "wf_trans";
    } else if (reference.indexOf('transfer') !== -1 || reference.indexOf('deposit') !== -1) {
      return "mx_trans";
    } else if (reference.indexOf('cash') !== -1) {
      return "cash";
    } else if (reference.indexOf('bb') !== -1) {
      return "cc_bb";
    } else if (reference.indexOf('369') !== -1) {
      return "u369"
    } else if (reference.indexOf('nc') !== -1 || reference.indexOf('gc') !== -1 || reference.indexOf('vc') !== -1 || reference.indexOf('vale de caja') !== -1) {
      return "nc";
    } else if (reference.indexOf('ps') !== -1) {
      return "cc_ps";
    } else if (reference.indexOf('stripe') !== -1) {
      return "cc_stripe";
    } else if (reference.indexOf('paypal') !== -1) {
      return "cc_paypal";
    } else if (reference.indexOf('nomina') !== -1 || reference.indexOf('nómina') !== -1) {
      return "nomina";
    }
    return 'unknown';
  }

  getArrayAsString(arr: any[], separator = "<br>") {
    return arr.join(separator);
  }

  ngOnDestroy(): void {
    this.aliveSubscription?.unsubscribe();
  }

  downloadResumePDF() {
    if (!this.pdf_files.resume.loading) {
      this.pdf_files.resume.loading = true;
      let el = document.querySelector('#resume-wrapper') as HTMLElement;
      let self = this;
      html2canvas(el).then(function(canvas) {
        let dataURL = canvas.toDataURL("image/png");

        const doc = new jsPDF({
          orientation: 'landscape',
          unit: 'cm',
          format: 'letter'
        });

        //dataURL = doc.output('datauristring');

        const now = moment().format("D MMM yyyy");
        const reportDate = moment(self.filters.date.value).format("MMMM-yyyy");

        doc.addImage(dataURL, 'PNG', 1, 1, 26, 20);
        doc.setFontSize(9);
        doc.text(`${self.filters.store} - Reporte generado el ${now.toUpperCase()}`, 1, 21);
        self.pdf_files.resume.doc = doc;
        doc.save(`${self.filters.store}-resumen-${reportDate}`);

        self.pdf_files.resume.loading = false;

        //document.body.appendChild(canvas);
      });
    }
  }

  downloadDailyPDF() {

    if (!this.pdf_files.daily.loading) {
      this.pdf_files.daily.loading = true;
      this.generateDailySalesCanvas();
    }
  }

  generateDailySalesCanvas() {
    let el = document.querySelector('#daily-wrapper') as HTMLElement;
    let self = this;
    html2canvas(el).then(function(canvas) {
      let dataURL = canvas.toDataURL("image/png");

      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: 'letter'
      });

      //dataURL = doc.output('datauristring');

      const now = moment().format("D MMM yyyy");
      const generatedReportDate = moment(self.filters.date.value).format("MMMM yyyy");
      doc.addImage(dataURL, 'PNG', .5, .5, 25, 20);
      doc.setFontSize(9);
      doc.text(`${self.filters.store} - Reporte generado el ${now.toUpperCase()} (Se muestra desglose del mes de ${generatedReportDate})`, .5, 21);
      //doc.save('daily.pdf');

      self.pdf_files.daily.doc = doc;
      self.generateValoresCanvas();

      //document.body.appendChild(canvas);
    });
  }

  generateValoresCanvas() {
    let el = document.querySelector('#valores-wrapper') as HTMLElement;
    let self = this;
    html2canvas(el).then(function(canvas) {
      let dataURL = canvas.toDataURL("image/png");
      const now = moment().format("D MMM yyyy");
      const reportDate = moment(self.filters.date.value).format("MMMM-yyyy");
      const generatedReportDate = moment(self.filters.date.value).format("MMMM yyyy");
      self.pdf_files.daily.doc?.addPage('letter', "l");
      self.pdf_files.daily.doc?.addImage(dataURL, 'PNG', .5, .5, 25, 20);
      //self.pdf_files.daily.doc?.setFontSize(10);
      self.pdf_files.daily.doc?.text(`${self.filters.store} - Reporte generado el ${now.toUpperCase()} (Se muestra desglose del mes de ${generatedReportDate})`, .5, 21);
      self.pdf_files.daily.doc?.save(`${self.filters.store}-desglose-${reportDate}`);
      self.pdf_files.daily.loading = false;
    });
  }

  testHTML2Canvas() { 
    let el = document.querySelector('#daily-wrapper') as HTMLElement;
    html2canvas(el).then(function(canvas) {
      let dataURL = canvas.toDataURL("image/png");

      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: 'letter'
      });

      //dataURL = doc.output('datauristring');

      const now = moment().format("D MMM yyyy");

      doc.addImage(dataURL, 'PNG', 1, 1, 26, 20);
      doc.setFontSize(10);
      doc.text(`${now.toUpperCase()}`, 25, 21);
      doc.save('daily.pdf');

      //document.body.appendChild(canvas);
    });
  }

}