import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { TestComponent } from './test/test.component';
import { AuthComponent } from './auth/auth.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth-guard.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

import { UsersModule } from './mods/users/users.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { UsersService } from './mods/users/users.service';
import { CommonService } from './shared/common.service';
import { AlertService } from './shared/alert/alert.service';
import { HistoryModule } from './mods/history/history.module';
import { ArqueoModule } from './mods/arqueo/arqueo.module';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { RtmModule } from './mods/rtm/rtm.module';
import { TagsComponent } from './mods/tags/tags.component';
import { TagsModule } from './mods/tags/tags.module';
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    //AuthComponent,
    HomeComponent,
    SnackbarComponent,
    ComingSoonComponent,
    TagsComponent,
  ],
  imports: [
    BrowserModule,
    // FormsModule,
    // ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    // FontAwesomeModule,
    // BrowserAnimationsModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatButtonModule,
    // MatSnackBarModule,
    SharedModule,
    AuthModule,
    UsersModule,
    HistoryModule,
    ArqueoModule,
    RtmModule,
    TagsModule
    // ToastModule,
    // DialogModule,
    // ButtonModule
  ],
  providers: [
    AuthService, 
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {provide: LOCALE_ID, useValue: 'es-ES'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
