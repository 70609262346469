import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Plugin, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Item, Sale } from 'src/app/shared/common.enums';
import { NumberFormatPipe } from 'src/app/shared/pipes/number-format.pipe';
import { ArqueoService } from '../../arqueo/arqueo.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'dash-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.css']
})
export class DashboardDailySalesComponent implements OnInit, OnDestroy {

  @Input('store') store?: string;

  loading = false;
  loadingIcon = faSpinner;
  sbSyncSubscription?: Subscription;
  plugin?: Plugin;

  dataUpdate$: Subject<any> = new Subject;
  dataSubscription?: Subscription;
  data = {};

  sales: Sale[] = [];
  salesPersons: {[key: string]: number} = {};
  users: string[] = [];
  percents: string[] = [];
  total = 0;

  options: any = null;
  date?: string;

  dayTest = 0;

  constructor(private numberFormat: NumberFormatPipe, private dashboardService: DashboardService, private arqueoService: ArqueoService) { }

  ngOnInit(): void {

    this.date = moment().format("YYYY-MM-DD");

    this.sbSyncSubscription = this.dashboardService.sbSync$.subscribe((data: any) => {
      if (data.store && data.store == this.store) {
        //console.log("Update sales!!", data);
        this.updateChart();
      }
    });

    this.dataSubscription = this.dataUpdate$.subscribe((data) => {
      this.data = {
        labels: data.users,
        datasets: [
          {
            data: data.data,
            label: data.users,
            backgroundColor: this.dashboardService.getUserColors(this.users),
            hoverBackgroundColor: this.dashboardService.getUserColors(this.users)
          }
        ]
      }
    });

    this.plugin = ChartDataLabels;
    this.options = {
      plugins: {
        tooltip: {
          callbacks: {
            //beforeTitle: (item: any) => "beforeTitle",
            title: (items: any[]) => {
              return this.users[items[0].dataIndex];
            },
            //afterTitle: (item:any) => 'afterTitle',
            label: (item: any) => {
              return this.numberFormat.transform(item.raw);
            },
            //afterLabel: (item:any) => 'afterLabel',
            //beforeLabel: (item:any) => 'beforeLabel',
            //beforeBody: (item:any) => 'beforeBody',
            //afterBody: (item:any) => 'afterBody',
            footer: (items: any[]) => {
              //return this.getSalesPersonPercentage(parseFloat(items[0].raw))
              return this.percents[items[0].dataIndex];
            },
            //beforeFooter: (item:any) => 'beforeFooter',
            //afterFooter: (item:any) => 'afterFooter'
          }
        },
        datalabels: {
          display: true,
          align: 'center',
          backgroundColor: '#CCC',
          borderRadius: 3,
          formatter: (value: number) => {
            //return this.numberFormat.transform(value);
            return this.getSalesPersonPercentage(value);
          },
          font: {
            size: 14,
          }
        },
      }
    }

    this.updateChart();
  }

  yesterday() {
    this.date = moment(this.date).add(-1, 'day').format("YYYY-MM-DD");
    this.updateChart();
  }

  getSalesPersonPercentage(amount: number) {
    let total = 0;
    for (let person in this.salesPersons) {
      total += this.salesPersons[person] || 0;
    }
    const percent = (amount * 100) / total;
    return `${percent.toFixed(1)}%`;
  }

  updateChart() {
    this.getDailySales();
  }

  getDailySales() {
    //HDS
    let today = moment().add(this.dayTest, 'day');
    this.dayTest -= 1;
    let filters = {
      dateFormated: this.date,
      store: this.store
    }
    this.arqueoService.getSalesFromFilters(filters).pipe(
      tap((sales: Sale[]) => {
        this.salesPersons = {};
        this.users = [];
        this.percents = [];
        this.total = 0;
        this.sales = sales;
      }),
      switchMap(() => {
        const sales_ids = this.sales.map((sale: {id_sb: string}) => sale.id_sb);
        return this.arqueoService.getSalesItems(sales_ids);
      }),
      tap((items) => {
        this.setSalesItems(items);
        this.setSalesPersonsTotals();
      })
    ).subscribe(sales => {
      //console.log('sales', this.sales);
      //console.log('salesPerson', this.salesPersons);
      this.updateSales();
    });
  }

  test() {
    this.updateChart();
  }

  updateSales() {
    let users = [];
    for (let person in this.salesPersons) {
      users.push(`${person} ${this.numberFormat.transform(this.salesPersons[person])}`);
    }
    let data = {
      users: users,
      data: Object.values(this.salesPersons)
    };
    this.dataUpdate$.next(data);
  }

  setSalesPersonsTotals() {
    const filterNoCancelled = (sale: Sale) => sale.status !== 15 && sale.items_price && parseFloat(sale.items_price) > 0 && sale.stype !== 'contract';

    //Set sales persons sums
    for (let sale of this.sales.filter(filterNoCancelled)) {

      // if ((sale.totalPromo || 0) > parseFloat(sale.items_price || '0')) {
      //   continue;
      // }

      let item_price = parseFloat(sale.items_price || '0') - (sale.totalPromo || 0);

      if (sale.stype === 'scommission') {
        const samount = parseFloat(sale.samount || '0');
        if (!sale.samount || samount === 0 || sale.samount == sale.total) {
          continue;
        }
        //console.log(item_price, sale.items_price);
        item_price = parseFloat(sale.items_price || '0') - samount;
      }

      if (sale.attention.indexOf('/') !== -1) {
        //shared sale
        let persons = sale.attention.split('/');
        //const amount = (parseFloat(sale.items_price || '0') / 2).toFixed(2);
        const amount = (item_price / persons.length).toFixed(2);
        for (let person of persons) {
          person = person.trim();
          if (!this.salesPersons[person]) this.salesPersons[person] = 0;
          this.salesPersons[person] += parseFloat(amount);
          sale.salesPersons[person] = amount;
        }
      } else {
        if (!this.salesPersons[sale.attention]) this.salesPersons[sale.attention] = 0;
        
        this.salesPersons[sale.attention] += item_price;
        sale.salesPersons[sale.attention] = item_price.toString();
        // this.salesPersons[sale.attention] += parseFloat(sale.items_price || '0');
        // sale.salesPersons[sale.attention] = sale.items_price || '';
        
      }
    }

    this.users = Object.keys(this.salesPersons);
    this.dashboardService.updateUserColors(this.users);
    for (let user of this.users) {
      const total = this.salesPersons[user];
      this.total += total;
      this.percents.push(this.getSalesPersonPercentage(total));
    }
  }

  setSalesItems(items: Item[]) {
    this.sales.forEach((sale: Sale) => {
      sale.items = items.filter(item => item.sale_id === sale.id_sb)
      //sale.items_other = sale.items.filter(item => item.type === 'other' && item.name?.toLowerCase().indexOf('discount') === -1);
      sale.items_other = sale.items.filter(item => item.type === 'other' && parseFloat(item.price) > 0);
      sale.items_price = sale.items.filter(item => item.type === 'item').map(item => item.price).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');
      //Changed to item.price_fixed to match invoice 6385 HDS
      const discount = sale.items.filter(item => parseFloat(item.price) < 0).map(item => parseFloat(item.price)).reduce((prev, curr) => {
        return prev + curr;
      }, 0).toFixed(2);
      sale.discount = discount;
      sale.items_price_no_dicount = sale.items_price;
      sale.items_price = (parseFloat(sale.items_price) + parseFloat(discount)).toString();
      //Sum of services
      if (sale.items_other.length > 0) {
        sale.items_other_sum = sale.items_other.map(item => parseFloat(item.price)).reduce((prev, curr) => {
          return prev + curr;
        }, 0);
      }

      //validate not greater than sale total
      if (parseFloat(sale.items_price) > parseFloat(sale.total)) {
        sale.items_price = sale.total;
      }
    });
  }

  ngOnDestroy(): void {
    this.sbSyncSubscription?.unsubscribe();
    this.dataSubscription?.unsubscribe();
  }

}
