import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Invoice, RtmTemplateDynamicField, RtmTemplateField, RtmTemplateFieldType } from '../../rtm.enums';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { faCircleDot, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rtm-template-field',
  templateUrl: './rtm-template-field.component.html',
  styleUrls: ['./rtm-template-field.component.scss']
})
export class RtmTemplateFieldComponent implements OnInit {

  @Input() field!: RtmTemplateField;
  @Input() fillMode = false;
  @Input() invoice?: Invoice;
  @Output() onRemoveField = new EventEmitter<number>();
  bulletIcon = faCircleDot;
  faDelete = faTrashAlt;
  fieldTypes = RtmTemplateFieldType;
  dynamicTypes = RtmTemplateDynamicField;
  data: {[key: string]: any} = {};

  modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      // [{ font: [] }],
      [{ color: [] }, { background: [] }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ]
  };

  constructor() { }

  ngOnInit(): void {
    if (this.field.isDynamic) {
      const currentData = JSON.parse(this.field.data || "{}");
      if (this.field.dynamicType === RtmTemplateDynamicField.SIGNATURE) {
        this.data["name"] = currentData.name || "";
      }
      if (this.field.dynamicType === RtmTemplateDynamicField.SIGNATURE_DOUBLE) {
        this.data["name"] = currentData.name || "";
        this.data["name_2"] = currentData.name_2 || "";
      }
      if (this.field.dynamicType === RtmTemplateDynamicField.SHIPPING_ADDRESS) {
        if (!this.field.defaultValue && this.invoice) {
          this.field.defaultValue = this.invoice.shipping_address;
        }
      }
      this.field.data_o = this.data;
    }
  }

  onRequiredUpdate(e: MatSlideToggleChange) {
    this.field.isRequired = e.checked;
  }

  onShowOnlyValueUpdate(e: MatSlideToggleChange) {
    this.field.showOnlyValue = e.checked;
  }

  updateDefaultContent(e: any) {
    //ngModel is already updating the content
    //this.field.defaultValue = e.html;
  }

  onDateUpdate(date: string) {
    this.field.defaultValue = date;
  }

  removeField() {
    this.onRemoveField.emit(this.field.position);
  }

}
