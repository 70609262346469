import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import {TabViewModule} from 'primeng/tabview';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AbstractControl, FormControl, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ErrorStateMatcher, MatNativeDateModule } from '@angular/material/core';
import { AlertComponent } from './alert/alert.component';
import { BtnAutoFocus } from './autofocus.directive';
import { AlertService } from './alert/alert.service';
import { CommonService } from './common.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { OnlyNumber } from './only-numbers.directive';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { AuthService } from '../auth/auth.service';
import { NoCopyPasteDirective } from './directives/no-copy-paste.directive';
import { AlertV2Component } from './alert-v2/alert-v2.component';
import { EnumKeyPipe } from './pipes/enum-value.pipe';
import { DateInputComponent } from './date-input/date-input.component';
import { NoUnderscorePipe } from './pipes/no-underscore.pipe';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export function passwordValidator(control: AbstractControl) : ValidationErrors | null {
  if (control && control.value) {
    const isLengthEnough = control.value.length >= 8;
    if (!isLengthEnough) {
      return {tooShort: true};
    }
  }
  return null;
}

export function ClientValidator(control: AbstractControl): ValidationErrors | null {
  if (control && control.value) {
    if (!control.value.hasOwnProperty('id')) {
      return {invalidClient: true};
    }
  }
  return null;
}

@NgModule({
  declarations: [
    AlertComponent,
    BtnAutoFocus,
    OnlyNumber,
    NumberFormatPipe,
    EnumKeyPipe,
    NoUnderscorePipe,
    NoCopyPasteDirective,
    AlertV2Component,
    DateInputComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    TableModule,
    PanelModule,
    MessagesModule,
    MessageModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    InputTextModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatAutocompleteModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    DynamicDialogModule,
    TabViewModule
  ],
  exports: [
    AlertComponent,
    AlertV2Component,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    TableModule,
    PanelModule,
    MessagesModule,
    MessageModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatAutocompleteModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    DynamicDialogModule,
    TabViewModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    InputTextModule,
    BtnAutoFocus,
    OnlyNumber,
    NoCopyPasteDirective,
    NumberFormatPipe,
    EnumKeyPipe,
    DateInputComponent,
    NoUnderscorePipe
  ],
  providers: [
    AlertService,
    CommonService,
    ConfirmationService,
    AuthService,
    NumberFormatPipe
  ],
})
export class SharedModule {}