import { SalesService } from './../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { faAddressBook, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Subscription, Subject, Observable } from 'rxjs';
import { Store, Stores } from 'src/app/shared/common.enums';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesClientesViewComponent } from './sales-clientes-view/sales-clientes-view.component';
import { SalesClientesAddressComponent } from './sales-clientes-address/sales-clientes-address.component';
import { Cliente } from '../sales.interfaces';
import { SalesClientesUtilComponent } from './sales-clientes-util/sales-clientes-util.component';
import { AuthService } from 'src/app/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { SalesClientesCreateComponent } from './sales-clientes-create/sales-clientes-create.component';

@Component({
  selector: 'app-sales-clientes',
  templateUrl: './sales-clientes.component.html',
  styleUrls: ['./sales-clientes.component.css'],
  providers: []
})
export class SalesClientesComponent implements OnInit, OnDestroy {
  
  clientes: Cliente[] = [];
  clientesFiltrados: Cliente[] = []
  PreClientes = Array.from({ length: 10 })
  loading = true;
  private destroy$ = new Subject();

  clientsIcon = faAddressBook;
  loadingIcon = faSpinner;
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };
  stores = [...Stores];
  searchCliente = "";
  customersNames: string[] = [];
  searchSubscription?: Subscription;
  search$: Subject<any> = new Subject();
  router: any;
  faSearch = faSearch
  featureAccess = {
    config: false,
    admin: false
  };

  constructor(
    public commonService: CommonService,
    public dialogService: DialogService,
    private salesService: SalesService,
    public authService: AuthService
    ) { }

  ngOnInit(): void {
    this.setFeatureAccess()
    
    this.ClientesTable()
    
    this.loading = true
    
    this.salesService.socket.emit("joinRoom", "clientes_table")
    
    this.salesService.socket.on("clienteActualizado", (cliente) => {
      this.table_fetch(cliente)
    })

    if(this.featureAccess.admin === true){
      this.salesService.socket.on('clientes_table_admin', (cliente) => {
      this.table_fetch(cliente)
    })}
  }

  ngOnDestroy(): void {
    this.salesService.socket.emit("leave_room", "clientes_table")
    this.salesService.socket.emit("leave_room", "clientes_table_admin")
    this.searchSubscription?.unsubscribe();
  }
  
  filtrarClientes() {
    const filtro = this.searchCliente.toLowerCase().trim();
    
    if (filtro === "") {
      this.clientesFiltrados = [...this.clientes]; // Si está vacío, mostrar todos
    } else {
      this.clientesFiltrados = this.clientes.filter(cliente =>
        cliente.nombre.toLowerCase().includes(filtro)
      );
    }
  }

  table_fetch(cliente: Cliente){
    //console.log(cliente)
    const index = this.clientes.findIndex(c => c.id === cliente.id)

    if(index !== -1){
      this.clientes[index] = cliente
      this.clientesFiltrados[index] = cliente
    }else{
      this.clientes.push(cliente)
      this.clientesFiltrados.push(cliente)
    }
  }

  setFeatureAccess(){
    const access = this.authService.user.value?.access || [];
    this.featureAccess.config = access.includes("config:view");
    this.featureAccess.admin = access.includes("sales:cliente-admin")
  }

  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  };

  ClientesTable() {
    this.salesService.getClientes().pipe(takeUntil(this.destroy$)).subscribe(
      (clientes) => {
        this.clientes = clientes
        this.clientesFiltrados = clientes
        this.loading = false
      },(error)=>{
        this.commonService.showErrorMessage(error);
    });
  };

  getSeverity(status: string) {
    switch (status) {
        case 'Vencido':
            return 'danger';
        case 'Sin iniciar':
            return "success";
        case 'Enviado':
            return 'primary';
        case 'Proximo a vencer':
            return 'warning';
        case 'Terminado':
            return 'success'
        default:
            return 'warning';
    }
  };
  
  showClient(id: number, nombre: string, seguimiento: string) {
    const ref = this.dialogService.open(SalesClientesViewComponent, {
      header: `Cliente (${nombre})`,
      width: '70%',
      data: {id, nombre, seguimiento, button: true} 
    });
  };

  showAddress(id: number, nombre: string) {
   this.dialogService.open(SalesClientesAddressComponent, {
      header: `Dirección(es) de ${nombre}`,
      width: '70%',
      data: {id, nombre}
    })
  }

  showAddViews(){
    this.dialogService.open(SalesClientesCreateComponent, {
      header: `Agregar Cliente`,
      width: '70%',
    });
  };

  updateFollowUP(
    id: number, 
    idCliente: number, 
    step:number, 
    stepz:number, 
    idFollowing: number, 
    tipo: string, 
    asistenteId: number, 
    idioma: string,
    nombre: string,
    salesOpportunity: number
  ){ 
    /* console.log("id", id, "idCliente", idCliente, "step", step, "stepz", stepz, "idFollowing", idFollowing, "tipo", tipo, "asistenteId", asistenteId, "idioma", idioma)
    return */
    const newStep = step + 1;

    const ref = this.dialogService.open(SalesClientesUtilComponent, {
      header: `Actualizar seguimiento`,
      width: '80%',
      data: {tipo, idFollowing, step, idioma, idCliente, nombre, salesOpportunity, id}
    })
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((trigger) => {
    if(!trigger){
      this.commonService.showInfoMessage("Se cancelo la acción");
      return;
    }
    
    if(newStep > stepz){
      const data = {
        idFollowUP: id,
        idCliente: idCliente,
        step: step,
        stepz: stepz,
        idFollowing: idFollowing,
        accion: tipo,
        idAsistente: asistenteId,
        msgId: trigger
      }
      //console.log("se terminara el seguimiento porque ya no hay mas pasos");
      this.salesService.endFollowUP(data).subscribe(
        (response) => {
          this.commonService.showSuccessMessage(response.msg);
        },
        (error) => {
          this.commonService.showErrorMessage(error);
        }
      )
    }else if(newStep <= stepz){
      const data = {
        idFollowUP: id,
        idCliente: idCliente,
        step: newStep,
        actualStep: step,
        stepz: stepz,
        idFollowing: idFollowing,
        tipo: tipo,
        idAsistente: asistenteId,
        msgId: trigger
      }
      //console.log("se actualizará el paso");
      this.salesService.updateFollowUP(data).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          this.commonService.showSuccessMessage(response.msg);
        },
        (error) => {
          this.commonService.showErrorMessage(error);
        }
      )
    }})
  }
}
