import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { RtmService } from '../rtm.service';
import { CommonService } from 'src/app/shared/common.service';
import { faCalendar, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { MOMENT_FORMAT_DATE, SelectOption, Store, Stores } from 'src/app/shared/common.enums';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { Agenda, AgendaType, AgendaSubType, AgendaStatus } from '../rtm.enums';
import * as moment from 'moment';

@Component({
  selector: 'app-rtm-agenda',
  templateUrl: './rtm-agenda.component.html',
  styleUrls: ['./rtm-agenda.component.css']
})
export class RtmAgendaComponent implements OnInit, OnDestroy {

  @ViewChild('alert') alert!: AlertV2Component;

  private destroy$ = new Subject();
  agendaIcon = faCalendar;
  fullSize: boolean = false;
  currentStore?: Store;
  loadingIcon = faSpinner;
  loading = false;

  agendas: Agenda[] = [];
  stores = [...Stores];
  agendaStatus: SelectOption[] = [];
  agendaTypes: SelectOption[] = [];
  filters: {[key:string]: any} = {};

  today: string;

  constructor(
    private rtmService: RtmService,
    private commonService: CommonService
  ) {
    this.today = moment().format(MOMENT_FORMAT_DATE);
    this.commonService.currentStore$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(store => {
      this.currentStore = store;
    });

    this.commonService.fullSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(fullSize => {
      this.fullSize = fullSize;
    });

    const statuses = AgendaStatus as any;
    for (const status in statuses) {
      this.agendaStatus.push({label: status, value: statuses[status]});
    }

    const types = AgendaType as any;
    for (const type in types) {
      this.agendaTypes.push({label: type, value: types[type]});
    }
  }

  ngOnInit(): void {
    this.rtmService.agendaFilters$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(filters => {
      if (filters && filters.status) {
        this.filters = filters;
        if (filters.store) {
          this.commonService.currentStore$.next(filters.store);
        }
        this.getAgendas(filters);
      }
    });
    
    this.initFilters();
  }

  initFilters() {
    const currentFilters = this.rtmService.agendaFilters$.getValue();
    const currentDate = currentFilters.hasOwnProperty("date") ? currentFilters['date'] : null;
    if (!currentFilters || !currentFilters.store) {
      this.rtmService.agendaFilters$.next({
        store: currentFilters['store'] || '',
        type: "todos",
        date: currentDate === null ? this.today : currentDate,
        status: AgendaStatus['En Progreso']
      });
    }
  }

  onChangeStore() {
    if (this.filters.store) {
      this.commonService.currentStore$.next(this.filters.store);
    }
  }

  onDateUpdate(date: string) {
    this.filters['date'] = date;
  }

  onUpdateAgendas() {
    this.loading = true;
    this.rtmService.agendaFilters$.next(this.filters);
  }

  getAgendas(filters: {[key:string]: any}) {
    this.rtmService.getAgendas(filters).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agendas => {
        this.agendas = agendas;
        this.loading = false;
      },
      error: errorMessage => {
        this.loading = false;
        this.commonService.showErrorMessage(errorMessage);
      }
    })
  }

  onEntregaAdded() {
    this.onUpdateAgendas();
  }

  onSizeChange() {
    this.commonService.fullSize$.next(!this.fullSize);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
