import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight, faCompressAlt, faEdit, faExpand, faExpandAlt, faList, faListAlt, faPlusCircle, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss', '../../shared/scss/structure.columns.scss']
})
export class UsersComponent implements OnInit, OnChanges, OnDestroy {

  faList = faList;
  faPlus = faPlusCircle;
  faEdit = faEdit;
  faList2 = faListAlt;
  faExpand = faAngleDoubleRight;
  faCollapse = faAngleDoubleLeft;
  showTools = false;
  viewSettings = {
    menuCollapsed: false
  }
  featureAccess = {
    list: false,
    add: false,
    modify: false,
    dptos: false
  }

  constructor(
    private router: Router, 
    private usersService: UsersService, 
    public alertService: AlertService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setFeatureAccess();
    this.redirectToFeaturePage();
  }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.list = access.includes("users:view");
    this.featureAccess.add = access.includes("users:edit");
    this.featureAccess.modify = access.includes("users:edit");
    this.featureAccess.dptos = access.includes("users:manage-dptos");
  }

  redirectToFeaturePage() {
    if (this.featureAccess.list) {
      this.router.navigate(['/usuarios/lista']);
    } else if (this.featureAccess.add) {
      this.router.navigate(['/usuarios/agregar']);
    } else if (this.featureAccess.dptos) {
      this.router.navigate(['/usuarios/dptos']);
    } else {
      this.router.navigate(['/panel']);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("onchanges!");
  }

  expandCollapseSubmenu(){
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }

  ngOnDestroy(): void {
    this.usersService.users = [];
    this.usersService.editingUser = null;
  }

}
