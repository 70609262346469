import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UsersListComponent } from './users-list/users-list.component';
import { UsersAddComponent } from './users-add/users-add.component';
import { UsersService } from './users.service';
import { DptosListComponent } from './dptos-list/dptos-list.component';
import { DptoFormComponent } from './dpto-form/dpto-form.component';

export const usersRoutes: Routes = [
  { path: "usuarios", canActivate:[AuthGuard], component: UsersComponent, children: [
    { path: "lista", canActivate:[AuthGuard], component: UsersListComponent },
    { path: "agregar", canActivate:[AuthGuard], component: UsersAddComponent },
    { path: "modificar", canActivate:[AuthGuard], component: UsersAddComponent },
    { path: "dptos", canActivate:[AuthGuard], component: DptosListComponent }
  ] },
  //{ path: "usuarios/lista/", canActivate:[AuthGuard], component: UsersListComponent }
]

@NgModule({
  declarations: [
    UsersComponent,
    UsersListComponent,
    UsersAddComponent,
    DptosListComponent,
    DptoFormComponent
  ],
  imports: [ 
    RouterModule,
    CommonModule,
    SharedModule
  ],
  exports: [],
  providers: [
    UsersService
  ],
  entryComponents: []
})
export class UsersModule {}