import { HttpClient } from '@angular/common/http';
import { chat, msg} from './../sales.interfaces';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/common.service';
import { catchError } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SalesChatsService {

  user_id = localStorage.getItem("userData") || ""

  featureAccess={
    admin: false
  }

  socket: any
  public chats$: Subject<chat[]> = new Subject();

  constructor(
    private http: HttpClient,
    public commonservice: CommonService,
    public authService: AuthService
  ) { 

    const access = this.authService.user.value?.access || [];
    this.featureAccess.admin = access.includes("chats:view_admin");

    this.socket = io(environment.socketio, {query:{user_id: JSON.parse(this.user_id).id, is_admin: this.featureAccess.admin}});

    this.socket.on('chat_notification', (data: chat[]) => { 
      this.chats$.next(data);
    });
  }

  private chatIdSource = new BehaviorSubject<{id_cliente: number; correo: string; chat_id: number, chat_important: number, ejecutivo:any} | null>(null);
  chatId$ = this.chatIdSource.asObservable(); // Observable para suscribirse

  //Chats
  getChats$(): Observable<chat[]>{
    return this.chats$.asObservable()
  }

  getChats():Observable<chat[]>{
    return this.http.get<any>(`${environment.endpoint}ventas/chats/getChats`).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  changeChat(id_cliente: number, correo: string, chat_id: number, chat_important: number, ejecutivo: any) {
    this.chatIdSource.next({id_cliente, correo, chat_id, chat_important, ejecutivo})
  }  

  getMsg(id:number):Observable<msg[]>{
    return this.http.get<any>(`${environment.endpoint}ventas/chats/getMails/${id}`)
  }

  getMsgBody(id:number):Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/chats/getBodyMail/${id}`).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  sendMail(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}Mails/sendMail`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  normalizeFile(file: any): { name: string, type: string, data: Uint8Array } {
    let name = file.filename || file.name; // Tomar el nombre correcto
    let type = file.contentType || file.type; // Tomar el tipo MIME
    let data: Uint8Array;
  
    if (file.base64) {
      // Caso Base64: Extraer y convertir
      const base64Data = file.base64.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      data = new Uint8Array(byteNumbers);
    } else if (file.content?.data) {
      // Caso Buffer: Convertir directamente
      data = new Uint8Array(file.content.data);
    } else {
      throw new Error("Formato de archivo no compatible");
    }
  
    return { name, type, data };
  }  

  download_File(file: any) {
    try {
      const normalizedFile = this.normalizeFile(file);
  
      // Crear un Blob y forzar la descarga
      const blob = new Blob([normalizedFile.data], { type: normalizedFile.type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = normalizedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  }

  confirmViewMsg(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/chats/view_confirm`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  Mark_as_unread(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/chats/mark_as_unread`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  delete_msg(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/chats/delete_msg`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  delete_chat(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/chats/delete_chat`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }

  Mark_as_favorite(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/chats/mark_as_favorite`, data).pipe(
      catchError(this.commonservice.handleRequestError)
    )
  }
}
