import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './noauth-guard.service';
import { SharedModule } from '../shared/shared.module';


// export const authRoutes: Routes = [
//   //{ path: "login", canActivate:[NoAuthGuard], component: AuthComponent }
//   { path: "/login", component: AuthComponent }
// ]

@NgModule({
  declarations: [
    AuthComponent
  ],
  imports: [ 
    RouterModule,
    CommonModule, 
    SharedModule
  ],
  exports: [],
  providers: [],
})
export class AuthModule {}