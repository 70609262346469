import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesService } from '../../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-sales-clientes-view-following',
  templateUrl: './sales-clientes-view-following.component.html',
  styleUrls: ['./sales-clientes-view-following.component.css']
})
export class SalesClientesViewFollowingComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  seguimientos: any[] = [];
  form: FormGroup = this.fb.group({});
  diseableInput = true

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public salesServices: SalesService,
    private fb: FormBuilder,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getSeguimientos();
    this.form = this.fb.group({
      following: [''],
      inicio: [1, [Validators.min(1)]]
    });
  }

  onSubmit(){
    if(this.form.value.following === this.seguimientos){
      this.commonService.showWarningMessage("El seguimiento no puede estar vacio")
      return
    }
    if( this.form.value.following.duracion < this.form.value.inicio){
      this.commonService.showWarningMessage("El inicio no puede ser mayor a la duracion")
      return
    }
    

    let data = {
      idCliente: this.config.data.idCliente,
      idSeguimiento: this.form.value.following.id,
      duration: this.form.value.following.duracion,
      inicio: this.form.value.inicio,
      asistente: this.config.data.asistente,
      idAsistente: this.config.data.idAsistente
    }
    //console.log(this.form.value)
    //console.log('Data:', data);
    
    this.salesServices.addFollowingCliente(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg);
        this.ref.close();
      }, error=>{
        this.commonService.showErrorMessage(error);
      })}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSeguimientos(){
    this.salesServices.getSeguimientos().pipe(takeUntil(this.destroy$)).subscribe(
      (seguimientos) => {
        // Filtrar seguimientos para mostrar solo los activos
        this.seguimientos = seguimientos.filter((follow: { status: string; }) => follow.status === 'Activo');
        //console.log(this.seguimientos)
        this.form.patchValue({
          following: this.seguimientos
        });
      } 
    );
  }
  
}
