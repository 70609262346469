import { Component, OnInit } from '@angular/core';
import { Cliente } from '../../sales.interfaces';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesService } from '../../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { UsersService } from 'src/app/mods/users/users.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sales-clientes-edit',
  templateUrl: './sales-clientes-edit.component.html',
  styleUrls: ['./sales-clientes-edit.component.css'],
  providers: [MessageService]
})
export class SalesClientesEditComponent implements OnInit {

  asistentes : any[] = [];
  seguimientos: any[] = [];
  Referenciados: any[] = [];
  Tipos_de_ventas: any[] = [];
  Tipos_de_interes: any[] = [];
  Tipos_de_Clientes: any[] = [];

  featureAccess = {
    config: false,
  };

  private destroy$ = new Subject();
  action: string;
  form: FormGroup = this.fb.group({});

  cliente: Cliente;
 
  constructor(
    public config: DynamicDialogConfig, 
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    private salesServices: SalesService,
    public commonService: CommonService,
    private authService: AuthService,
    public userService: UsersService,
    public messageService: MessageService,
  ){
    this.cliente = this.config.data.cliente;
    this.action = this.config.data.action;
  }

  setFeatureAccess(){
    const access = this.authService.user.value?.access || [];
    this.featureAccess.config = access.includes("config:view");
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.setFeatureAccess()
    this.getReferenciados()
    this.getSeguimientos()
    this.getAsistentes()
    this.getTiposDeVentas()
    this.getTiposDeInteres()
    this.getTiposDeCliente()
    this.form = this.fb.group({
      id: [this.cliente?.id || ''],
      nombre: [this.cliente?.nombre || ''],
      apellido: [this.cliente?.apellido || ''],
      email: [this.cliente?.email || '', [Validators.email]],
      email_2: [this.cliente?.email_2 || '', [Validators.email]],
      phone: [this.cliente?.phone || ''],
      phone_2 : [this.cliente?.phone_2 || ''],
      type_interest_1: [this.cliente?.id_type_interest_1 || null],
      type_interest_2: [this.cliente?.id_type_interest_2 || null],
      type_interest_3: [this.cliente?.id_type_interest_3 || null],
      contactMethod: [this.cliente?.contactMethod || null],
      referencia: [this.cliente?.referencia.id_referenced || null],
      shared_asist: [this.cliente?.asistente_compartido.idAsistans || null],
      main_asist: [this.cliente?.asistente.idAsistans|| null],
      type_client: [this.cliente?.type_client.id || null],
      idioma: [this.cliente?.idioma || null]
    });
  }

  getReferenciados(){
    this.salesServices.getReferenciados().pipe(takeUntil(this.destroy$)).subscribe(
      (Referenciados) => {
        this.Referenciados = Referenciados.filter((Referenciados: { status: string; }) => Referenciados.status === 'Activo');
        //console.log(this.Referenciados)
        this.form.patchValue({
          Referenciados: this.Referenciados
        });
      })
  }
  
  getSeguimientos(){
    this.salesServices.getSeguimientos().pipe(takeUntil(this.destroy$)).subscribe(
      (seguimientos) => {
        this.seguimientos = seguimientos.filter((seguimientos: { status: string; }) => seguimientos.status === 'Activo');
        //console.log(this.seguimientos
        this.form.patchValue({
          seguimientos: this.seguimientos
        });
      })
  }

  getAsistentes(){
    this.salesServices.getAsistentes().pipe(takeUntil(this.destroy$)).subscribe(
      (asistentes) => {
        this.asistentes = asistentes
      })
  }

  getTiposDeVentas(){
    this.salesServices.getTiposDeVentas().pipe(takeUntil(this.destroy$)).subscribe(
      (Tipos_de_ventas) => {
        this.Tipos_de_ventas = Tipos_de_ventas.filter((Tipos_de_ventas: { status: string; }) => Tipos_de_ventas.status === 'Activo');
        //console.log(this.Tipos_de_ventas)
        this.form.patchValue({
          Tipos_de_ventas: this.Tipos_de_ventas
        })
      })
  }

  getTiposDeInteres(){
    this.salesServices.getTiposDeInteres().pipe(takeUntil(this.destroy$)).subscribe(
      (Tipos_de_interes) => {
        this.Tipos_de_interes = Tipos_de_interes.filter((Tipos_de_interes: { status: string; }) => Tipos_de_interes.status === 'Activo');
        //console.log(this.Tipos_de_interes)
        this.form.patchValue({
          Tipos_de_interes: this.Tipos_de_interes
        })
      })
  }

  getTiposDeCliente(){
    this.salesServices.getTiposDeCliente().pipe(takeUntil(this.destroy$)).subscribe(
      (Tipos_de_Clientes) => {
        this.Tipos_de_Clientes = Tipos_de_Clientes.filter((Tipos_de_Clientes: {status: string}) => Tipos_de_Clientes.status === 'Activo');
        this.form.patchValue({
          Tipos_de_Clientes: this.Tipos_de_Clientes
        })
      })
    }

  onSubmit() {
    if (!this.form.get('nombre')?.value || !this.form.get('apellido')?.value) {
      this.commonService.showWarningMessage('The first name and last name are required.');
      return;
    }

    let data = this.form.value;
    this.editCliente(data);
  }

  editCliente(data: Cliente){
    //console.log(data)
    this.salesServices.editClient(data).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.commonService.showSuccessMessage(response.msg);
      this.ref.close();
    }, error => {
      this.commonService.showErrorMessage(error);
    });
  }
  
  cancel() {
    this.form.reset();
    this.ref.close();
  }
}
