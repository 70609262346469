import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SalesService } from '../../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SalesClientesAddressDesarrolloComponent } from './sales-clientes-address-desarrollo/sales-clientes-address-desarrollo.component';
import { SalesClientesAddressPersonalizadaComponent } from './sales-clientes-address-personalizada/sales-clientes-address-personalizada.component';
import { SalesClientesAddressLocalComponent } from './sales-clientes-address-local/sales-clientes-address-local.component';
import { SalesClientesAddressForaneaComponent } from './sales-clientes-address-foranea/sales-clientes-address-foranea.component';
import { SalesAddressService } from '../../sales-address.service';
import { SalesClientesAddressViewClientesComponent } from './sales-clientes-address-view-clientes/sales-clientes-address-view-clientes.component';
import { Address, AddressCasaDesarrollo, Addressdesarrollo, AddressForanea, AddressLocal, AddressPersonalizada, AddressResidencial, addressText, AddressVilla, TipeAddressText } from '../../sales-search/address.enum';
import { FormBuilder } from '@angular/forms';
import { map, startWith, switchMap } from 'rxjs/operators';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { SalesDireccionesService } from '../../sales-direcciones/sales-direcciones.service';

@Component({
  selector: 'app-sales-clientes-address',
  templateUrl: './sales-clientes-address.component.html',
  styleUrls: ['./sales-clientes-address.component.css']
})
export class SalesClientesAddressComponent implements OnInit {
  
  @ViewChild('alert') alert!: AlertV2Component;

  searchSubscription?: Subscription;

  inputSearch = ''

  idAddressSelected = 0

  faSearch = faSearch

  //villa
  villa: AddressVilla[] = []
  //desarrollo
  desarrollo : Addressdesarrollo[] = []
  //casa_desarrollo
  casa: AddressCasaDesarrollo[] = []
  //local
  local : AddressLocal[] = []
  //personalizada 
  personalizada: AddressPersonalizada[] = []
  //foranea
  foranea: AddressForanea[] = []
  //residencial
  residencial: AddressResidencial[] = []

  AddressesText: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  TipeAddressText: TipeAddressText[] = []
  AddressesTextDesarrollo: addressText[] = []
  AddressesTextCasaDesarrollo: addressText[] = []
  AddressesTextLocal: addressText[] = []
  AddressesTextPersonalizada: addressText[] = []
  AddressesTextResidencial: addressText[] = []
  AddressesTextForanea: addressText[] = []
  AddressesTextVilla: addressText[] = []

  addressesSearch: any[] = []

  stateForm = this.fb.group({})
  
  constructor(
    public config: DynamicDialogConfig,
    public salesService: SalesService,
    public dialogService: DialogService,
    public commonService: CommonService,
    public SalesAddressService: SalesAddressService,
    public fb: FormBuilder,
    public alertService: AlertService,
    public AddressesServices: SalesDireccionesService
  ) {
    this.stateForm = this.fb.group({
      text: ['']
    })
  }

  ngOnInit(): void {
    this.getClienteAddresses()
    this.getSearchAddresses()
    this.AddressesServices.setAddresses(this.TipeAddressText);

    this.stateForm.get('text')?.valueChanges
    .pipe(startWith(''), switchMap(value => this.AddressesServices.filterAddresses(value)))
    .subscribe(filteredAddresses => {
      this.AddressesText.next(filteredAddresses);
    });
    
  }

  resetValues(){
    this.desarrollo = []
    this.local = []
    this.personalizada = []
    this.foranea = []
    this.villa = []
    this.casa = []
    this.residencial = []
  }

  getSearchAddresses(){
    this.SalesAddressService.getAddresses().pipe().subscribe(
      (addresses)=>{
        for(let address of addresses){
          const addressText = this.AddressesServices.buildAddressText(address)
          switch(address.tipo){
            case 'desarrollo':
              this.AddressesTextDesarrollo.push(addressText)
              break
            case 'casa_desarrollo':
              this.AddressesTextCasaDesarrollo.push(addressText)
              break
            case 'local':
              this.AddressesTextLocal.push(addressText)
              break
            case 'personalizada':
              this.AddressesTextPersonalizada.push(addressText)
              break
            case 'residencial':
              this.AddressesTextResidencial.push(addressText)
              break
            case 'foranea':
              this.AddressesTextForanea.push(addressText)
              break
            case 'villa':
              this.AddressesTextVilla.push(addressText)
              break
          }
        }
        this.TipeAddressText.push({tipo:'Desarrollos', address: this.AddressesTextDesarrollo})
        this.TipeAddressText.push({tipo:'Casas en desarrollos', address: this.AddressesTextCasaDesarrollo})
        this.TipeAddressText.push({tipo:'Locales', address: this.AddressesTextLocal})
        this.TipeAddressText.push({tipo:'Personalizadas', address: this.AddressesTextPersonalizada})
        this.TipeAddressText.push({tipo:'Residenciales', address: this.AddressesTextResidencial})
        this.TipeAddressText.push({tipo:'Foraneas', address: this.AddressesTextForanea})
        this.TipeAddressText.push({tipo:'Villas', address: this.AddressesTextVilla})
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getClienteAddresses(){
    this.resetValues()
    this.SalesAddressService.getClienteAddresses(this.config.data.id).subscribe(
      (addresses)=>{
        for(let address of addresses){
          switch(address.tipo){
            case 'desarrollo':
              this.desarrollo.push(address);
              break
            case 'casa_desarrollo':
              this.casa.push(address)
              break
            case 'local':
              this.local.push(address)
              break
            case 'personalizada':
              this.personalizada.push(address)
              break
            case 'residencial':
              this.residencial.push(address)
              break
            case 'foranea':
              this.foranea.push(address)
              break
            case 'villa':
              this.villa.push(address)
              break
          }
        }
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }
  ViewAddDesarrollos(){
    const ref = this.dialogService.open(SalesClientesAddressDesarrolloComponent, {
      header: `Agregar direccion de desarrollo a ${this.config.data.nombre}`,
      width: "50%",
      height: "100%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getClienteAddresses()
        }
      }
    )
  }

  ViewAddForanea(){
    const ref = this.dialogService.open(SalesClientesAddressForaneaComponent, {
      header: `Agregar dirección Foranea a cliente ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getClienteAddresses()
        }
      }
    )
  }

  ViewAddLocal(){
    const ref = this.dialogService.open(SalesClientesAddressLocalComponent, {
      header:`Agregar dirección Local a cliente ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getClienteAddresses()
        }
      }
    )
  }

  ViewAddPersonalizada(){
    const ref = this.dialogService.open(SalesClientesAddressPersonalizadaComponent,{
      header: `Agregar direccion personalizada a ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getClienteAddresses()
        }
      }
    )
  }

  viewAddress(id: number){
    this.dialogService.open(SalesClientesAddressViewClientesComponent, {
      header:"Clientes en la dirección",
      width:"50%",
      data: id
    })
  }

  onOptionSelected(event: any): void {
    // Elimina las etiquetas HTML del texto seleccionado
    const sanitizedText = this.stripHtml(event.option.value);
    this.stateForm.get('text')?.setValue(sanitizedText);
  }

  OnselectedAddress(id: number){
    this.idAddressSelected = id
  }

  OnAddAddressToCliente(){
    const data = {
      idAddress: this.idAddressSelected,
      idCliente: this.config.data.id
    } 
    this.SalesAddressService.AddAddressToCliente(data).subscribe(
      res=>{
        this.commonService.showSuccessMessage(res.msg)
        this.idAddressSelected = 0
        this.stateForm.reset({
          text: ''  
        })
        this.getClienteAddresses()
      },err=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnDeleteAddressToCliente(id: number){
    const data = {
      idAddress: id,
      idCliente: this.config.data.id
    }
    const body = `
    <h2>¿Seguro que quiere eliminar la dirección del cliente?</h2>
    <br>
    <p>Podrá volver a asignar la dirección al cliente en cualquier otro momento</p>
    `
    const confirmationData: ConfirmationAlert = {
            title: 'Eliminar dirección del cliente',
            body: body,
            cancelLabel: "Cerrar",
            confirmLabel: 'Eliminar',
            confirmDanger: true,
            onConfirm: () => {
              this.DeleteAddressToCliente(data)
            }
          }
    this.alertService.setConfirmationAlertv2(this.alert, confirmationData)
  }

  DeleteAddressToCliente(data: any){
    this.SalesAddressService.DeleteAddressToCliente(data).subscribe(
      res=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showSuccessMessage(res.msg)
        this.getClienteAddresses()
      },err=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showErrorMessage(err)
        this.getClienteAddresses()
      }
    )
  }

  private stripHtml(html: string): string {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe()
  }
  
}
