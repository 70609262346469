import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/common.service';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor(
    private http: HttpClient,
    public commonService: CommonService,
    private authService: AuthService
  ) {}

    getPrices(id: number, store: string): Observable<any> {
      return this.http.get<any>(`${environment.endpoint}prices/getPrices/${id}&${store}`).pipe(
        catchError(this.commonService.handleRequestError)
      );
    }

    getItems(provider: string): Observable<Blob> {
      return this.http.get(`${environment.endpoint}prices/getItems/${provider}`, {
        responseType: 'blob' 
      });
    }    

    updatePrices(data: any, store: string): Observable<any> {
      return this.http.post<any>(`${environment.endpoint}prices/updatePrices`, data).pipe(
        catchError(this.commonService.handleRequestError)
      );
    }

    getPrices_history():Observable<any>{
      return this.http.get<any>(`${environment.endpoint}prices/getHistory`).pipe(
        catchError(this.commonService.handleRequestError)
      )
    }
  }
  
