import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { assistant, Cliente, followings, Intereces_Types, Referenced, Salles_Types } from './sales.interfaces';
import { io } from 'socket.io-client';
import { ReportHistory } from 'src/app/shared/common.enums';
import { AuthService } from 'src/app/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class SalesService {

  user_id = this.authService.getUserFromLocalStorage()?.id

  public report$: Subject<ReportHistory[]> = new Subject();
  socket = io(environment.socketio,{
    auth:{
      id: this.user_id 
    }
  });
  clientes : Cliente[] = [];
  referenciados: Referenced[] = []
  Tipos_de_interes: Intereces_Types[] = [];
  Tipos_de_ventas: Salles_Types[] = [];
  Seguimientos: followings[] = [];
  asistentes: assistant[] = [];

  constructor(
    private http: HttpClient,
    public commonService: CommonService,
    public authService: AuthService
  ) {
    this.socket.on('reportHistory', (data) => { 
      this.report$.next(data);
    });
  }


  //Reportes
  getReportesdaily(): Observable<ReportHistory[]>{
    return this.http.get<ReportHistory[]>(`${environment.endpoint}ventas/reportes/getReportesdaily`).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getReportesfilter(data: any): Observable<ReportHistory[]>{
    return this.http.post<ReportHistory[]>(`${environment.endpoint}ventas/reportes/getReportesfilter`, data).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getReportesdailySubject(): Observable<ReportHistory[]>{
    return this.report$.asObservable();
  }

//utils
public parseJson(data: any) {
  if (typeof data === 'string') {
      data = data.trim();
      if (data.startsWith('{') && !data.startsWith('[{' )) {
          data = '[' + data + ']';
      }
      try {
          //console.log('Datos antes de parsear:', data); 
          return JSON.parse(data);
      } catch (error) {
          console.error('Error al parsear JSON:', error);
          return null; 
      }
  }
  return data; 
}


  //Clientes
  getClientes(){
    return this.http.get<Cliente[]>(`${environment.endpoint}ventas/clientes/get`).pipe(
      tap(clientes => {
        this.clientes = clientes;
        //console.log(this.clientes);
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  getClienteText():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/getClientesText`).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  getClienteById(id: number): Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/get/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  createClient(data:any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/clientes/add`, data).pipe(
      catchError(this.commonService.handleRequestError)
    ); 
  }

  editClient(data:any): Observable<any> {
    //console.log(data);
    return this.http.put<any>(`${environment.endpoint}ventas/clientes/updateCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    ); 
  }

  deleteCliente(data:any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/clientes/delete`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addFollowingCliente(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/clientes/addFollowingCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addFollowingClientes(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/clientes/addFollowingClientes`, data)
  }

  getHistorialByCliente(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/getHistorialByCliente/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Clientes/Seguimientos

  updateFollowUP(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/clientes/updateFollowUP`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  endFollowUP(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/clientes/endFollowUP`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getPasobyFollowing():Observable<any>{  
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/getPasobyFollowing`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  /* sales config */
  // Seguimientos
  getSeguimientos(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getSeguimientos`).pipe(
     tap(seguimientos => {
        this.Seguimientos = seguimientos;
        //console.log(this.Seguimientos,"service");
     }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getFrecuenciaBySeguimiento(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getFrecuenciaBySeguimiento/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarFrecuencia(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateFrecuencia`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getSeguimiento(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getSeguimiento/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  AgregarSeguimiento(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addSeguimiento`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarSeguimiento(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateSeguimiento`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarSeguimiento(IdSeguimiento: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteSeguimiento/${IdSeguimiento}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarSeguimiento(id:number):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/config/activeSeguimiento`, {id}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Template/seguimiento/paso

  saveTemplate(data: any): Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/config/seguimiento/paso/template`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Intereses
  getTiposDeInteres(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getTiposDeInteres`).pipe(
      tap(tipos_de_interes => {
        this.Tipos_de_interes = tipos_de_interes;
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getTipoDeInteres(id: number): Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/config/getTipoDeInteres/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  AgregarInteres(data: any ): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addInteres`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarInteres(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateInteres`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarInteres(IdInteres: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteInteres/${IdInteres}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarInteres(IdInteres: number): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/activateInteres/${IdInteres}`, {IdInteres}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Ventas
  getTiposDeVentas(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getTiposDeVentas`).pipe(
      tap(tipos_venta => {
        this.Tipos_de_ventas = tipos_venta;
        //console.log(this.Tipos_de_ventas,"service");
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getTipoDeVenta(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getTipoDeVenta/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  AgregarVentas(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addVenta`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarVenta(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateVenta`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarVenta(IdVenta: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteVenta/${IdVenta}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarVenta(IdVenta: number): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/activateVenta/${IdVenta}`, {IdVenta}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Referenciados
  AgregarReferenciado(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addReferenciado`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getReferenciados(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getReferenciados`).pipe(
      tap(Referenciados => {
        this.referenciados = Referenciados;
        //console.log(this.referenciados,"service");
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getReferenciado(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getReferenciado/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarReferenciado(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateReferenciado`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarReferenciado(IdReferenciado: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteReferenciado/${IdReferenciado}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarReferenciado(IdReferenciado: number): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/activateReferenciado/${IdReferenciado}`, {IdReferenciado}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Asistentes
  AgregarAsistente(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addAsistente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getAsistentes(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getAsistentes`).pipe(
      tap(asistentes => {
        this.asistentes = asistentes;
      }),
      catchError(this.commonService.handleRequestError)
    )
  }
  
  getAsistente(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getAsistente/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarAsistente(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateAsistente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarAsistente(IdAsistente: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteAsistente/${IdAsistente}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
  
  //Notas
  getNotasByCliente(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/getNotas/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  saveNote(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/clientes/addNota`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  deleteNota(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/clientes/deleteNota/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  updateNoteClient(data: any):Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/clientes/updateNota`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getHistorialNota(id: number): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/clientes/getHistorialNota/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Acciones
  AgregarAccion(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addAccion`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getAccion(){
    return this.http.get<any>(`${environment.endpoint}ventas/config/getAccion`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarAccion(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateAccion`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarAccion(IdAccion: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteAccion/${IdAccion}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarAccion(IdAccion: number): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/activateAccion/${IdAccion}`, {IdAccion}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  } 

  //Tipos de Clientes
  AgregarTipoCliente(data: any): Observable<any> {
    return this.http.post<any>(`${environment.endpoint}ventas/config/addTipoCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getTiposDeCliente(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}ventas/config/getTiposDeClientes`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editarTipoCliente(data: any): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/updateTipoCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  eliminarTipoCliente(IdTipoCliente: number): Observable<any> {
    return this.http.delete<any>(`${environment.endpoint}ventas/config/deleteTipoCliente/${IdTipoCliente}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  ActivarTipoCliente(IdTipoCliente: number): Observable<any> {
    return this.http.put<any>(`${environment.endpoint}ventas/config/activateTipoCliente/${IdTipoCliente}`, {IdTipoCliente}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Mails

  getMailsToSend(id: number, step: number, idioma: string, idCliente: number): Observable<any>{
    return this.http.get<any>(`${environment.endpoint}Mails/follow/getMailByStep/${id}/${step}/${idioma}/${idCliente}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  SendFollowingMail(data:any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}Mails/send/following`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
  
  //Mails Promociones
  AddPromotion(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}Mails/promotions/upload`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  GetPromotions():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}Mails/promotions/get`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  StatusPromotion(data:any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}Mails/promotions/status`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  DeletePromotion(id:number):Observable<any>{
    return this.http.delete<any>(`${environment.endpoint}Mails/promotions/delete/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

/*   getMails(): Observable<any> {
    return this.http.get<any>(`${environment.endpoint}auth/google`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  } 
  redirectToGoogle() {
    window.location.href = `${environment.endpoint}auth/google`;
  }

  getAccessTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('access_token');
  } */

  getMailById(id: number):Observable<any>{
    return this.http.get<any>(`${environment.endpoint}Mails/get/getMailById/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
}
