import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { AgendaConfirmarFechaComponent } from './agenda-confirmar-fecha/agenda-confirmar-fecha.component';
import { takeUntil } from 'rxjs/operators';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { RtmService } from '../../rtm.service';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { Agenda, AgendaStatus, AgendaType } from '../../rtm.enums';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MOMENT_FORMAT_DATE_READABLE } from 'src/app/shared/common.enums';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'agenda-actions',
  templateUrl: './agenda-actions.component.html',
  styleUrls: ['./agenda-actions.component.scss'],
  providers: [
    DialogService
  ]
})
export class AgendaActionsComponent implements OnInit, OnDestroy {

  @Input() alert?: AlertV2Component;
  @Input() agenda!: Agenda;
  @Input() dispatched = false;
  @Output() onDateConfirmed = new EventEmitter<Agenda>();
  @Output() onDone = new EventEmitter<Agenda>();
  @Output() onDeleted = new EventEmitter<Agenda>();
  @Output() onError = new EventEmitter<string>();

  private destroy$ = new Subject();
  agendaTypes = AgendaType;
  agendaStatuses = AgendaStatus;
  templateAPIUrl = "";

  hasAccess = {
    delete: false
  };

  constructor(
    private rtmService: RtmService,
    private dialogService: DialogService,
    public alertService: AlertService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this._setAccess();
    
    if (this.agenda.template_record) {
      this.setTemplateAPIUrl();
    }
  }

  onMarkAsDone() {
    if (this.alert) {
      const body = `¿Estás seguro de marcar la agenda como completada?`;
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: body,
        confirmLabel: 'Confirmar',
        confirmDanger: false,
        onConfirm: () => {
          this.markAsDone();
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  markAsDone() {
    this.rtmService.markAsDone(this.agenda!.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agenda => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.onDone.emit(agenda);
        this.updateActionsViews(agenda);
      },
      error: errorMessage => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.onError.emit(errorMessage);
      }
    })
  }

  onConfirmDate() {
    const ref = this.dialogService.open(AgendaConfirmarFechaComponent, {
      data: {
        agenda: this.agenda
      },
      header: `Confirmar fecha`,
      width: '500px'
    });
    ref.onClose.pipe(
      takeUntil(this.destroy$)
    ).subscribe((agenda: Agenda) => {
      if (agenda) {
        this.onDateConfirmed.emit(agenda);
        this.updateActionsViews(agenda);
      }
    });
  }

  updateActionsViews(agenda: Agenda) {
    if (agenda.template_record) {
      this.agenda.template_record = agenda.template_record;
      this.setTemplateAPIUrl();
    }
  }

  setTemplateAPIUrl() {
    this.templateAPIUrl = `${environment.phpEndpoint}/reports/template/?tid=${this.agenda.template_record?.id}&aid=${this.agenda.id}`;
  }

  onDelete() {
    if (this.alert) {
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: `¿Está seguro de eliminar la agenda con invoice(s) ${this.agenda._doc_numbers_str} 
          para el día ${moment(this.agenda.expected_date).format(MOMENT_FORMAT_DATE_READABLE)}?`,
        confirmLabel: 'Eliminar',
        confirmDanger: true,
        onConfirm: () => {
          this.deleteAgenda();
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  deleteAgenda() {
    this.rtmService.deleteAgenda(this.agenda.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agenda => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.onDeleted.emit(agenda);
      },
      error: errorMessage => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.onError.emit(errorMessage);
      }
    })
  }

  private _setAccess() {
    const access = this.authService.user.value?.access || [];
    this.hasAccess.delete = access.includes("agenda:agendas:delete");
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
