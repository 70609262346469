import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

    constructor(private authService: AuthService,private router: Router){}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        
        return this.authService.isAuthenticated()
            .then(
                (authenticated) => {
                    //console.log("canActivate", authenticated);
                    if ( !authenticated ){
                        return true;
                    } else {
                      //console.log("redirecting to home");
                      this.router.navigate(['/']);
                    }
                    return false;
                }
            );

    }
}
