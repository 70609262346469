import { Item, ItemShort, SaleType, Store } from "src/app/shared/common.enums";

export interface Invoice {
  id: number;
  id_sb: string;
  id_customer: string;
  invoice: number;
  document_number: number;
  store: Store;
  name?: string;
  total: string;
  tax: string;
  tax2: string;
  paid: string;
  status: number;
  shipped: number;
  shipping_address: string;
  shipping_date: string;
  customer: string;
  customer_name: string;
  attention: string;
  attentions_total?: number;
  discount?: string;
  user: string;
  created: string;
  stype?: string;
  samount?: string;
  sale_type?: SaleType;
  _items?: Item[];
  _is_pe?: boolean;
  _agendas?: number;
}

export interface Template {
  id: number;
  name: string;
  fields?: RtmTemplateField[]
}

export interface RtmTemplateField {
  id?: number;
  template_id?: number;
  name: string;
  isDynamic: boolean;
  dynamicType?: RtmTemplateDynamicField;
  isShared: boolean;
  isRequired: boolean;
  showOnlyValue: boolean;
  position: number;
  type?: RtmTemplateFieldType;
  defaultValue?: string;
  data?: string;
  data_o?: {[key:string]: any};
  creation?: string;
  _error?: boolean;
  isSignature: () => boolean;
  isDynamicEditable: () => boolean;
}

export class TemplateField implements RtmTemplateField {
  id?: number;
  template_id?: number;
  name: string;
  isDynamic: boolean;
  dynamicType?: RtmTemplateDynamicField;
  isShared: boolean;
  isRequired: boolean;
  showOnlyValue: boolean;
  position: number;
  type?: RtmTemplateFieldType;
  defaultValue?: string;
  data?: string;
  data_o?: {[key:string]: any};
  creation?: string;
  _error?: boolean;
  
  constructor(field: RtmTemplateField) {
    this.name = field.name;
    this.isDynamic = field.isDynamic;
    this.isShared = field.isShared;
    this.isRequired = field.isRequired;
    this.showOnlyValue = field.showOnlyValue;
    this.position = field.position;
    Object.assign(this, field);
  }

  isSignature(): boolean {
    return this.dynamicType === RtmTemplateDynamicField.SIGNATURE
      || this.dynamicType === RtmTemplateDynamicField.SIGNATURE_DOUBLE;
  }

  isDynamicEditable(): boolean {
    return this.dynamicType === RtmTemplateDynamicField.SIGNATURE
    || this.dynamicType === RtmTemplateDynamicField.SIGNATURE_DOUBLE
    || this.dynamicType === RtmTemplateDynamicField.SHIPPING_ADDRESS;
  }
}

export interface RtmTemplateRecord {
  id: number;
  agenda_id: number;
  template_id: number;
  creation: string;
}

export interface Agenda {
  id: number;
  creation: string;
  expected_date?: string;
  expected_time?: string;
  invoices?: Invoice[];
  dpto_id: number;
  template_id: number;
  items: AgendaItem[];
  status: AgendaStatus;
  type: AgendaType;
  subtype?: AgendaSubType;
  notes_request?: string;
  notes_response?: string;
  template_record?: RtmTemplateRecord;
  _invoice?: number;
  _invoices_no?: number;
  _document_number?: string;
  _invoices?: {[key: string]: any}[];
  _doc_numbers_str?: string;
  _datetime?: string;
}

export interface AgendaItem {
  id: number;
  agenda_id: number;
  id_item_sale_sb: string;
  inventory_item_id: string;
  name: string;
  quantity: number;
  _url: string;
}

export interface EntregaData {
  store: string;
  invoice_number: number;
  invoice_id: number;
  targetProducts?: Item[];
  sourceProducts?: Item[];
  items: ItemShort[];
  customer?: string;
}

export interface EntregasPayload {
  global_invoice_sb_id?: string;
  expected_date: string;
  expected_time: string;
  template: number;
  fields: TemplateField[];
  invoices: EntregaData[];
}

export enum AgendaStatus {
  "En Progreso" = 'en_progreso', //Entrega, Tapiceria
  "Fecha por confirmar" = 'fecha_por_confirmar', //Entrega, Otra
  "Fecha programada" = 'fecha_agendada', //Otra, Entrega
  "Por confirmar pago" = 'confirmacion_pago',//PE
  "Compra en progreso" = 'compra_en_progreso',//PE
  "Completado" = 'completado'//PE, PE-COMPRA, Tapiceria, Otra, Entrega
}

export enum AgendaType {
  "Entrega" = 'entrega',
  "Pedido Especial" = 'pedido_especial',
  "Tapicería" = 'tapiceria',
  "Otro" = 'otro'
}

export enum AgendaSubType {
  "Instalación" = 'instalacion',
  "Reparación" = 'reparacion',
  "Inspección" = 'inspeccion',
  "Toma de Medidas" = 'toma_medidas'
}

export enum AgendaCompraStatus {
  "En progreso" = "en_progerso",
  "Completado" = "completado"
}

export enum AgendaCompraItemStatus {
  "Pendiente de compra" = "pendiente",
  "Comprado" = "comprado",
  "Importado" = "importado",
  "En almacén US" = 'almacen_us',
  "En almacén MX" = 'almacen_mx'
}

export enum RtmTemplateFieldType {
  TEXT = "text",
  RICH_TEXT = "rich_text",
  DATE = "date"
}

export enum RtmTemplateDynamicField {
  INVOICE = "INVOICE",
  INVOICE_INFO_BOX = "INVOICE_INFO_BOX",
  CUSTOMER_NAME = "CUSTOMER_NAME",
  PURCHASE_DATE = "PURCHASE_DATE",
  //TOTAL_AMOUNT = "TOTAL_AMOUNT",
  PRODUCTS_LIST = "PRODUCTS_LIST",
  SHIPPING_ADDRESS = "SHIPPING_ADDRESS",
  //SERVICES_LIST = "SERVICES_LIST",
  SIGNATURE = "SIGNATURE",
  SIGNATURE_DOUBLE = "SIGNATURE_DOUBLE",
}

export const RtmTemplateDynamicFieldLabels = {
  INVOICE: "Invoice #",
  INVOICE_INFO_BOX: "Cuadro de Información",
  CUSTOMER_NAME: "Nombre del Cliente",
  PURCHASE_DATE: "Fecha de compra",
  //TOTAL_AMOUNT: "Total de la compra",
  PRODUCTS_LIST: "Lista de productos",
  SHIPPING_ADDRESS: "Dirección de entrega",
  //SERVICES_LIST: "Lista de servicios",
  SIGNATURE: "Campo de firma",
  SIGNATURE_DOUBLE: "Campos para firma doble"
}

export const RtmTemplateFieldTypeLabel = {
  "text": "Texto simple",
  "rich_text": "Texto con formato",
  "date": "Fecha"
}