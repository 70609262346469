import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';
import { SalesService } from '../../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { promos } from '../../../sales.interfaces';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-sales-config-mails-promociones',
  templateUrl: './sales-config-mails-promociones.component.html',
  styleUrls: ['./sales-config-mails-promociones.component.css']
})

export class SalesConfigMailsPromocionesComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  @ViewChild('cortinasInput', { static: false }) cortinasInput!: ElementRef
  @ViewChild('MesInput', { static: false }) MesInput!: ElementRef
  @ViewChild('CortinaIMG', { static: false }) CortinaIMG!: any
  @ViewChild('MesIMG', { static: false }) MesIMG!: any

  @ViewChild('alert') alert!: AlertV2Component;
  

  uploadedFilesCortina: any[] = []
  uploadedFilesMes: any[] = []
  promosCortinas: promos[] = []
  promosMes: promos[] = []

  constructor(
    public fb: FormBuilder,
    public commonservice: CommonService,
    public salesService: SalesService,
    public alertService: AlertService
  ) { }

  ngOnInit(): void { 
    this.getPromotions()
  }

  getPromotions(){
    this.salesService.GetPromotions().pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.cleanData()
        this.promosCortinas = res.cortinas
        this.promosMes = res.mes
      }, err =>{
        this.commonservice.showErrorMessage(err)
      }
    )
  }

  onUploadCortina(event: any) {
    const maxSize = 10000000; // 10 MB
    const file = event.files[0]

    if (!file.type.startsWith('image/')) {
      this.commonservice.showInfoMessage('El archivo no es una imagen:', file.name)
      return
    }

    if (file.size > maxSize) {
      this.commonservice.showInfoMessage('El archivo excede el tamaño máximo:', file.name)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const base64String = reader.result as string
      const data = {
        Titulo: this.cortinasInput.nativeElement.value,
        IMG: base64String,
        fileType: file.type 
      }
      this.uploadFile(data, 'cortinas')
    }
    reader.readAsDataURL(file)
  }

  onUploadMes(event: any) {
    const maxSize = 10000000; // 10 MB
    const file = event.files[0]

    if (!file.type.startsWith('image/')) {
      this.commonservice.showInfoMessage('El archivo no es una imagen:', file.name)
      return
    }

    if (file.size > maxSize) {
      this.commonservice.showInfoMessage('El archivo excede el tamaño máximo:', file.name)
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      const base64String = reader.result as string
      const data = {
        Titulo: this.MesInput.nativeElement.value,
        IMG: base64String,
        fileType: file.type 
      }
      this.uploadFile(data, 'mes')
    }
    reader.readAsDataURL(file)
  }

  uploadFile(data: any, type: string){
    
    if(data.Titulo === ''){
      this.commonservice.showInfoMessage(`El título de ${type} no puede estar vacío.`)
      return
    }

    data.tipo = type
    
    this.salesService.AddPromotion(data).pipe(takeUntil(this.destroy$)).subscribe(
      res=>{    
        this.getPromotions()
        this.commonservice.showSuccessMessage(res.msg)
      }, err =>{
        this.commonservice.showErrorMessage(err)
      }
    )

    if(type === 'mes'){
      this.cleanMes()
    }else if(type === 'cortinas'){
      this.cleanCortinas()
    }
  }

  cleanCortinas() {
    this.cortinasInput.nativeElement.value = null
    this.uploadedFilesCortina = []
    this.CortinaIMG.clear()
  }

  cleanMes() {
    this.uploadedFilesMes = []
    this.MesInput.nativeElement.value = null
    this.MesIMG.clear()
  }

  OnDelete(id: number, status: string){

    if(status === 'Activo'){
      this.commonservice.showWarningMessage('No se puede eliminar la promocion activa')
      return
    }

    const body = `
        <h2>¿Seguro que quiere eliminar promoción?</h2>
        `
        const confirmationData: ConfirmationAlert = {
                title: 'Eliminar promoción',
                body: body,
                cancelLabel: "Cerrar",
                confirmLabel: 'Eliminar',
                confirmDanger: true,
                onConfirm: () => {
                  this.delete(id)
                }
              }
        this.alertService.setConfirmationAlertv2(this.alert, confirmationData)
  }

  delete(id: number){
    this.salesService.DeletePromotion(id).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.getPromotions()
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonservice.showInfoMessage(res.msg)
      }, err =>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonservice.showErrorMessage(err)
      }
    )
  }

  onToggleChange(event:any, promo: promos){
    
    const data = {
      id: promo.id,
      status: promo.status,
      type: promo.tipo
    }

    if(promo.status === 'Inactivo'){
      data.status = 'Activo'
    }else if(promo.status === 'Activo'){
      data.status = 'Inactivo'
    }
    
    this.salesService.StatusPromotion(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.getPromotions()
        this.commonservice.showSuccessMessage(res.msg)

      }, err=>{
        this.commonservice.showErrorMessage(err)
      }
    )
  }

  cleanData(){
    this.promosCortinas = []
    this.promosMes = []
  }

  ngOnDestroy():void{
    this.destroy$.next();
    this.destroy$.complete();
  }
}
