import { Injectable } from '@angular/core';
import * as Papa from 'papaparse'

@Injectable({
  providedIn: 'root'
})
export class TagsCSVService {

  constructor() { }

  public parseCsv(file: File): Promise<any[]>{
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header:true,
        dynamicTyping: true,
        complete: (result) => {
          resolve(result.data)
        },
        error: (error) => {
          reject(error);
        }

      })
    })
  }
}
