import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Accesses, Dpto, User } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UsersService {

  editingUser: User | null = null;
  users: User[] = [];
  redirectedToList = false;
  updateUsersList = false;
  statusFilter = "active";
  accesses?: Accesses;
  dptos$ = new BehaviorSubject<Dpto[]>([]);

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getUserStatusLabel(status: string): string {
    const labels: {[key: string]: string} = {active: 'Activo', inactive: 'Inactivo'};
    if (labels[status]) {
      return labels[status];
    }
    return "";
  }

  getUsers(status: string = "") : Observable<User[]>{
    return this.http.get<User[]>(
      `${environment.endpoint}users/?status=${status}`,
      // {
      //     headers: new HttpHeaders({
      //         // 'Access-Control-Allow-Origin' : 'hds-dev.inefra.com',
      //         'Content-Type' : 'application/json',
      //         'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      //         'Pragma': 'no-cache',
      //         'Expires': '0'
      //     })
      // }
    ).pipe(
      tap(users => {
        this.users = users;
      }),
      catchError(this.commonService.handleRequestError)
      // tap( resData => {
      //     //const expirationDate = new Date( new Date().getTime() + +resData.expiresIn * 1000);
      //     const expirationDate = new Date( resData.expiresIn );
      //     const user = new User( resData.email, resData.name, resData.id, resData.token, expirationDate );
      //     this.user.next(user);
      //     localStorage.setItem( "userData", JSON.stringify(user));
      // })
    );
  }

  getAccesses() : Observable<Accesses | null>{
    if (this.accesses && this.accesses?.users.length > 0) {
      return of(null);
    }
    return this.http.get<Accesses>(
      `${environment.endpoint}users/accesses/`,
    ).pipe(
      tap(accesses => {
        this.accesses = accesses;
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  addUser(data: any): Observable<any> {
    return this.http.post(`${environment.endpoint}users/`, {data: data})
      .pipe(
        catchError(this.commonService.handleRequestError)
      );
  }

  updateUser(data: any): Observable<any> {
    return this.http.put(`${environment.endpoint}users/`, {user_id: this.editingUser?.id, data: data})
      .pipe(
        catchError(this.commonService.handleRequestError)
      );
  }

  updateUserStatus(status: string): Observable<any> {
    return this.http.put<{[key: string]: any}>(
      `${environment.endpoint}users/status/`, 
      {user_id: this.editingUser?.id, status: status}
    ).pipe(
      tap(response => {
        if (response.user) {
          this.editingUser = response.user;
        }
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  getDptos(include_users = false): Observable<Dpto[]> {
    const query = include_users ? "?users=true" : "";
    return this.http.get<Dpto[]>(
      `${environment.endpoint}dptos/${query}`,
    ).pipe(
      tap(dptos => {
        this.dptos$.next(dptos);
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  addDpto(dpto: Partial<Dpto>): Observable<Dpto> {
    return this.http.post<Dpto>(
      `${environment.endpoint}dptos/`, dpto
    ).pipe(
      tap(dpto => {
        let dptos = this.dptos$.getValue();
        dptos.push(dpto);
        dptos = this.commonService.sortByProperty(dptos, "name");
        this.dptos$.next(dptos);
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  updateDpto(dpto_id: number, dpto: Partial<Dpto>): Observable<Dpto> {
    return this.http.patch<Dpto>(
      `${environment.endpoint}dptos/${dpto_id}`, dpto
    ).pipe(
      tap(dpto => {
        let dptos = this.dptos$.getValue();
        const dptoIndx = dptos.findIndex(d => d.id === dpto.id);
        if (dptoIndx !== -1) {
          dptos[dptoIndx] = dpto;
          dptos = this.commonService.sortByProperty(dptos, "name");
          this.dptos$.next(dptos);
        }
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  deleteDpto(dpto_id: number): Observable<Dpto> {
    return this.http.delete<Dpto>(
      `${environment.endpoint}dptos/${dpto_id}`
    ).pipe(
      tap(dpto => {
        let dptos = this.dptos$.getValue();
        dptos = dptos.filter(d => d.id !== dpto.id);
        this.dptos$.next(dptos);
      }),
      catchError(this.commonService.handleRequestError)
    );
  }
}