import { BehaviorSubject, Observable } from 'rxjs';
import { Address, addressText, TipeAddressText } from './../sales-search/address.enum';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalesDireccionesService {
  private tipeAddressText$ = new BehaviorSubject<TipeAddressText[]>([]);

  constructor() { }

  buildAddressText(address: Address): addressText {
    let text = ""
    switch(address.tipo){
      case 'desarrollo':
        text += `Desarrollo: ` + address.desarrollo?.nombre
        text += ` / Edificio: ` + address.edificio?.nombre_edificio
        text += ` / Condominio: ` + address.condominio?.nombre_condominio
        text += ` / Habitaciones: ` + address.condominio?.numero_de_habitaciones
      break
      case 'casa_desarrollo':
        text += `Desarrollo: ` + address.desarrollo?.nombre
        text += ` / Casa: ` + address.casa
      break
      case 'local':
        text += `Colonia: ` + address.colonia?.nombre
        text += ` / Calle: ` + address.calle?.nombre
        text += ` / Casa: ` + address.casa?.nombre
        text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
        text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre
      break
      case 'personalizada':
        text += `Dirección: ` + address.address.direccion
      break
      case 'residencial':
        text += `Residencia: ` + address.recidencial
      
        if (address.aggregates && address.aggregates.length > 0) {
          const aggregatesText = address.aggregates.map(aggregate => [`${aggregate.Tipo}`+' : '+aggregate.contenido]).join(' / ')
          text += `/`+ aggregatesText;
        }

        text = text.trim()
      break
      case 'foranea':
        text += `Ciudad: ` + address.ciudad.nombre
        text += ` / Colonia: ` + address.colonia?.nombre
        text += ` / Calle: ` + address.calle?.nombre
        text += ` / Casa: ` + address.casa?.nombre
        text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
        text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre

      break
      case 'villa':
        text += `Desarrollo: ` + address.desarrollo?.nombre
        text += ` / Villa: ` + address.villa
      break
      }
    return { id: address.id, text: text.trim() };
  }

    // Inicializa los datos de direcciones
  setAddresses(addresses: TipeAddressText[]): void {
    this.tipeAddressText$.next(addresses);
  }

  // Función reutilizable para filtrar direcciones
  filterAddresses(value: string): Observable<TipeAddressText[]> {
    const filterValue = value.toLowerCase();

    const highlightText = (text: string, searchValue: string): string => {
      const regex = new RegExp(`(${searchValue})`, 'gi');
      return text.replace(regex, `<strong>$1</strong>`);
    };

    return this.tipeAddressText$.pipe(
      map(groups =>
        groups
          .map(group => ({
            tipo: group.tipo,
            address: group.address
              .filter(item => item.text.toLowerCase().includes(filterValue))
              .map(item => ({
                ...item,
                text: highlightText(item.text, value)
              }))
          }))
          .filter(group => group.address.length > 0)
      )
    );
  }
}