import { DialogService } from 'primeng/dynamicdialog';
import { ReportHistory } from './../../../shared/common.enums';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/common.service';
import { SalesService } from '../sales.service';
import { Subject, Subscription } from 'rxjs';
import { SalesClientesViewComponent } from '../sales-clientes/sales-clientes-view/sales-clientes-view.component';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { SalesReportsViewMailComponent } from './sales-reports-view-mail/sales-reports-view-mail.component';

@Component({
  selector: 'app-sales-reports',
  templateUrl: './sales-reports.component.html',
  styleUrls: ['./sales-reports.component.css']
})
export class SalesReportsComponent implements OnInit {
  
  searchSubscription?: Subscription

  asistentes: any[] = [];
  
  deilys: ReportHistory[] = [];
  
  reports: ReportHistory[] = [];
  
  faReportes = faChartBar;
  
  loading = false;
  
  estado = 'Default';
  
  deilysSubscription?: Subscription;
  
  private destroy$ = new Subject();
  
  ReportForm = new FormGroup({
    Startdate: new FormControl(moment().startOf('month')), // Fecha de inicio predeterminada
    Enddate: new FormControl(moment()),                   // Fecha de fin predeterminada
    ejecutivo: new FormControl(''),
  });

  pasosContados: { paso: string, count: number }[] = [];
  seguimientosContados: { seguimiento: string, count: number }[] = [];
  seguimientosPorEjecutivo: { usuario: string, seguimientos: { seguimiento: string, count: number }[] }[] = [];
  AccionesPorEjecutivo: { usuario: string, acciones: { accion: string, count: number }[] }[] = [];

  constructor(
    public commonService: CommonService,
    public salesService: SalesService,
    public dialogService: DialogService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getAsistentes();
    this.getDealy();
    this.deilysSubscription = this.salesService.getReportesdailySubject().subscribe((deilys: ReportHistory[]) => {
      if (deilys) {
        this.deilys = deilys;
      }
    });
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

  getAsistentes() {
    this.salesService.getAsistentes().pipe(takeUntil(this.destroy$)).subscribe(
      (asistentes) => {
        this.asistentes = asistentes;
        //console.log('Asistentes:', this.asistentes);
      }
    );
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  formatearFechaHoy(fecha: string): string {
    return moment(fecha).locale('es').format('[Hoy a las] HH:mm');
  }

  formatearFecha(fecha: string): string {
    return moment(fecha).locale('es').format('dddd, D [de] MMMM [a las] h:mm A');
  }

  contarElementos<T>(reports: T[], propiedad: keyof T): { valor: string, count: number }[] {
    const contador = reports.reduce((acc, report) => {
      const key = report[propiedad] as unknown as string; 
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {} as { [key: string]: number });
  
    return Object.keys(contador).map(valor => ({
      valor,
      count: contador[valor]
    }));
  }

  getDealy() {
    this.salesService.getReportesdaily().subscribe(
      (data) => {
        this.deilys = data;
        //console.log(data);
      },
      (error) => {
        this.commonService.showErrorMessage(error);
        //console.log(error);
      }
    );
  }

  showClient(deily: any) {
    this.dialogService.open(SalesClientesViewComponent, {
      width: '70%',
      data: {id: deily.id_cliente, button: true}
    });
  }

  showMail(id: any){
    this.salesService.getMailById(id).pipe(takeUntil(this.destroy$)).subscribe(
      (Mail) =>{
        this.openMailView(Mail)
      },
      (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  openMailView(Mail: any){
    const ref = this.dialogService.open(SalesReportsViewMailComponent,{
      header: `Correo Enviado`,
      width: '70%',
      data: Mail.mail
    })
  }

  onSearchReport() {
    const formData = this.ReportForm.value;
    const formattedData = {
      Startdate: this.datePipe.transform(formData.Startdate, 'yyyy-MM-dd'),
      Enddate: this.datePipe.transform(formData.Enddate, 'yyyy-MM-dd'),
      ejecutivo: formData.ejecutivo
    };
    this.loading = true;
    this.salesService.getReportesfilter(formattedData).subscribe(
      (data) => {
        this.reports = data;
        this.loading = false;
        this.estado = 'consulta';
        //console.log(this.reports);
        
        this.pasosContados = this.contarElementos(this.reports, 'paso').map(item => ({ paso: item.valor, count: item.count }));
        this.seguimientosContados = this.contarElementos(this.reports, 'seguimiento_tipo' ).map(item => ({ seguimiento: item.valor, count: item.count }));
        this.seguimientosPorEjecutivo = this.contarElementos(this.reports, 'usuario').map(ejecutivo => ({
          usuario: ejecutivo.valor,
          seguimientos: this.contarElementos(this.reports.filter(report => report.usuario === ejecutivo.valor), 'seguimiento_tipo').map(item => ({
            seguimiento: item.valor,
            count: item.count
          }))
        }));
        this.AccionesPorEjecutivo = this.contarElementos(this.reports, 'usuario').map(ejecutivo => ({
          usuario: ejecutivo.valor,
          acciones: this.contarElementos(this.reports.filter(report => report.usuario === ejecutivo.valor), 'paso').map(item => ({
            accion: item.valor,
            count: item.count
          }))
        }));
        
      },
      (error) => {
        this.commonService.showErrorMessage(error);
        this.loading = false;
      }
    );
  }
}
