import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faArrowRight, faEdit, faPlusCircle, faSave, faSpinner, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Accesses, Access, Modules, ModuleTranslation, Dpto } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { MyErrorStateMatcher, passwordValidator } from 'src/app/shared/shared.module';
import { UsersService } from '../users.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css'],
  providers: [MessageService]
})
export class UsersAddComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    dpto: new FormControl(null, [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    pwd: new FormControl('', [Validators.required, passwordValidator]),
    pwd_confirm: new FormControl('', [Validators.required, passwordValidator])
  }, {validators: [this.passwordMatcher]});

  @ViewChild('userForme') userForme!: NgForm;

  user: {[key: string]: string} = {
    name: '',
    email: '',
    pwd: '',
    pwd_confirm: ''
  };
  title: string = "Agregar Usuario";
  matcher = new MyErrorStateMatcher();
  plusIcon = faUserPlus;
  editIcon = faUserEdit;
  saveIcon = faSave;
  loadingIcon = faSpinner;
  rightArrowIcon = faArrowRight;
  loading = true;
  editingMode = false;
  modules = Modules;
  featureAccess = {
    update_status: false
  }
  dptos: Dpto[] = [];

  constructor(
    public usersService: UsersService, 
    public commonService: CommonService, 
    private messageService: MessageService,  
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setFeatureAccess();
    this.initDependencies();
    // Development
    // this.name.setValue("asdf");
    // this.email.setValue("dev@inefra.com");
    // this.pwd.setValue("asdfasdf");
    // this.pwd_confirm.setValue("asdfasdf");
  }

  initDependencies() {
    this.usersService.getDptos().pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: dptos => {
        this.dptos = dptos;
        this.loading = false;
        this.initMode();
      },
      error: errorMessage => {
        this.commonService.showErrorMessage(errorMessage, this.messageService);
        this.loading = false;
      }
    });
  }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.update_status = access.includes("users:update-status");
  }

  initMode() {
    this.editingMode = !!this.usersService.editingUser;
    if (this.editingMode) {
      this.title = "Modificar Usuario";
      this.userForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        dpto: new FormControl(null, [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        pwd: new FormControl('', [passwordValidator]),
        pwd_confirm: new FormControl('', [passwordValidator])
      }, {validators: [this.passwordMatcher]});
      this.name.setValue(this.usersService.editingUser?.name);
      this.email.setValue(this.usersService.editingUser?.email);
      this.dpto.setValue(this.usersService.editingUser?.id_dpto);
      //Load user accesses
      this.setAccesses();
    } else {
      if (this.router.url.indexOf("modificar") !== -1){
        this.usersService.redirectedToList = true;
        this.router.navigate(["/usuarios/lista"]);
      } else {
        this.resetAccesses();
      }
    }
  }

  get name() {
    return this.userForm?.get('name') as FormControl;
  }

  get dpto() {
    return this.userForm?.get('dpto') as FormControl;
  }

  get email() {
    return this.userForm?.get('email') as FormControl;
  }

  get pwd() {
    return this.userForm?.get('pwd') as FormControl;
  }

  get pwd_confirm() {
    return this.userForm?.get('pwd_confirm') as FormControl;
  }

  getTranslation(module: string) {
    return ModuleTranslation[module];
  }

  setAccesses() {
    const userAccess = this.usersService.editingUser?.access || [];
    for (let module of this.modules) {
      if (this.usersService.accesses) {
        for (let access of this.usersService.accesses?.[module]) {
          access.active = userAccess.includes(access.type);
        }
      }
    }
  }

  passwordMatcher(control: AbstractControl) : ValidationErrors | null {
    const pwd = control.get('pwd')?.value || "";
    const pwd_confirm = control.get('pwd_confirm')?.value || "";
    if (pwd || pwd_confirm) {
      let current_errors = control.get('pwd_confirm')?.errors || {};
      if (pwd !== pwd_confirm) {
        current_errors.noMatch = true;
        control.get('pwd_confirm')?.setErrors(current_errors);
      } else {
        if (current_errors.noMatch) {
          delete current_errors.noMatch;
        }
        if (Object.keys(current_errors).length > 0) {
          control.get('pwd_confirm')?.setErrors(current_errors);
        } else {
          control.get('pwd_confirm')?.setErrors(null);
        }
      }
    }
    return null;
  }

  onSubmit() {
    if (this.loading) {
      this.commonService.showWarningMessage('Espere un momento por favor', this.messageService, "Proceso en curso");
      return;
    } else if (!this.userForm?.valid) {
      this.commonService.showErrorMessage('Verifique la información del formulario', this.messageService);
      return;
    }
    let data: {[key: string]: string | string[]} = {
      name: this.name.value,
      email: this.email.value,
      id_dpto: this.dpto.value,
      pwd: this.pwd.value,
      pwd_confirm: this.pwd_confirm.value
    };

    data.accesses = this.parseAccesses();
    
    this.loading = true;
    if (this.editingMode) {
      this.updateUser(data);
    } else {
      this.addNewUser(data);
    }
  }

  updateUser(data: any) {
    this.usersService.updateUser(data).subscribe(response => {
      this.commonService.showSuccessMessage(response.msg, this.messageService);
      this.loading = false;
      this.usersService.updateUsersList = true;
      this.usersService.editingUser = response.user;
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService, 4500);
      this.loading = false;
    })
  }

  addNewUser(data: any) {
    this.usersService.addUser(data).subscribe(response => {
      this.resetForm();
      this.usersService.updateUsersList = true;
      this.router.navigateByUrl('/usuarios', { skipLocationChange: true }).then(() => {
        this.router.navigate(["/usuarios/agregar"]);
      }); 
      this.alertService.setSuccessAlert("Éxito", response.msg);
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService, 4500);
      this.loading = false;
    })
  }

  resetForm() {
    this.userForm?.reset();
    this.userForm?.markAsUntouched();
    this.resetAccesses();
  }

  resetAccesses() {
    for (let module of this.modules) {
      if (this.usersService.accesses) {
        for (let access of this.usersService.accesses?.[module]) {
          access.active = false;
        }
      }
    }
  }

  onActiveInactive() {
    const newStatus = this.usersService.editingUser?.status === 'active' ? 'inactive': 'active';
    const esLabel = this.usersService.editingUser?.status === 'active' ? 'inactivo': 'activo';
    const body = `¿Estás seguro de cambiar el estado del usuario ${this.usersService.editingUser?.name} a ${esLabel}?`;
    const confirmDanger = newStatus === 'inactive';
    this.alertService.setConfirmationAlert('Confirmación requerida', body, 'Confirmar', () => {
      this.alertService.syncing = true;
      this.activeInactiveUser(newStatus);
    }, confirmDanger);
  }

  activeInactiveUser(status: string) {
    this.usersService.updateUserStatus(status).subscribe(response => {
      this.alertService.closeAlert(true);
      this.commonService.showSuccessMessage(response.msg, this.messageService);
      this.usersService.updateUsersList = true;
    }, error => {
      this.alertService.closeAlert(true);
      this.commonService.showErrorMessage(error, this.messageService, 4500);
    })
  }

  toogleAllAccess(e: any, module: string) {
    if (this.usersService.accesses) {
      this.usersService.accesses[module].forEach((access: Access) => {
        access.active = e.target.checked;
      });
    }
  }

  parseAccesses() {
    let activeAccess = [];
    if (this.usersService.accesses) {
      for (let module of Modules) {
        for (let access of this.usersService.accesses?.[module]) {
          if (access.active) {
            activeAccess.push(access.type);
          }
        }
      }
    }
    return activeAccess;
  }

  ngOnDestroy(): void {
    this.usersService.editingUser = null;
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}