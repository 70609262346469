import { Component, Input, OnInit } from '@angular/core';
import { Item } from 'src/app/shared/common.enums';
import { Agenda } from '../../rtm.enums';

@Component({
  selector: 'agenda-items-details',
  templateUrl: './agenda-items-details.component.html',
  styleUrls: ['./agenda-items-details.component.scss']
})
export class AgendaItemsDetailsComponent implements OnInit {
  @Input() agenda!: Agenda;
  @Input() items: Item[] = [];

  constructor() {}

  ngOnInit(): void {
    for (const agenda_item of this.agenda.items) {
      const item = this.items.find(item => item.id_item === agenda_item.inventory_item_id);
      if (item) {
        agenda_item._url = item.url || item.url_s || "";
      }
    }
  }

  getItemsTotal(agenda: Agenda) {
    return agenda.items.reduce((acc, item) => acc + item.quantity, 0);
  }

}
