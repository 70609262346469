import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-tags-edit-product',
  templateUrl: './tags-edit-product.component.html',
  styleUrls: ['./tags-edit-product.component.css'],
  providers:[]
})

export class TagsEditProductComponent {
  product: any = {
    productName: '',
    productId: '',
    productPrice: null,
    quantityOption: '',
    quantityTags: 1,
    TagTemplate: ""
  }

  constructor(
     public ref: DynamicDialogRef,
     public config: DynamicDialogConfig,
     public commonService: CommonService
  ) {
    this.product ={ ...this.config.data.product }|| { ...this.product }; // Recibe el producto desde el diálogo
  }

  save() {
    this.commonService.showSuccessMessage("Cambios Guardados");
    this.ref.close(this.product); // Cierra el diálogo y pasa el producto editado
  }

  cancel() {
    this.ref.close(); // Cierra el diálogo sin pasar datos
  }
}
