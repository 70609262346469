import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MY_FORMATS } from 'src/app/mods/arqueo/arqueo-sales/arqueo.sales.component';
import * as moment from 'moment';

@Component({
  selector: 'hds-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class DateInputComponent implements OnInit {

  @Input() label: string = "Fecha";
  @Input() defaultValue?: string;
  @Input() hint?: string;
  @Input() showClear = false;
  @Input() defaultToday = false;
  @Input() disabled = false;

  @Output() onDateChange = new EventEmitter<string>();

  date: any;

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.date = moment(this.defaultValue);
    } else if (this.defaultToday) {
      this.date = moment();
    }
  }

  onChange() {
    //Emitter here if needed in future
    if (this.date) {
      this.onDateChange.emit(moment(this.date).format("YYYY-MM-DD"));
    }
  }

  clearDate() {
    //console.log(this.date);
    this.date = "";
    this.onDateChange.emit("");
  }

}
