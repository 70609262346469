import { Component, OnInit } from '@angular/core';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { SalesDireccionesService } from './sales-direcciones.service';

@Component({
  selector: 'app-sales-direcciones',
  templateUrl: './sales-direcciones.component.html',
  styleUrls: ['./sales-direcciones.component.css']
})
export class SalesDireccionesComponent implements OnInit {

  loading = false
  faAddreses = faMapPin;

  desarrollos = []
  foraneas = []
  locales = []
  personalizadas = []

  SubscribeDesarrollos? : Subscription;
  SubscribeForaneas? : Subscription;
  SubscribeLocales? : Subscription;
  SubscribePersonalizadas? : Subscription;

  constructor(
    public commonService: CommonService,
    public addressService: SalesDireccionesService
  ) { 
  }

  ngOnInit(): void {
  }

 /*    */

  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }
}
