import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesService } from '../../../sales.service';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';
import { addressText, TipeAddressText } from '../../../sales-search/address.enum';
import { SalesDireccionesService } from '../../../sales-direcciones/sales-direcciones.service';
import { SalesAddressService } from '../../../sales-address.service';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';

@Component({
  selector: 'app-sales-clientes-view-following',
  templateUrl: './sales-clientes-view-following.component.html',
  styleUrls: ['./sales-clientes-view-following.component.css']
})
export class SalesClientesViewFollowingComponent implements OnInit, OnDestroy {

  @ViewChild('alert') alert!: AlertV2Component;
  
  private destroy$ = new Subject();

  faSearch = faSearch
  
  seguimientos: any[] = [];
  form: FormGroup = this.fb.group({});
  groupFollow: FormGroup = this.fb.group({});
  diseableInput = true
  TypeFollow = ''
  loading = false
  addresses = []

  AddressesText: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  TipeAddressText: TipeAddressText[] = []
  AddressesTextDesarrollo: addressText[] = []
  AddressesTextCasaDesarrollo: addressText[] = []
  AddressesTextLocal: addressText[] = []
  AddressesTextPersonalizada: addressText[] = []
  AddressesTextResidencial: addressText[] = []
  AddressesTextForanea: addressText[] = []
  AddressesTextVilla: addressText[] = []
  
  addressesSearch: any[] = []

  idAddressSelected = 0

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogService: DialogService,
    public dialog: MatDialog,
    public salesServices: SalesService,
    private fb: FormBuilder,
    public commonService: CommonService,
    public AddressesServices: SalesDireccionesService,
    public SalesAddressService: SalesAddressService,
    public alertService: AlertService
  ) {
    console.log(this.config.data)
    this.form = this.fb.group({
      following: [''],
      inicio: [1, [Validators.min(1)]]
    });

    this.groupFollow = this.fb.group({
      following: [''],
      direccion: ['', [Validators.required]],
      inicio:[1, [Validators.min(1)]]    
    });
   }

  ngOnInit(): void {
    this.getAddrressesClient();
    this.getSeguimientos();
    this.AddressesServices.setAddresses(this.TipeAddressText);

    this.groupFollow.get('direccion')?.valueChanges
    .pipe(startWith('')
    , switchMap(value => 
      this.AddressesServices.filterAddresses(value)
    )).subscribe(filteredAddresses => {
      this.AddressesText.next(filteredAddresses);
    })

  }

  OnAddFollowingAlone(){
    this.loading = true
    if(this.form.value.following === ''){
      this.commonService.showWarningMessage("El seguimiento no puede estar vacio")
      return
    }
    if( this.form.value.following.duracion < this.form.value.inicio){
      this.commonService.showWarningMessage("El inicio no puede ser mayor a la duracion")
      return
    }
    

    let data = {
      idCliente: this.config.data.idCliente,
      idSeguimiento: this.form.value.following.id,
      duration: this.form.value.following.duracion,
      inicio: this.form.value.inicio,
      asistente: this.config.data.asistente,
      asist_id: this.config.data.asist_id,
      tipo: 'individual'
    }
    //console.log(this.form.value)
    //console.log('Data:', data);
    
    this.salesServices.addFollowingCliente(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg);
        this.loading = false
        this.ref.close();
      }, error=>{
        this.commonService.showErrorMessage(error);
        this.loading = false
      }
    )
  }

  OnAddFollowingGroup(){
  this.loading = true
    if(this.groupFollow.value.following === ''){
      this.commonService.showWarningMessage("El seguimiento no puede estar vacio")
      return
    }
    if( this.groupFollow.value.following.duracion < this.groupFollow.value.inicio){
      this.commonService.showWarningMessage("El inicio no puede ser mayor a la duracion")
      return
    }
    

    let data = {
      idCliente: this.config.data.idCliente,
      idSeguimiento: this.groupFollow.value.following.id,
      duration: this.groupFollow.value.following.duracion,
      inicio: this.groupFollow.value.inicio,
      asistente: this.config.data.asistente,
      asist_id: this.config.data.asist_id,
      direccion: this.idAddressSelected,
      tipo: 'grupo'
    }
    //console.log(this.groupFollow.value)
    //console.log('Data:', data);
    
    this.salesServices.addFollowingClientes(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg);
        this.loading = false
        this.ref.close();
      }, error=>{
        if (error.error.clientes) {
          let clientes = error.error.clientes;
        
          // Formatear los nombres y apellidos de los clientes
          let clientesTexto = clientes.map((cliente:any) => `${cliente.nombre} ${cliente.apellido}`).join('<br>');
        
          const confirmationData: ConfirmationAlert = {
            title: 'Algunos clientes tienen diferentes ejecutivos de ventas.',
            body: clientesTexto += '<br><Strong>¡Por favor, soluciona esto y vuelve a intentarlo!</Strong>', // Mostrar la lista de clientes correctamente
            confirmLabel: 'Confirmar',
            confirmDanger: false,
            onConfirm: () => {
              this.ref.close();
            }
          };
        
          this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
        } else if (error.error.msg) {
          this.commonService.showErrorMessage(error.error.msg);
        }
        
        this.loading = false;
        
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSeguimientos(){
    this.salesServices.getSeguimientos().pipe(takeUntil(this.destroy$)).subscribe(
      (seguimientos) => {
        // Filtrar seguimientos para mostrar solo los activos
        this.seguimientos = seguimientos.filter((follow: { status: string; }) => follow.status === 'Activo');
        //console.log(this.seguimientos)
        this.form.patchValue({
          following: this.seguimientos
        });
      } 
    );
  }

  getAddrressesClient(){
    this.SalesAddressService.getClienteAddresses(this.config.data.idCliente).subscribe(
      (addresses)=>{
        console.log(addresses)
        for(let address of addresses){
          const addressText = this.AddressesServices.buildAddressText(address)
          switch(address.tipo){
            case 'desarrollo':
              this.AddressesTextDesarrollo.push(addressText)
              break
            case 'casa_desarrollo':
              this.AddressesTextCasaDesarrollo.push(addressText)
              break
            case 'local':
              this.AddressesTextLocal.push(addressText)
              break
            case 'personalizada':
              this.AddressesTextPersonalizada.push(addressText)
              break
            case 'residencial':
              this.AddressesTextResidencial.push(addressText)
              break
            case 'foranea':
              this.AddressesTextForanea.push(addressText)
              break
            case 'villa':
              this.AddressesTextVilla.push(addressText)
              break
          }
        }
        this.TipeAddressText.push({tipo:'Desarrollos', address: this.AddressesTextDesarrollo})
        this.TipeAddressText.push({tipo:'Casas en desarrollos', address: this.AddressesTextCasaDesarrollo})
        this.TipeAddressText.push({tipo:'Locales', address: this.AddressesTextLocal})
        this.TipeAddressText.push({tipo:'Personalizadas', address: this.AddressesTextPersonalizada})
        this.TipeAddressText.push({tipo:'Residenciales', address: this.AddressesTextResidencial})
        this.TipeAddressText.push({tipo:'Foraneas', address: this.AddressesTextForanea})
        this.TipeAddressText.push({tipo:'Villas', address: this.AddressesTextVilla})
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  private stripHtml(html: string): string {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText || ''
  }
  
  onOptionSelected(event: any): void {
    // Elimina las etiquetas HTML del texto seleccionado
    const sanitizedText = this.stripHtml(event.option.value);
    this.groupFollow.get('direccion')?.setValue(sanitizedText);
  }

  OnselectedAddress(id: number){
    this.idAddressSelected = id
  }

  resetvalues(){
    this.form.reset({
      following: '',
      inicio: 1
    })

    this.groupFollow.reset({
      following: '',
      inicio: 1
    })
  }
}
