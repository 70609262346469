import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { SalesChatsService } from '../../sales-chats.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sales-chats-gmail-create',
  templateUrl: './sales-chats-gmail-create.component.html',
  styleUrls: ['./sales-chats-gmail-create.component.css']
})
export class SalesChatsGmailCreateComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  
  subject: string = ''
  text: string = ''
  mail: string = ''
  files: any[] = []
  loading = false

  constructor(
    private config: DynamicDialogConfig,
    private commonService: CommonService,
    private chatService: SalesChatsService,
    public ref: DynamicDialogRef
  ) {  
    this.mail = this.config.data.correo
  }

  ngOnInit(): void {
  }

  onFileChange(event: Event) {
    this.loading = true
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {this.files.push(
        {name: file.name, type: file.type, size: file.size, base64: reader.result}
      )}
      reader.onerror = () => {
        this.commonService.showErrorMessage("Error al leer el archivo, si el problema continua intente con otro")
        this.loading = false
      }
    }
  }

  SendMail(){

    if(this.subject === ''){
      this.commonService.showWarningMessage('El subject no puede estar vacio') 
      return
    }

    if(this.text === ''){
      this.commonService.showWarningMessage('El mensaje no puede estar vacio')
      return
    }

    const data = {
      subject: this.subject,
      mail: this.mail,
      html: this.text,
      attachments: this.files,
      id_chat: this.config.data.chat_id,
      date: this.commonService.getLocalDate()
    }

    this.chatService.sendMail(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close(true)
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
