import { msg } from './../../sales.interfaces';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesChatsService } from '../sales-chats.service';
import { CommonService } from 'src/app/shared/common.service';
import { Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sales-chats-view',
  templateUrl: './sales-chats-view.component.html',
  styleUrls: ['./sales-chats-view.component.css']
})
export class SalesChatsViewComponent implements OnInit {
  private destroy$ = new Subject();

  msg: msg
  loading = true


  constructor(
    public config: DynamicDialogConfig,
    public chatService: SalesChatsService,
    public commonService: CommonService,
    public ref: DynamicDialogRef
  ) { 
    this.loading = true
    this.msg = this.config.data.id
  }

  ngOnInit(): void {
    this.getBodyMail()
  }

  getBodyMail(){
    const id  = this.config.data.id
    this.chatService.getMsgBody(id).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.msg = res
        this.loading = false
      },
      err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnDownload_File(file: any){
    this.chatService.download_File(file)
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
