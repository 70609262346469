import { Component, OnDestroy, OnInit } from '@angular/core';
import { faEdit, faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { TemplateModalComponent } from './template-modal/template-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RtmService } from '../rtm.service';
import { Template } from '../rtm.enums';

@Component({
  selector: 'app-rtm-templates',
  templateUrl: './rtm-templates.component.html',
  styleUrls: ['./rtm-templates.component.css'],
  providers: [
    DialogService
  ]
})
export class RtmTemplatesComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  templates: Template[] = [];
  templatesIcon = faPaperPlane;
  fullSize: boolean = false;
  loadingIcon = faSpinner;
  loading = true;
  editIcon = faEdit;

  constructor(
    private rtmService: RtmService,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    this.commonService.fullSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(fullSize => {
      this.fullSize = fullSize;
    });

    this.rtmService.templates$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(templates => {
      this.templates = templates;
    });
  }

  ngOnInit(): void {
    this.getTemplates();
  }

  getTemplates() {
    this.rtmService.getTemplates().pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: templates => {
        this.loading = false;
      },
      error: errorMessage => {
        this.loading = false;
        this.commonService.showErrorMessage(errorMessage);
      }
    })
  }

  onSizeChange() {
    this.commonService.fullSize$.next(!this.fullSize);
  }

  onAddTemplate() {
    this.openTemplateForm();
  }

  onEditTemplate(template: Template) {
    this.openTemplateForm(template);
  }

  openTemplateForm(template?: Template) {
    const ref = this.dialogService.open(TemplateModalComponent, {
      data: {
        template: template
      },
      header: template ? `Actualizar plantilla` : `Crear nueva plantilla`,
      width: '80%'
    });
    ref.onClose.pipe(
      takeUntil(this.destroy$)
    ).subscribe((result: any) => {
      if (result === "created") {
        this.commonService.showSuccessMessage("Plantilla creada correctamente");
      } else if (result === "deleted") {
        this.commonService.showSuccessMessage("Plantilla eliminada correctamente");
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
