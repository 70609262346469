import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MY_FORMATS } from 'src/app/mods/arqueo/arqueo-sales/arqueo.sales.component';
import { Agenda, EntregaData, EntregasPayload, Invoice, RtmTemplateDynamicField, RtmTemplateField, Template, TemplateField } from '../../rtm.enums';
import { Item, Stores } from 'src/app/shared/common.enums';

import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { MatStepper } from '@angular/material/stepper';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { RtmService } from '../../rtm.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, of, throwError } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-agenda-entrega',
  templateUrl: './agenda-entrega.component.html',
  styleUrls: ['./agenda-entrega.component.scss'],
  providers: [
    MessageService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class AgendaEntregaComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper?: MatStepper;
  @ViewChild('alert') alert?: AlertV2Component;

  private destroy$ = new Subject();
  invoice: Invoice | null | undefined;
  items: Item[] = [];
  others: Item[] = [];
  contentReady = false;
  loadingIcon = faSpinner;
  agendas: Agenda[] = [];
  templates: Template[] = [];
  template?: Template;
  fields: TemplateField[] = [];
  loading = false;
  noInvoice = false;
  minTime?: any;
  maxTime?: any;

  basicForm: FormGroup;
  areItemsSelected = false;
  agendaCreated = false;
  templateFilled = false;
  stores = [...Stores];

  sourceProducts: Item[] = [];
  targetProducts: Item[] = [];

  payload: EntregasPayload;
  selectedInvoiceId: number | null = null;

  constructor(
    private rtmService: RtmService,
    private config: DynamicDialogConfig,
    private msgService: MessageService,
    private commonService: CommonService,
    public alertService: AlertService,
    private dialogRef: DynamicDialogRef
  ) {
    this.basicForm = new FormGroup({
      expected_date: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      }),
      expected_time: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      }),
      template: new FormControl(null, {
        updateOn: 'blur',
        validators: [Validators.required]
      })
    });

    // Set initial values to payload
    this.payload = {
      expected_date: "",
      expected_time: "",
      template: 0,
      fields: [],
      invoices: []
    };

    this.invoice = this.config.data?.invoice;
    this.noInvoice = !this.invoice;
    this.items = this.config.data?.items;
    this.others = this.config.data?.others;
    this.minTime = "08:00";
    this.maxTime = "18:00";

    if (this.noInvoice) {
      this.basicForm.addControl("store", new FormControl(null, {
        validators: [Validators.required]
      }));
      this.basicForm.addControl("invoice_number", new FormControl(null, {
        validators: [Validators.required]
      }));
    }
  }

  ngOnInit(): void {
    this.initItems();
    if (this.noInvoice) {
      //No invoices tied
      this.initDependencies();
    } else {
      //Specific invoice
      this.payload.global_invoice_sb_id = this.invoice?.id_sb;
      if (this.invoice?.shipping_date) {
        this.dialogRef.close();
        this.commonService.showErrorMessage(`El invoice seleccionado ya fue marcado como dispatched`);
      } else if (this.sourceProducts.length === 0) {
        this.dialogRef.close();
        this.commonService.showErrorMessage(`No se encontraron artículos en el invoice seleccionado`);
      } else {
        this.initDependencies();
      }
    }
  }

  initDependencies() {
    this.rtmService.getTemplates().pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: templates => {
        this.templates = templates;
        this.contentReady = true;
      },
      error: errorMessage => {
        console.log(errorMessage);
        setTimeout(() => {
          this.dialogRef.close();
          this.commonService.showErrorMessage(`Error obteniendo plantillas, por favor inténtelo nuevamente`);
        }, 0);
      }
    });
  }

  initItems() {
    const existingData = this.payload.invoices.find(i => i.invoice_id === this.invoice?.id);
    if (existingData) {
      this.sourceProducts = existingData.sourceProducts || [];
      this.targetProducts = existingData.targetProducts || [];
    } else {
      if (this.items) {
        this.sourceProducts = (this.items || []).filter(i => i.quantity).map(item => {
          item._selected = 1;
          item._available = item.quantity;
          return item;
        });
      }
    }
  }

  updateItemAvailableQuantities() {
    if (this.agendas.length > 0) {
      for (const item of this.sourceProducts) {
        const blocked = this.agendas.map(agenda => {
          return agenda.items.filter(i => i.id_item_sale_sb === item.id_sb).reduce((acc, i) => acc + i.quantity, 0);
        }).reduce((acc, quantity) => acc + quantity, 0);
        item._available = (item.quantity || 0) - blocked;
      }
      this.sourceProducts = this.sourceProducts.filter(item => (item._available || 0) > 0);
    }
  }

  fillDev() {
    //dev
    console.log(this.invoice);
    console.log(this.items);
    console.log(this.others);
    this.basicForm.get("expected_date")?.setValue(moment());
    this.basicForm.get("expected_time")?.setValue("08:00");
    this.basicForm.get("template")?.setValue(7);
  }

  onUpdateItems(event: any) {
    this.areItemsSelected = this.targetProducts.length > 0;
  }

  onStepChange(event: any) {
    //console.log("step updated", event);
    if (event.selectedIndex === 1 && this.payload.fields.length === 0) {
      this.getTemplate();
    }
  }

  onValidateBasic() {
    if (!this.basicForm.valid) {
      this.commonService.showErrorMessage("La fecha de entrega y template son requeridos en el paso #1", this.msgService);
      return;
    }

    //Update payload
    this._updatePayload("basic");

    //Validate invoice
    if (!this.noInvoice) {

      if (this.invoice) {
        this.loading = true;
        this.rtmService.getInvoiceAgenda(this.invoice.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe({
          next: agendas => {
            this.loading = false;
            this.agendas = agendas;
            this.updateItemAvailableQuantities();
            if (this.sourceProducts.length > 0) {
              this.stepper?.next();
            } else {
              this.commonService.showErrorMessage(`No se encontraron artículos pendientes de entrega en el invoice seleccionado`, this.msgService);
            }
          },
          error: errorMessage => {
            this.loading = false;
            this.commonService.showErrorMessage(errorMessage, this.msgService);
          }
        });
      }

    } else {

      const store = this.basicForm.get('store')?.value;
      const invoice_number = this.basicForm.get('invoice_number')?.value;
      if (!store || !invoice_number) {
        this.commonService.showErrorMessage("Debe proporcionar la tienda e invoice en el paso #1", this.msgService);
        return;
      }

      let invoice_items_ids: any[] = [];
      this.loading = true;
      this.rtmService.getInvoice(store, parseInt(invoice_number)).pipe(
        tap(invoice => {

          if (invoice.shipping_date) {
            throw new Error(`El invoice seleccionado ya fue marcado como dispatched`);
          }

          this.invoice = invoice;
          this.items = (invoice._items || []).filter(i => i.type === 'item');
          this.others = (invoice._items || []).filter(i => i.type !== 'item');
          this.initItems();
          if (this.sourceProducts.length === 0 && this.targetProducts.length === 0) {
            throw new Error(`No se encontraron artículos en el invoice seleccionado`);
          }
        }),
        switchMap(() => {
          invoice_items_ids = this.items.filter(item => !!item.id_item).map(item => item.id_item);
          if (invoice_items_ids.length > 0 && this.invoice) {
            return this.rtmService.getInventoryItems(invoice_items_ids, this.invoice.store);
          }
          return of(null);
        }),
        switchMap(inventory_items => {
          if (inventory_items && this.invoice) {
            this.updateLocalItems(inventory_items);
            const notFoundIds = invoice_items_ids.filter(id => !inventory_items.find(i => i.id_sb == id));
            if (notFoundIds && notFoundIds.length > 0) {
              this.commonService.showWarningMessage(`Estamos sincronizando la venta con el SB, esto puede tardar
                un par de minutos, por favor espere...`, this.msgService, "Sincronización necesaria", (notFoundIds.length * 8) * 1000);
              return this.rtmService.syncInvoicesAndItems(this.invoice.id_sb, notFoundIds, this.invoice.store);
            }
          }
          return of([]);
        }),
        switchMap(inventory_items => {
          if (inventory_items && inventory_items.length > 0) {
            this.updateLocalItems(inventory_items);
          }
          return this.rtmService.getInvoiceAgenda(this.invoice!.id);
        }),
        takeUntil(this.destroy$)
      ).subscribe({
        next: agendas => {
          this.agendas = agendas;
          this.updateItemAvailableQuantities();
          if (this.sourceProducts.length > 0) {
            this.stepper?.next();
          } else {
            this.commonService.showErrorMessage(`No se encontraron artículos pendientes de entrega en el invoice seleccionado`, this.msgService);
          }
          this.loading = false;
        },
        error: errorMessage => {
          this.loading = false;
          this.commonService.showErrorMessage(errorMessage, this.msgService);
        }
      });
    }
  }

  updateLocalItems(inventory_items: any[]) {
    for (const item of this.items) {
      const matchedItem = inventory_items.find(i => i.id_sb === item.id_item);
      if (matchedItem) {
        item.name = matchedItem.name;
        item.url_s = matchedItem.url_s;
        item.url_m = matchedItem.url_m;
        item.url = matchedItem.url;
        item.location_name = matchedItem.location_name;
        item.location_alias = matchedItem.location_alias;
      }
    }
  }

  onTemplateSelected(e: MatSelectChange) {
    this.templateFilled = false;
    if (parseInt(e.value) !== this.payload.template) {
      this.fields = [];
    } else {
      this.fields = [...this.payload.fields];
    }
  }

  getTemplate() {
    this.loading = true;
    const template_id = this.basicForm.get("template")?.value;
    this.rtmService.getTemplate(template_id).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: template => {
        this.template = template;
        this.fields = template.fields || [];
        this.loading = false;
      },
      error: errorMessage => {
        this.loading = false;
        this.commonService.showErrorMessage(errorMessage, this.msgService);
      }
    })
  }

  onValidateTemplate() {
    //Reset _error to false
    this.fields.map(f => {
      f._error = false;
      return f;
    });

    let fieldsWithError = this.fields.filter(f => !f.isDynamic && !f.defaultValue && f.isRequired).map(f => {
      f._error = true;
      return f;
    });

    //Validate signature fields
    const wrongSignatures = this.fields.filter(f => {
      return (f.dynamicType === RtmTemplateDynamicField.SIGNATURE && !f.data_o?.name)
        || (f.dynamicType === RtmTemplateDynamicField.SIGNATURE_DOUBLE && (!f.data_o?.name || !f.data_o?.name_2));
    }).map(f => {
      f._error = true;
      return f;
    });
    fieldsWithError = fieldsWithError.concat(wrongSignatures);

    //Other dynamic editable fields
    const wrongDynamics = this.fields
      .filter(f => f.dynamicType === RtmTemplateDynamicField.SHIPPING_ADDRESS && !f.defaultValue)
      .map(f => {
        f._error = true;
        return f;
      });
    fieldsWithError = fieldsWithError.concat(wrongDynamics);

    if (fieldsWithError.length > 0) {
      this.commonService.showErrorMessage("Debes llenar todos los campos obligatorios", this.msgService);
      return;
    }

    this.templateFilled = true;
    setTimeout(() => {
      this._updatePayload("template");
      this.stepper?.next();
    }, 0);
  }

  onValidate() {
    //validate form
    if (!this.basicForm.valid) {
      this.commonService.showErrorMessage("La fecha de entrega y template son requeridos en el paso #1", this.msgService);
      return;
    }

    //Validate articles
    if (this.targetProducts.length < 1) {
      this.commonService.showErrorMessage("Debes agregar al menos 1 artículo en el paso #2", this.msgService);
      return;
    }

    //Set or update invoice
    this._updatePayload("invoice");

    this.confirmPayload();
  }

  confirmPayload() {
    if (this.alert) {
      
      //Mostrar confirmacion
      let invoices_views = "";
      for (const invoiceData of this.payload.invoices) {
        invoices_views += `<div>
        <b>Invoice ${invoiceData.invoice_number}</b>[${invoiceData.customer}] (${invoiceData.store})<br><ul>`;
        for (const item of invoiceData.targetProducts || []) {
          const color = ((item._selected || 0) < (item._available || 0)) ? "color-red" : "";
          invoices_views += `<li> ${item.name} <b>(<span class='${color}'>${item._selected} / ${item._available}</span>)</b></li>`;
        }
        invoices_views += "</ul></div>";
      }

      //warnings
      const customers: string[] = [];
      this.payload.invoices.forEach(invoice => {
        if (invoice.customer && !customers.includes(invoice.customer)) customers.push(invoice.customer);
      });
      const leftArticlesWarning = this.payload.invoices.some(i => {
        return i.targetProducts?.some(i => (i._selected || 0) < (i._available || 0) );
      });

      let warning_views = "";
      if (customers.length > 1 || leftArticlesWarning) {
        warning_views += `<br><div class='danger-container hds-padding-md'><b>IMPORTANTE:</b><br><ul>`;
        if (customers.length > 1) {
          warning_views += `<li><b>Múltiples clientes:</b> Se agregaron invoices de clientes diferentes</li>`;
        }
        if (leftArticlesWarning) {
          warning_views += `<li><b>Entrega parcial:</b> No se agregaron todos los artículos de uno o más invoices</li>`;
        }
        warning_views += `<ul></div>`;
      }

      const template = this.templates.find(t => t.id === this.payload.template);
      const templateLI = template ? `<li><b>Platilla:</b> ${template.name}</li>` : "";
      const body = `<b>Detalles</b><br><ul>
        <li><b>Fecha de entrega:</b> ${moment(this.payload.expected_date).format("MMM DD YYYY")}</li>
        <li><b>Hora de entrega:</b> ${this.payload.expected_time}</li>
        ${templateLI}
      </ul>
      ${invoices_views}${warning_views}`;

      const confirmationData: ConfirmationAlert = {
        title: 'Resumen',
        body: body,
        cancelLabel: "Cerrar",
        confirmLabel: 'Crear agenda',
        confirmDanger: false,
        onConfirm: () => {
          this.createAgenda();
        }
      };
      if (this.noInvoice) {
        confirmationData.secondaryLabel = "Guardar y agregar otro invoice";
        confirmationData.onSecondaryAction = () => {
          this.addAnotherInvoice();
        };
      }
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  createAgenda() {
    this.rtmService.createAgendaEntrega(this.payload).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: agenda => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.commonService.showSuccessMessage("Agenda creada correctamente");
        this.stepper?.next();
        this.agendaCreated = true

        this.dialogRef.close();
      },
      error: errorMessage => {
        this.alertService.finishConfirmationAlertv2(this.alert!);
        this.commonService.showErrorMessage(errorMessage, this.msgService);
      }
    });
  }

  addAnotherInvoice() {
    //restart stepper and current data
    this._reset();

    //Restart alert sync
    this.alertService.finishConfirmationAlertv2(this.alert!);
  }

  onAddNewInvoice() {
    this._reset();
  }

  deleteInvoice(invoiceData: EntregaData) {
    this.payload.invoices = this.payload.invoices.filter(d => d.invoice_id !== invoiceData.invoice_id);
    this._reset();
  }

  loadInvoiceData(invoiceData: EntregaData) {
    this._reset();
    
    this.basicForm.get('store')?.setValue(invoiceData.store);
    this.basicForm.get('invoice_number')?.setValue(invoiceData.invoice_number);
    
    this.targetProducts = invoiceData.targetProducts || [];
    this.sourceProducts = invoiceData.sourceProducts || [];

    this.areItemsSelected = this.targetProducts.length > 0;

    this.selectedInvoiceId = invoiceData.invoice_id;
    this.basicForm.get("store")?.disable();
    this.basicForm.get("invoice_number")?.disable();
  }

  private _updatePayload(type: "basic" | "template" | "invoice") {
    switch (type) {
      case "basic":
        const data = this.basicForm.value;
        if (parseInt(data.template) !== this.payload.template) {
          this.payload.fields = [];
        }
        this.payload.expected_date = moment(data.expected_date).format("YYYY-MM-DD");
        this.payload.expected_time = data.expected_time;
        this.payload.template = data.template;
        break;
      case "template":
        this.payload.fields = [...this.fields];
        break;
      case "invoice":
        const currentData = this._getCurrentInvoiceData();
        const existingIndx = this.payload.invoices.findIndex(i => i.invoice_number === currentData.invoice_number);
        if (existingIndx === -1) {
          // Add invoice data
          this.payload.invoices.push(currentData);
        } else {
          // Update
          this.payload.invoices[existingIndx] = currentData;
        }
        break;
    }
  }

  private _getCurrentInvoiceData(): EntregaData {
    const items = [...this.targetProducts].map(item => 
      ({
        item_id: item.id_item || "", 
        item_sale_id: item.id_sb || "",
        quantity: item._selected || 0
      })
    );
    const data: EntregaData = {
      invoice_number: this.invoice?.document_number || this.invoice?.invoice || 0,
      invoice_id: this.invoice?.id || 0,
      store: this.invoice?.store || "",
      items: items,
      targetProducts: [...this.targetProducts],
      sourceProducts: [...this.sourceProducts],
      customer: this.invoice?.customer || this.invoice?.customer_name
    };
    return data;
  }

  private _reset() {

    if (this.noInvoice) {
      this.invoice = null;
      this.basicForm.get("store")?.enable();
      this.basicForm.get("invoice_number")?.enable();
    }
    this.selectedInvoiceId = null;

    //Reset stepper
    this.stepper?.reset();

    //Step 1
    //Resetting the stepper will reset the form
    //Restore agenda basics
    this.basicForm.get("expected_date")?.setValue(moment(this.payload.expected_date));
    this.basicForm.get("expected_time")?.setValue(this.payload.expected_time);
    this.basicForm.get("template")?.setValue(this.payload.template);
    
    //Step 2
    //No need to reset fields, template fields are same for all invoices
    //this.fields = [];
    this.templateFilled = false;

    //Step 3
    this.targetProducts = [];
    this.sourceProducts = [];
    this.areItemsSelected = false;

    //Reset stepper
    //this.stepper?.reset();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
