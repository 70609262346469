import { Component, Inject, OnInit } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

interface Isnack {
    message: string,
    classType: string
}

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.css']
})
export class SnackbarComponent implements OnInit {

    message: string = "Snackbar works!";

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Isnack) { }

    ngOnInit(): void {
    }

}
