import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faComment, faSearch, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/common.service';
import { SalesService } from '../sales.service';
import { AuthService } from 'src/app/auth/auth.service';
import { chat, msg } from '../sales.interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { SalesChatsService } from './sales-chats.service';

@Component({
  selector: 'app-sales-chats',
  templateUrl: './sales-chats.component.html',
  styleUrls: ['./sales-chats.component.scss']
})
export class SalesChatsComponent implements OnInit {
  @ViewChild('CardContainer') CardContainer!: ElementRef;
  private destroy$ = new Subject();

  searchQuery: string = ''
  chats : chat[] = []

  accessToken: string | null = null;

  faChats = faComment;
  faUsers = faUsersViewfinder;
  faSearch = faSearch;
  searchContact: string = '';
  filteredChats: any[]  = []
  id_chat = 0
  chatSubscribtion?: Subscription

  featureAccess ={
    chat: false,
    admin: false
  }

  constructor(
    public commonService: CommonService,
    public salesService: SalesService,
    public authService: AuthService,
    private chatService: SalesChatsService
  ) { }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.chat = access.includes("chats:view");
    this.featureAccess.admin = access.includes("chats:view_admin")
  }

  ngOnInit(): void {
    this.getChats()
    this.setFeatureAccess();
    this.salesService.socket.emit("joinRoom", "chat_component")
    this.salesService.socket.on("chat_notification", (chat) => {
      this.join_chat(chat)
    } )

    if(this.featureAccess.admin === true){
      this.salesService.socket.on('chat_notification_admin', (cliente) => {
      this.join_chat(cliente)
    })}
  }

  join_chat(chat: chat ){
    //console.log(chat)
    const index = this.chats.findIndex(c => c.chat_id === chat.chat_id)
    //console.log(index)
    if(index !== -1){
      this.chats[index] = chat
    }else{
      this.chats.push(chat)
    }
    this.order_chat()
  }

  order_chat(){
    this.chats.sort((a, b) => {
     // Ordena primero por importancia (1 antes que 0)
     if (a.is_important !== b.is_important) {
      return b.is_important - a.is_important;
    }

    // Luego, ordena los que NO tienen mensajes sin leer antes que los que sí tienen
    const aUnread = b.mails_unread?.length > 0 ? 1 : 0;
    const bUnread = a.mails_unread?.length > 0 ? 1 : 0;

    return aUnread - bUnread;
  })}

  onSearchChats(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredChats = this.chats.filter(chat =>
      chat.cliente.cliente_nombre.toLowerCase().includes(query) ||
      chat.cliente.cliente_correo.toLowerCase().includes(query)
    );
  }

  onScroll(){
    const scrollTop = this.CardContainer.nativeElement.scrollTop;
    if (scrollTop > 0) {
      this.CardContainer.nativeElement.classList.add('shadow');
      //console.log("scroll")
    } else {
      this.CardContainer.nativeElement.classList.remove('shadow');
    }
  }

  getChats(){
    this.chatService.getChats().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.chats = res
        this.filteredChats = this.chats
        this.order_chat()
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  } 

/*   SingWithGoogle(){
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=303801480710-bogj1lua1b43sk1rpra0tjt1ivk88oqn.apps.googleusercontent.com&redirect_uri=http://localhost:5001/api/oauth2/callback&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly&access_type=offline&prompt=consent`;
    window.location.href = googleAuthUrl;
  } */

  Change_id(idCliente: number, correo: string, chat_id: number, chat_important: number, ejecutivo: any){
    this.chatService.changeChat(idCliente, correo, chat_id, chat_important, ejecutivo)
  }
  
  ngOnDestroy(): void {
    this.salesService.socket.emit("leave_room", "chat_notification")
    this.salesService.socket.emit("leave_room", "chat_notification_admin")
    this.destroy$.next();
    this.destroy$.complete();
  }
}
