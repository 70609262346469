import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesService } from '../../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsersService } from 'src/app/mods/users/users.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sales-clientes-create',
  templateUrl: './sales-clientes-create.component.html',
  styleUrls: ['./sales-clientes-create.component.css'],
  providers: [MessageService]
})
export class SalesClientesCreateComponent implements OnInit {
  
  private destroy$ = new Subject();
  form: FormGroup = this.fb.group({});
  asistentes : any[] = [];

  constructor(
    public config: DynamicDialogConfig, 
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    private salesServices: SalesService,
    public commonService: CommonService,
    public userService: UsersService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getAsistentes()
    this.form = this.fb.group({
      nombre: '',
      apellido:  '',
      email: ['', [Validators.email]],
      email_2: ['', [Validators.email]],
      phone:  null,
      phone_2 : null,
      main_asist:  null,
    });
  }

  getAsistentes(){
    this.salesServices.getAsistentes().pipe(takeUntil(this.destroy$)).subscribe(
      (asistentes) => {
        this.asistentes = asistentes
      })
  }
  
  onSubmit() {
    if (!this.form.get('nombre')?.value || !this.form.get('apellido')?.value) {
      this.commonService.showWarningMessage('The first name and last name are required.');
      return;
    }
      let data = this.form.value;
      const fechaActual = new Date();
    
      // Ajustar el formato de la fecha a 'YYYY-MM-DD HH:mm:ss' en hora local (zona: America/Hermosillo)
      const opciones: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'America/Hermosillo',
      };
    
      const fechaLocal = fechaActual.toLocaleString('es-MX', opciones);
    
      // Reemplazar las partes de la fecha para ajustarla a 'YYYY-MM-DD HH:mm:ss'
      const fechaFormateada = fechaLocal
        .split(',')[0] // Obtener la parte de la fecha 'DD/MM/YYYY'
        .split('/')
        .reverse()
        .join('-') + ' ' + // Cambiar a 'YYYY-MM-DD'
        fechaLocal
          .split(',')[1]
          .trim(); // Obtener la parte de la hora 'HH:mm:ss'
    
      // Asignar la fecha formateada a create_at
      data.create_at = fechaFormateada

      this.form.patchValue({
        nombre: this.form.get('nombre')?.value.trim(),
        apellido: this.form.get('apellido')?.value.trim(),
        email: this.form.get('email')?.value.trim(),
        email_2: this.form.get('email_2')?.value.trim()
      })

      this.createCliente(data);
  }

  createCliente(data: any) {
    //console.log(data)
    this.salesServices.createClient(data).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.commonService.showSuccessMessage(response.msg);
      this.ref.close({updateTable: true});
    }, error => {
      this.commonService.showErrorMessage(error);
    });
  }

  cancel() {
    this.form.reset();
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
