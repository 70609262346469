import { tagsRoutes } from './mods/tags/tags.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { AuthComponent } from './auth/auth.component';
import { NoAuthGuard } from './auth/noauth-guard.service';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { HomeComponent } from './home/home.component';
import { arqueoRoutes } from './mods/arqueo/arqueo.module';
import { dashboardRoutes } from './mods/dashboard/dashboard.module';
import { historyRoutes } from './mods/history/history.module';
import { usersRoutes } from './mods/users/users.module';
//import { UsersComponent } from './mods/users/users.component';
import { TestComponent } from './test/test.component';
import { rtmRoutes } from './mods/rtm/rtm.module';

//var uRoutes = usersRoutes;
// const dashboardRoutes: Routes = [
//   { path: 'dashboard', canActivate:[AuthGuard], component: TestComponent}
// ];

let appRoutes: Routes = [
    { 
      path: '',
      canActivate:[AuthGuard], 
      component: HomeComponent, 
      children: dashboardRoutes
        .concat(usersRoutes).concat(historyRoutes).concat(arqueoRoutes).concat(rtmRoutes).concat(tagsRoutes)
    },
    { path: 'login', canActivate:[NoAuthGuard], component: AuthComponent},
    // { path: 'test', canActivate:[AuthGuard], component: TestComponent}
];
// for (let route of historyRoutes) {
//   appRoutes.push(route);
// }

@NgModule({
    declarations: [],
    imports: [ 
        RouterModule.forRoot(appRoutes) 
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}