import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from '../users.service';
import { CommonService } from 'src/app/shared/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Dpto } from 'src/app/shared/common.enums';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-dpto-form',
  templateUrl: './dpto-form.component.html',
  styleUrls: ['./dpto-form.component.css'],
  providers: []
})
export class DptoFormComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  dptoForm: FormGroup;
  loading = false;
  saveIcon = faSave;
  loadingIcon = faSpinner;
  dpto?: Dpto;
  alert?: AlertV2Component;

  constructor(
    private usersService: UsersService,
    private commonService: CommonService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private alertService: AlertService
  ) {
    this.dptoForm = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required]
      })
    });
    this.commonService.alertRef$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(alert => {
      if (alert) {
        this.alert = alert;
      }
    })
    this.dpto = this.dialogConfig.data?.dpto;
    //this.alert = this.dialogConfig.data?.alert;
  }

  ngOnInit(): void {
    this.fillForm();
  }

  fillForm() {
    if (this.dpto) {
      this.dptoForm.get('name')?.setValue(this.dpto.name);
    }
  }

  onSave() {
    if (!this.dptoForm.valid) {
      this.commonService.showErrorMessage(`Todos los campos son requeridos`);
      return;
    }

    if (this.dpto) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {
    if (!this.loading) {
      this.loading = true;
      this.usersService.addDpto(this.dptoForm.value).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: dpto => {
          this.commonService.showSuccessMessage(`Departamento ${dpto.name} creado correctamente`);
          this.loading = false;
          this.closeDialog();
        },
        error: errorMessage => {
          this.commonService.showErrorMessage(errorMessage);
          this.loading = false;
        }
      })
    }
  }

  update() {
    if (!this.loading) {
      this.loading = true;
      this.usersService.updateDpto(this.dpto!.id, this.dptoForm.value).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (dpto) => {
          this.commonService.showSuccessMessage(`Departamento ${dpto.name} actualizado correctamente`);
          this.loading = false;
          this.closeDialog();
        },
        error: errorMessage => {
          this.commonService.showErrorMessage(errorMessage);
          this.loading = false;
        }
      })
    }
  }

  onDelete() {
    if (this.alert) {
      const body = `¿Estás seguro de eliminar el departamento?`;
      const confirmationData: ConfirmationAlert = {
        title: 'Confirmación',
        body: body,
        confirmLabel: 'Eliminar',
        confirmDanger: true,
        onConfirm: () => {
          this.delete();
        }
      };
      this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
    }
  }

  delete() {
    if (!this.loading) {
      this.loading = true;
      this.usersService.deleteDpto(this.dpto!.id).pipe(
        takeUntil(this.destroy$)
      ).subscribe({
        next: (dpto) => {
          this.commonService.showSuccessMessage(`Departamento ${dpto.name} eliminado correctamente`);
          this.loading = false;
          this.alertService.finishConfirmationAlertv2(this.alert!);
          this.closeDialog();
        },
        error: errorMessage => {
          this.commonService.showErrorMessage(errorMessage);
          this.loading = false;
          this.alertService.finishConfirmationAlertv2(this.alert!);
        }
      })
    }
  }

  closeDialog() {
    setTimeout(() => {this.dialogRef.close()}, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
