import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faEdit, faList, faSpinner, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonService } from 'src/app/shared/common.service';
import { UsersService } from '../users.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css'],
  providers: [MessageService]
})
export class UsersListComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  editIcon = faEdit;
  addUserIcon = faUserPlus;
  loadingIcon = faSpinner;
  addUserView: boolean = false;
  loading: boolean = false;
  listIcon = faList;

  featureAccess = {
    add: false,
    modify: false
  }
  
  constructor(
    public usersService: UsersService, 
    public commonService: CommonService, 
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setFeatureAccess();
    if (this.usersService.users.length === 0 || this.usersService.updateUsersList) {
      this.getUsers();
      this.usersService.updateUsersList = false;
    }
    if (this.usersService.redirectedToList) {
      this.usersService.redirectedToList = false;
      setTimeout(() => {
        this.commonService.showWarningMessage("Seleccione el ícono de edición en uno de los usuarios en la tabla", this.messageService, "Atención", 5000);
      }, 0);
    }
  }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.add = access.includes("users:edit");
    this.featureAccess.modify = access.includes("users:edit");
  }
  
  getUsers() {
    if (!this.loading) {
      this.loading = true;
      this.usersService.getAccesses().pipe(
        switchMap(accesses => this.usersService.getUsers(this.usersService.statusFilter)),
        takeUntil(this.destroy$)
      ).subscribe(result => {
        this.loading = false;
      }, error => {
        this.commonService.showErrorMessage(error, this.messageService);
        this.loading = false;
      });
    }
  }

  onModifyUser(i: number) {
    if (this.featureAccess.modify) {
      const user = this.usersService.users[i];
      this.usersService.editingUser = user;
      this.router.navigate(["/usuarios/modificar"]);
    } else {
      this.commonService.showErrorMessage("No cuenta con acceso para modificar usuarios. Solicite acceso a su administrador");
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
