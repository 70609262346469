import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { DashboardHistoryComponent } from './history/history.component';
import { DashboardService } from './dashboard.service';
import { DashboardDailySalesComponent } from './daily-sales/daily-sales.component';
import {ChartModule} from 'primeng/chart';
import { DashboardMonthlySalesComponent } from './monthly-sales/monthly-sales.component';

export const dashboardRoutes: Routes = [
  {path: "panel", canActivate: [AuthGuard], component: DashboardComponent}
]

@NgModule({
  declarations: [
    DashboardComponent, 
    DashboardHistoryComponent, 
    DashboardDailySalesComponent, 
    DashboardMonthlySalesComponent
  ],
  imports: [ 
    RouterModule,
    CommonModule,
    SharedModule,
    ChartModule
  ],
  exports: [RouterModule],
  providers: [DashboardService],
})
export class DashboardModule {}