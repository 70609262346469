import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SalesChatsService } from '../sales-chats.service';
import { Subject } from 'rxjs';
import { msg } from '../../sales.interfaces';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/common.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesChatsViewComponent } from '../sales-chats-view/sales-chats-view.component';
import { SalesClientesViewComponent } from '../../sales-clientes/sales-clientes-view/sales-clientes-view.component';
import { SalesChatsGmailCreateComponent } from './sales-chats-gmail-create/sales-chats-gmail-create.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';

@Component({
  selector: 'app-sales-chats-gmail',
  templateUrl: './sales-chats-gmail.component.html',
  styleUrls: ['./sales-chats-gmail.component.css']
})
export class SalesChatsGmailComponent implements OnInit, OnDestroy {

  @ViewChild('alert') alert!: AlertV2Component;

  private destroy$ = new Subject();
  
  faSearch = faSearch

  msgs: msg [] = []
  id_cliente: any 
  correo: any
  chat_id: any
  chat_important: any
  ejecutivo: any

  constructor(
    private chatService: SalesChatsService,
    public commonService: CommonService,
    public dialogService: DialogService,
    private alertService: AlertService
  ) { 
  }

  ngOnInit(): void {
    this.chatService.chatId$.pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        console.log(res)
        this.id_cliente = res?.id_cliente
        this.correo = res?.correo
        this.chat_id = res?.chat_id,
        this.chat_important = res?.chat_important
        this.ejecutivo = res?.ejecutivo
        this.loadChatData(this.chat_id)
      }
    )
  }

  loadChatData(id: number){
    if(!id){
      return
    }

    this.chatService.getMsg(id).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.msgs = res
        this.ordenarMensajesPorFecha()
      },
      err => {
        this.commonService.showErrorMessage(err.msg)
      }
    )
  }

  ordenarMensajesPorFecha() {
    this.msgs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  getColorMsg(status: string): any {
    switch (status) {
      case 'Enviado':
        return { 'background-color': '#f9dfb2' };
      case 'Recibido':
        return { 'background-color': '#f1ffff' };
      default:
        return { 'background-color': '#cccccc' };
    }
  }  

  see_mail(id: number, subject: string, view_confir: number, status: string){
   const ref = this.dialogService.open(SalesChatsViewComponent, {
    header: `${subject}`,
      width: '70%',
      data: {id, view_confir, status} 
   }) 
   ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(
    ()=>{
      if(view_confir === 0 && status === 'Recibido'){
        const data = {
          id: id,
          id_chat: this.chat_id
        }

        this.chatService.confirmViewMsg(data).pipe(takeUntil(this.destroy$)).subscribe(
          res => {
            this.confirm_view(id)
          },
          err => {
            //console.log(err)
          }
        )
      }
    }
   )
  }

  confirm_view(id: number) {
    this.msgs.forEach((msg) => {
      if (msg.id === id) {
        msg.view_confirm = 1
      }
    })
  }

  viewCliente(id_cliente: number){

    if(!this.id_cliente){
      this.commonService.showInfoMessage("No hay cliente seleccionado")
      return
    }

    this.dialogService.open(SalesClientesViewComponent, {
      header: `Cliente`,
      width: '70%',
      data: {id:id_cliente, button: true}
     })
  }

  CreateMail(correo: string, chat_id: number){
   const ref = this.dialogService.open(SalesChatsGmailCreateComponent, {
      width: '70%',
      data: {correo, chat_id}
    })

    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(
      (reload)=>{
        if(reload === true){
          this.loadChatData(this.chat_id)
        }
      }
    )
  }

  Ondelete_MSG(id: number){
    const data ={
      id: id,
      date: this.commonService.getLocalDate()
    }

    this.chatService.delete_msg(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.loadChatData(this.chat_id)
      },
      err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  Onmark_unread(id: number){
    this.msgs.forEach((msg) => {
      if (msg.id === id) {
        msg.view_confirm = 0
      }
    }) 

    const data ={
      mail_id: id,
      id_chat: this.chat_id
    }

    this.chatService.Mark_as_unread(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
     
      }, err =>{
        this.commonService.showErrorMessage(err)
        this.msgs.forEach((msg) => {
          if (msg.id === id) {
            msg.view_confirm = 1
          }
        }) 
      }
    )
  }

  OnDelete_chat(){
    const body = `<h2>¿Seguro que quiere eliminar el chat?</h2>`
    const confirmationData: ConfirmationAlert = {
        title: 'Eliminar dirección del cliente',
        body: body,
        cancelLabel: "Cerrar",
        confirmLabel: 'Eliminar',
        confirmDanger: true,
      onConfirm: () => {
         this.delete_chat()
      }
    };
    this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
  }

  delete_chat(){
    const data = {
      date: this.commonService.getLocalDate(),
      id_chat: this.chat_id
    }

    this.chatService.delete_chat(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showSuccessMessage(res.msg)
      },err => {
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnMark_as_important(){
    const data = {
      actual_important: this.chat_important,
      id: this.chat_id,
      id_chat: this.chat_id
    }
    this.chatService.Mark_as_favorite(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.chat_important = this.chat_important === 0 ? 1 : 0;
        console.log(this.chat_important)
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
