import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { throwError } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';

@Injectable({
  providedIn: 'root'
})
export class TagSleepService {
  constructor(private commonService: CommonService) {}

  handleRequestError(error: HttpErrorResponse) {
    let errorMessage = "";

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);

    return throwError(() => new Error(errorMessage));
  }

  public generateLabelsPDF(
    up4List: Array<any>,
    up16List: Array<any>,
    up80List: Array<any>
    //TagTemplate: string
  ) {
    if (up4List && up4List.length > 0) {
      this.Up4Label(up4List);
    }

    if (up16List && up16List.length > 0) {
      this.Up16Label(up16List);
    }

    if (up80List && up80List.length > 0) {
      this.Up80Label(up80List);
    }
  }

  private async Up4Label(up4List: Array<any>) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    const SpectralFont = "../../../assets/Font/Spectral-Bold-bold.js";
    doc.addFileToVFS("Spectral-Bold.js", SpectralFont);
    doc.addFont("Spectral-Bold.js", "Spectral", "Bold");

    let RedLine = "../../../../assets/Labels Img/line-png-16810.png"
    let ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png";

    const labelWidth = 10.85;
    const labelHeight = 13.95;

    const Positions = [
      { x: 0, y: 0, imgX: 0, imgY: 0 },
      { x: labelWidth, y: 0, imgX: labelWidth, imgY: 0 },
      { x: 0, y: labelHeight, imgX: 0, imgY: labelHeight },
      { x: labelWidth, y: labelHeight, imgX: labelWidth, imgY: labelHeight },
    ];

    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await loadImage(ImgURL);

        const ImgWidth = 3.3;
        const ImgHeight = 2.4;

        const imgPaddingX = 3.8;
        const imgPaddingY = 1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 4;
        const lineHeight = 1;

        //Logo
        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        //Product Name
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(22)
        let longText = item.productName;
        let textLines = doc.splitTextToSize(longText, 9); // Ancho máximo de 9 unidades
        
        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
        });
        

        //Product ID
        let textWidth = doc.getTextWidth(item.productId);
        doc.text(item.productId, centerX - textWidth / 2, startY + textLines.length);

        //RP + Line Red
        doc.setFontSize(37.5);
        const URP = item.productPrice + (item.productPrice / 100 * 20)
        let formattedPrice = URP.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`)
        const CenterLine = textWidth + 1
        doc.addImage(RedLine, "PNG", centerX - CenterLine / 2, startY + 3.5* lineHeight, CenterLine , .62)
        doc.text(`$${formattedPrice}`, centerX - textWidth / 2, startY + 4 * lineHeight)
        
        //USD + IVA
        doc.setFontSize(11)
        textWidth = doc.getTextWidth("(USD)(+IVA)")
        doc.text("(USD)(+IVA)", centerX - textWidth / 2, startY + 4.5 * lineHeight)
        
        //Reduced Price text
        doc.setFontSize(18)
        textWidth = doc.getTextWidth("Reduced Price")
        doc.text("Reduced Price",centerX - textWidth / 2, startY + 5.5 * lineHeight)

        //Product Price
        doc.setFontSize(54)
        formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 7.5 * lineHeight
        );

        //USD + Iva text
        doc.setFontSize(11)
        textWidth = doc.getTextWidth("(USD)(+IVA)")
        doc.text("(USD)(+IVA)",centerX - textWidth / 2, startY + 8 * lineHeight)
      } catch {
        this.commonService.showErrorMessage("Error al generar Etiquetas (Ashley) Up4");
      }
    };

    for (const item of up4List) {
      const quantityTags = item.quantityTags || 1;
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % 4];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % 4 === 0 &&
          labelIndex <
            up4List.length * Math.max(...up4List.map((p) => p.quantityTags))
        ) {
          doc.addPage();
        }
      }
    }
    this.commonService.showSuccessMessage("Etiquetas (Ashley) Up4");
    doc.save("Etiquetas (Ashley) Up4.pdf");
  }

  private async Up16Label(up16List: Array<any>) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    //Cargamos la fuente Spectral.tff
    const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf";
    doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
    doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

    let ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png";

    const labelWidth = 5.4;
    const labelHeight = 6.9;

    const Positions = [];

    for (let row = 0; row < 4; row++) {
      for (let col = 0; col < 4; col++) {
        Positions.push({
          x: col * labelWidth,
          y: row * labelHeight,
          imgX: col * labelWidth,
          imgY: row * labelHeight,
        });
      }
    }

    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await loadImage(ImgURL);

        const ImgWidth = 2.4;
        const ImgHeight = 1.7;

        const imgPaddingX = 1.5;
        const imgPaddingY = 0.1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 2.5;
        const lineHeight = 0.75;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        let longText = item.productName;
        let textLines = doc.splitTextToSize(longText, 5); // Ancho máximo de 9 unidades
        
        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
        });
        
        let textWidth = doc.getTextWidth(item.productId);
        doc.text(
          item.productId,
          centerX - textWidth / 2, startY + lineHeight * textLines.length
        );
        doc.setFontSize(28);
        doc.setFont("Spectral", "bold");
        const formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });

        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 1.8 + lineHeight
        );

        doc.setFont("Helvetica", "normal");
        doc.setFontSize(10);
        textWidth = doc.getTextWidth("USD");
        doc.text("USD", centerX - textWidth / 2, startY + 2.3 + lineHeight);

        doc.setFontSize(12);
        textWidth = doc.getTextWidth("(+IVA)");
        doc.text("(+IVA)", centerX - textWidth / 2, startY + 2.8 + lineHeight);

      } catch (err) {
        this.commonService.showErrorMessage("Error al generar Etiquetas (Ashley) Up16");
      }
    };

    for (const item of up16List) {
      const quantityTags = item.quantityTags || 1;
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % 16];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % 16 === 0 &&
          labelIndex < up16List.length * quantityTags
        ) {
          doc.addPage();
        }
      }
    }
    this.commonService.showSuccessMessage("Etiquetas (Ashley) Up16 Generadas");
    doc.save("Etiquetas (Ashley) Up16.pdf");
  }

  private async Up80Label(up80List: Array<any>) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    // Carga y define la fuente Spectral-Bold
    const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf";
    doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
    doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

    const ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png"

    // Definición de las posiciones de las etiquetas
    const labelWidth = 1.268181818181818;
    const labelHeight = 4.4;
    const margin = 0.75;
    const Positions = [];

    const totalRows = 20; // Número total de filas (ajusta según sea necesario)
    const labelsPerRow = 4; // Cantidad de etiquetas por fila

    for (let row = 1; row <= totalRows; row++) {
      for (let col = 0; col < labelsPerRow; col++) {
        const x = col * labelHeight + margin * (col + 1);
        const y = labelWidth * row;
        Positions.push({
          x: x,
          y: row < 21 ? y : 1.268181818181818,
          imgX: x,
          imgY: row < 21 ? y : 1.268181818181818,
        });
      }
    }

    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await loadImage(ImgURL);
        const ImgWidth = 1.3;
        const ImgHeight = 0.87;

        const imgPaddingX = 1.5;
        const imgPaddingY = 0;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth * 1.7;
        const startY = y + 0.85;
        const lineHeight = 0.3;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        // Añadir nombre del producto y ID
        doc.setFontSize(5);
        doc.setFont("Helvetica", "bold");
        const productText = `${item.productName} ${item.productId}`;
        let textWidth = doc.getTextWidth(productText);
        doc.text(productText, centerX - textWidth / 2, startY);

        // Añadir precio con textos en diferentes tamaños
        const priceText = `$${item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}`;
        const currencyText = "(USD)";
        const taxText = "(+IVA)";

        // Configura el tamaño de la fuente para el precio principal
        doc.setFontSize(8);
        doc.setFont("Spectral", "bold");
        let priceWidth = doc.getTextWidth(priceText + currencyText + taxText);
        doc.text(priceText, centerX - priceWidth / 2, startY + 0.3);

        // Ajusta el tamaño de la fuente para "(USD)" y "(+IVA)"
        doc.setFontSize(6);
        doc.setFont("Helvetica", "normal");
        doc.text(currencyText + taxText, centerX, startY + lineHeight);
      } catch (err) {
        this.commonService.showErrorMessage(
          "Error al generar las Etiquetas (Ashley) Up80"
        );
      }
    };

    for (const item of up80List) {
      const quantityTags = item.quantityTags || 1; // Usa 1 si quantityTags no está definido
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % Positions.length];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % Positions.length === 0 &&
          labelIndex <
            up80List.length * Math.max(...up80List.map((p) => p.quantityTags))
        ) {
          doc.addPage();
        }
      }
    }
    this.commonService.showSuccessMessage("Etiquetas (Ashley) Up80 Generadas");
    doc.save("Etiquetas (Ashley) Up80.pdf");
  }

}