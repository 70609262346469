import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../../../sales-address.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs/operators';
import { BaseType, calle, casa, ciudad, colonia } from '../../../sales-search/address.enum';

@Component({
  selector: 'app-sales-clientes-address-foranea',
  templateUrl: './sales-clientes-address-foranea.component.html',
  styleUrls: ['./sales-clientes-address-foranea.component.css']
})
export class SalesClientesAddressForaneaComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  ForaneaForm = this.fb.group({})

  Colonias : colonia[] = []
  Calles: calle[] = []
  Casas: casa[] = []
  Ciudades: ciudad[] = []
  BaseType: BaseType[] = []

  filteredColonias: colonia[] = [];
  filteredCalles: calle[] = [];
  filteredEntreCalles1: calle[] =[]
  filteredEntreCalles2: calle[] =[]
  filteredNumerosCasa: casa[] = [];
  filteredCiudades: ciudad[] = [];

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    public addresServices: SalesAddressService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) { 
    this.ForaneaForm = this.fb.group({
      Ciudad:['', Validators.required],
      Colonia:['', Validators.required],
      Calle:['', Validators.required],
      Numero_Casa:['', Validators.required],
      EntreCalle1:['', Validators.required],
      EntreCalle2: ['', Validators.required],
      Descripcion: ['']
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.getCiudades()
    this.getColonias()
    this.getCalles()
    this.getCasas()
    this.ForaneaForm.get('Ciudad')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredCiudades = this.filter(value, this.Ciudades)
    })
    this.ForaneaForm.get('Colonia')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredColonias = this.filter(value, this.Colonias);
    });
    this.ForaneaForm.get('Calle')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredCalles = this.filter(value, this.Calles);
    });
    this.ForaneaForm.get('EntreCalle1')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value=>{
      this.filteredEntreCalles1 = this.filter(value, this.Calles)
    })
    this.ForaneaForm.get('EntreCalle2')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value=>{
      this.filteredEntreCalles2 = this.filter(value, this.Calles)
    })
    this.ForaneaForm.get('Numero_Casa')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredNumerosCasa = this.filter(value, this.Casas);
    });
  }

  filter(value: string, options: BaseType[]): BaseType[] {
    //console.log("value",value, "option",options)
    const filterValue = value.toLowerCase();
    return options.filter(option => option.nombre.toLowerCase().includes(filterValue)); 
  }

  getCiudades(){
    this.addresServices.get("ciudad").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (response)=>{
        this.Ciudades = response.filter((c: ciudad) => c.status === "Activo")
      },
      (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }
  getColonias(){
    this.addresServices.get("colonia").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (response)=>{
        this.Colonias = response.filter((c: colonia)=> c.status === "Activo")
      },
      (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }
  getCalles(){
    this.addresServices.get("calle").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (response)=>{
        this.Calles = response.filter((c: calle) => c.status === "Activo")
      },
      (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }
  getCasas(){
    this.addresServices.get("casa").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (response)=>{
        this.Casas = response.filter((c: casa) => c.status === "Activo")
      },
      (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnAddColoniaAddress(){
    if(this.ForaneaForm.valid){
      const data = {
        Ciudad: this.ForaneaForm.value.Ciudad,
        Colonia: this.ForaneaForm.value.Colonia,
        Calle: this.ForaneaForm.value.Calle,
        Numero_Casa:this.ForaneaForm.value.Numero_Casa,
        EntreCalle1:this.ForaneaForm.value.EntreCalle1,
        EntreCalle2:this.ForaneaForm.value.EntreCalle2,
        Descripcion:this.ForaneaForm.value.Descripcion,
        idCliente: this.config.data 
      }
      this.addresServices.addForaneaAddress(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res)=>{
          this.commonService.showSuccessMessage(res.msg)
          this.ForaneaForm.reset({
            Ciudad:'',
            Calle:'', 
            Numero_Casa:'',
            EntreCalle1:'',
            EntreCalle2: '',
            Descripcion: ''
          })
          this.ref.close(true)
        }, 
        (err)=>{
          this.commonService.showErrorMessage(err)
        }
      )
    }
  }
}
