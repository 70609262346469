import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { HistoryComponent } from './history.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HistoryService } from './history.service';

export const historyRoutes: Routes = [
  {path: "historial", canActivate:[AuthGuard], component: HistoryComponent}
]

@NgModule({
  declarations: [
    HistoryComponent
  ],
  imports: [ 
    RouterModule,
    CommonModule,
    SharedModule
  ],
  exports: [RouterModule],
  providers: [
    HistoryService
  ],
})
export class HistoryModule {}