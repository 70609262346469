import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HistoryRecord } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { io } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public history$: Subject<HistoryRecord[]> = new Subject();
  public sbSync$: Subject<any> = new Subject();
  socket = io(environment.socketio);

  colors: string[] = ["#FF6384","#36A2EB","#FFCE56","#607d8b","#4caf50","#ff9800", "#cddc39", "#009688", "#3f51b5", "#03d6f1"];
  userColors: {[key: string]: string} = {};

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ){
    this.socket.on('history', (message) =>{
      this.history$.next(message);
    });

    this.socket.on('arqueo_synced', (data) => {
      this.sbSync$.next(data);
    })
  }

  getHistoryRecords() : Observable<HistoryRecord[]>{
    return this.http.get<HistoryRecord[]>(
      `${environment.endpoint}history/?limit=5`,
    ).pipe( 
      catchError(this.commonService.handleRequestError), 
      // tap(records => {
      //   this.records = records;
      // })
    );
  }

  getUserColors(users: string[]) {
    let colors = [];
    for (let user of users) {
      colors.push(this.userColors[user] || '#9e9e9e');
    }
    return colors;
  }

  updateUserColors(users: string[]) {
    for (let user of users) {
      if (!this.userColors[user]) {
        this.userColors[user] = this.getAvailableColor();
      }
    }
  }

  getAvailableColor() {
    let usedColors = Object.values(this.userColors);
    for (let color of this.colors) {
      if (!usedColors.includes(color)) {
        return color;
      }
    }
    return '#9e9e9e';
  }

  // public sendHistory(message: string) {
  //   this.socket.emit('history', message);
  // }
  public getSBsyncSubject = () => {
    return this.sbSync$.asObservable();
  }

  public getHistorySubject = () => {
    return this.history$.asObservable();
  }
}