import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MOMENT_FORMAT_DATE, MOMENT_FORMAT_TIME, Store } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
import { Agenda, Invoice, Template, TemplateField } from './rtm.enums';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RtmService {

  templates$ = new BehaviorSubject<Template[]>([]);
  agendaFilters$ = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) { }

  getInvoices(store: Store, invoice?: string): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(
      `${environment.endpoint}rtm/invoices/?store=${store}&invoice=${invoice}`
    ).pipe(
      map(invoices => {
        for (const invoice of invoices) {
          if (invoice.name) {
            invoice._is_pe = invoice.name.indexOf("PE") !== -1 || 
              invoice.name.toLowerCase().indexOf("pedido especial") !== -1;
          }
        }
        return invoices;
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getInvoice(store: Store, invoice_number: number): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${environment.endpoint}rtm/invoice/find/${store}/${invoice_number}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getInvoiceItems(document: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.endpoint}rtm/invoices/items/?document=${document}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getInventoryItems(ids_sb: string[], store: Store): Observable<any[]> {
    return this.http.post<any[]>(
      `${environment.endpoint}rtm/invoices/inventory/ids`, 
      { ids: ids_sb, store: store }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  syncInvoicesAndItems(invoice_id: string, ids_sb: string[], store: Store): Observable<any[]> {
    return this.http.post<any[]>(
      `${environment.endpoint}rtm/invoices/inventory/ids/sync`, 
      { invoice_id, ids: ids_sb, store: store }
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  markAsDispatched(invoice_id: number): Observable<Invoice> {
    return this.http.patch<Invoice>(
      `${environment.endpoint}rtm/invoice/${invoice_id}/dispatched`, {}
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  // Agenda

  getAgendas(filters: {[key:string]: any}): Observable<Agenda[]> {
    const query = this._getAgendaFiltersQuery(filters);
    return this.http.get<Agenda[]>(
      `${environment.endpoint}rtm/agendas/${query}`
    ).pipe(
      map(agendas => {
        return this._transformAgendas(agendas);
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  getTemplateRecordByAgenda(agenda_id: number): Observable<Template> {
    return this.http.get<Template>(
      `${environment.endpoint}rtm/agenda/${agenda_id}/template-record`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getInvoiceAgenda(invoice_id: number): Observable<Agenda[]> {
    return this.http.get<Agenda[]>(
      `${environment.endpoint}rtm/invoice/${invoice_id}/agenda`
    ).pipe(
      map(agendas => {
        return this._transformAgendas(agendas);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  createAgendaEntrega(data: any): Observable<Agenda> {
    return this.http.post<Agenda>(
      `${environment.endpoint}rtm/agendas/entrega`, 
      data
    ).pipe(
      map(agenda => {
        return this._transformAgenda(agenda);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  confirmAgendaDate(agenda_id: number, data: any): Observable<Agenda> {
    return this.http.patch<Agenda>(
      `${environment.endpoint}rtm/agenda/${agenda_id}/confirm-date`, 
      data
    ).pipe(
      map(agenda => {
        return this._transformAgenda(agenda);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  markAsDone(agenda_id: number): Observable<Agenda> {
    return this.http.patch<Agenda>(
      `${environment.endpoint}rtm/agenda/${agenda_id}/done`, {}
    ).pipe(
      map(agenda => {
        return this._transformAgenda(agenda);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  deleteAgenda(agenda_id: number): Observable<Agenda> {
    return this.http.delete<Agenda>(
      `${environment.endpoint}rtm/agenda/${agenda_id}/`, {}
    ).pipe(
      map(agenda => {
        return this._transformAgenda(agenda);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  //Templates

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(
      `${environment.endpoint}rtm/templates`
    ).pipe(
      map(templates => {
        for (const template of templates) {
          template.fields = template.fields?.map(f => new TemplateField(f));
        }
        return templates;
      }),
      tap(templates => {
        this.templates$.next(templates);
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  getTemplate(template_id: number): Observable<Template> {
    const templates = [...this.templates$.getValue()];
    const existing = templates.find(t => t.id === template_id);
    if (existing) {
      return of(existing);
    }
    return this.http.get<Template>(
      `${environment.endpoint}rtm/templates/:${template_id}`
    ).pipe(
      map(template => {
        template.fields = template.fields?.map(f => new TemplateField(f));
        return template;
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  createTemplate(template: Partial<Template>): Observable<Template> {
    return this.http.post<Template>(
      `${environment.endpoint}rtm/templates/`, 
      template
    ).pipe(
      tap(template => {
        if (template) {
          const templates = [...this.templates$.getValue()];
          templates.push(template);
          this.templates$.next(templates);
        }
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  updateTemplate(template_id: number, template: Partial<Template>): Observable<Template> {
    return this.http.patch<Template>(
      `${environment.endpoint}rtm/templates/${template_id}`, 
      template
    ).pipe(
      tap(template => {
        const templates = [...this.templates$.getValue()];
          const modIndx = templates.findIndex(t => t.id === template.id);
          if (modIndx !== -1) {
            templates[modIndx] = template;
            this.templates$.next(templates);
          }
      }),
      catchError(this.commonService.handleRequestError)
    )
  }

  deleteTemplate(template_id: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.endpoint}rtm/templates/${template_id}`
    ).pipe(
      tap(() => {
        const templates = [...this.templates$.getValue()];
        this.templates$.next(templates.filter(t => t.id !== template_id));
      }),
      catchError(this.commonService.handleRequestError)
    );
  }

  deleteTemplateField(field_id: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.endpoint}rtm/template-field/${field_id}`
    ).pipe(
      catchError(this.commonService.handleRequestError)
    );
  }

  private _transformAgendas(agendas: Agenda[]) {
    for (const agenda of agendas) {
      if (agenda.expected_date) {
        agenda._datetime = `${moment(agenda.expected_date).format(MOMENT_FORMAT_DATE)} ${agenda.expected_time || ''}`;
        agenda.expected_time = moment(agenda._datetime).format(MOMENT_FORMAT_TIME);
      }
      agenda._doc_numbers_str = (agenda._invoices_no && agenda._invoices_no > 1) 
        ? agenda._invoices?.map(i => i._document_number).join(", ") 
        : agenda._document_number?.toString();
    }
    return agendas;
  }

  private _transformAgenda(agenda: Agenda) {
    return this._transformAgendas([agenda])?.[0];
  }

  private _getAgendaFiltersQuery(filters: {[key:string]: any}) {
    let query = "?";
    if (filters['store']) {
      query += `store=${filters['store']}&`;
    }
    if (filters['status']) {
      query += `status=${filters['status']}&`;
    }
    if (filters['type']) {
      query += `type=${filters['type']}&`;
    }
    if (filters['date']) {
      query += `date=${filters['date']}&`;
    }
    return query;
  }
}
