import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  faFileCsv,
  faSpinner,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { CommonService } from "src/app/shared/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TagsCSVService } from "../tags-csv.service";
import {
  Up4Products,
  Up16Products,
  Up80Products,
  Up2Products,
} from "../tags.enums";
import jsPDF from "jspdf";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { TagsProcessProdutService } from "../tags-process-product.service";
import { TagsAshleyProductsComponent } from "./tags-ashley-products/tags-ashley-products.component";
import { TagsAshleyService } from "./tags-ashley.service";

@Component({
  selector: "app-tags-ashley",
  templateUrl: "./tags-ashley.component.html",
  styleUrls: ["./tags-ashley.component.css"],
  providers: [DialogService],
})
export class TagsAshleyComponent implements OnInit {
 
  ref: DynamicDialogRef | undefined;
  
  up2List: Up2Products[] = [];
  up4List: Up4Products[] = [];
  up80List: Up80Products[] = [];
  up16List: Up16Products[] = [];

  // Variables del formulario
  productId: string = "";
  quantityOption: string = "";
  quantityTags: number = 1;
  TagTemplate = ""
  
  //Utils
  tagsIcon = faTags;
  loadingIcon = faSpinner;
  CSVicon = faFileCsv;
  loading = false;
  pdf_loading = false;

  constructor(
    public commonService: CommonService,
    private messageService: MessageService,
    private csvReader: TagsCSVService,
    private productProcess: TagsProcessProdutService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    public dialogService: DialogService,
    private tagsService: TagsAshleyService,
  ) { }

  ngOnInit(): void {
    this.up2List = this.productProcess.getUp2Products()
    this.up4List = this.productProcess.getUp4Products()
    this.up16List = this.productProcess.getUp16Products()
    this.up80List = this.productProcess.getUp80Products()
    }

    ngOnDestroy(): void{
      this.productProcess.clearAllProducts();
     }
   
     onSizeChange() {
      const option = !this.commonService.fullSize$.value;
      this.commonService.fullSize$.next(option);
    }
  
    //Manejo del CSV
    onFileSelected(event: any): void {
      const file: File = event.target.files[0];
      if (file) {
        this.csvReader
          .parseCsv(file)
          .then((data) => {
            data.forEach((item) => {
              this.productProcess.processProduct(item);
            });
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  
   // Imprimir Etiquetas
    async OnLabelsPrint(): Promise<void> {
      if (!this.up2List.length && !this.up4List.length && !this.up16List.length && !this.up80List.length) {
        this.commonService.showWarningMessage("No hay etiquetas para imprimir");
        return;
      }

      this.commonService.showInfoMessage("Generando etiquetas, por favor espere...");

      this.pdf_loading = true;

      try {
        // Espera el resultado de la generación de etiquetas
        const status = await this.tagsService.generateLabelsPDF(
          this.up2List,
          this.up4List,
          this.up16List,
          this.up80List,
          this.TagTemplate
        );

        if (status === 200) {
          this.pdf_loading = false;
          this.commonService.showSuccessMessage("Etiquetas generadas correctamente");
        } else {
          this.commonService.showErrorMessage("Error al generar etiquetas");
          this.pdf_loading = false;
        }
      } catch (error) {
        this.commonService.showErrorMessage("Ocurrió un error durante la generación de etiquetas");
        this.pdf_loading = false;
      }
    }
  
    //Buscar por ID
    OnSearchID(): void {
      if (!this.productId || this.quantityOption === null || this.quantityTags === 0 || !this.TagTemplate) {
        this.commonService.showWarningMessage("Por favor complete los campos");
        return;
      }
    
      this.loading = true;
    
      const ModuleName = "ashley"

      this.productProcess.SearchID(this.productId, this.quantityOption, this.quantityTags, ModuleName, this.TagTemplate).subscribe({
        next: () => {
          this.loading = false
          this.commonService.showSuccessMessage("Producto Agregado");
          // Limpiar campos
          this.productId = "";
          this.quantityOption = "";
          this.quantityTags = 1;
          this.TagTemplate = "";
          this.cd.detectChanges();
        },
        error: (error) => {
          this.loading = false;
          const message = error.status === 404
            ? "ID no encontrado"
            : "Ocurrió un error, inténtelo de nuevo";
          this.snackBar.open(message, "Cerrar", {
            duration: 3000,
            verticalPosition: "top",
          });
        }
      });
    }
    
    //Mostrar productos del arreglo
    show(listType: string) {
      let headerTitle: string = '';
    
      switch (listType) {
        case 'up2':
          headerTitle = 'Etiquetas Up 2';
          break
        case 'up4':
          headerTitle = 'Etiquetas Up 4';
          break;
        case 'up16':
          headerTitle = 'Etiquetas Up 16';
          break;
        case 'up80':
          headerTitle = 'Etiquetas Up 80';
          break;
        default:
          this.snackBar.open("Tipo de lista no válido", "Cerrar", {
            duration: 3000,
            verticalPosition: "top",
          });
          return;
      }

      this.ref = this.dialogService.open(TagsAshleyProductsComponent, {
        data: {
          listType: listType
        },
        header: headerTitle,
        width: '50%'
      });
    }
}
