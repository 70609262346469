import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from "@angular/core";
import { DynamicDialogRef, DynamicDialogConfig } from "primeng/dynamicdialog";
import { SalesService } from "../../../sales.service";
import { CommonService } from "src/app/shared/common.service";
import * as moment from "moment";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: "app-sales-clientes-note-edit-create",
  templateUrl: "./sales-clientes-note-edit-create.component.html",
  styleUrls: ["./sales-clientes-note-edit-create.component.css"],
})
export class SalesClientesNoteEditCreateComponent implements OnInit {

  private destroy$ = new Subject();

  note: any;
  IconEdit = faEdit;
  
  formatearFecha(fecha: string): string {
    return moment(fecha).locale('es').format('D [de] MMMM [de] YYYY [a las] HH:mm');
  }


  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public saleService: SalesService,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.note = this.config.data;
    this.getHistorialNota()
    //console.log("Note pre:", this.note);
  }

  getHistorialNota(){
    this.saleService.getHistorialNota(this.note.idNote).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.note.historial = response.msg.slice().reverse();
        //console.log(this.note)
      },(error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }

  onEditNote() {
    this.config.header = "Editar nota";
    this.note.status = "edit";
    this.note.oldmensaje = this.note.note;
    this.note.mensaje = this.note.note;
    this.note.folio = this.note.folio;
  }

  editNote() {
    let data = {
      idNote: this.note.idNote,
      nota: this.note.mensaje,
      oldnota: this.note.oldmensaje,
      folio: this.note.folio,
    };
    //console.log("Data:", data);
    this.saleService.updateNoteClient(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        //console.log("Response:", response);
        this.commonService.showSuccessMessage(response.msg);
        this.ref.close(data);
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }

  saveNote() {
    if (!this.note.mensaje || this.note.mensaje.trim().length === 0) {
      this.commonService.showErrorMessage("La nota no puede estar vacía");
      return;
    }
    if(this.note.folio === undefined){
      this.note.folio = 0
    }
    let data = {
      idCliente: this.note.idCliente,
      nota: this.note.mensaje,
      folio: this.note.folio,
    };
    this.saleService.saveNote(data).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg);
        this.ref.close(data);
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }
}
