import { Component, OnInit } from '@angular/core';
import { FormBuilder, NgModel, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../../../sales-address.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { faPlusCircle, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { field_types } from '../../../sales.enums';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseType, calle, casa, casa_residencial, colonia, residencia } from '../../../sales-search/address.enum';

@Component({
  selector: 'app-sales-clientes-address-local',
  templateUrl: './sales-clientes-address-local.component.html',
  styleUrls: ['./sales-clientes-address-local.component.css'],
})
export class SalesClientesAddressLocalComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  
  Field_types  = field_types
  addIcon = faPlusCircle
  subtractIcon = faTrashCan

  //Selector
  tipo_direccion: string = ''
  tipo_residencia : residencia = {id: 0, nombre: '', status: ''} 
  
  //BaseType
  BaseType: BaseType[] = []
  //Forms
  ColoniaForm = this.fb.group({})
  //Datos para los form
  Colonias : colonia[] = []
  Calles: calle[] = []
  Casas: casa[] = []
  Residencias: residencia[] = []
  CasasRecidenciales: casa_residencial[] = []

  //Filtros de los forms
  filteredColonias: colonia[] = [];
  filteredCalles: calle[] = [];
  filteredEntreCalles1: calle[] =[]
  filteredEntreCalles2: calle[] =[]
  filteredNumerosCasa: casa[] = [];
  filterCasaRecidencial: casa_residencial[] = []

  fields: any[] = []

  fieldsId = 0

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    public addresServices: SalesAddressService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.ColoniaForm = this.fb.group({
      Colonia:['', Validators.required],
      Calle:['', Validators.required],
      Numero_Casa:[''],
      EntreCalle1:[''],
      EntreCalle2: [''],
      Descripcion: ['']
    })
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //Recidencial 
  get fieldArray(){
    return Object.values(this.Field_types)
  }

  detectTipoDireccionChanges($event: string): void {
    if (this.tipo_direccion === 'Colonia') {
      this.loadColoniaData()
      this.clearRecidencialData()
    } else if(this.tipo_direccion === 'Residencial'){
      this.getRecidenciales()
      this.clearColoniaData()
    }
  }

  detectorResidencialChanges($event:any): void{
    this.fields = []
  }

  deleteField(field: any){
    this.fields = this.fields.filter((f)=> f.id !== field.id)
  }

  getRecidenciales() {
    this.addresServices.getResidenciales().pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.Residencias = res.filter((item: any) => item.status === "Activo");
      },
      (err) => {
        this.commonService.showErrorMessage(err);
      }
    );
  }  

  clearRecidencialData(){
    this.Residencias = []
    this.fields = []
  }

  addField(field: any) {
    const exists = this.fields.some(f => f.field === field);
    if (!exists) {
      this.fieldsId = this.fieldsId + 1;
      let id = this.fieldsId;
      this.fields.push({ field, id: id, value: '' });
    }else{
      this.commonService.showWarningMessage(`El campo ${field} ya esta agregado`)
    }
  }
  

  AddRecidencial(){
    if(this.fields.length === 0){
      return this.commonService.showWarningMessage(`Por lo menos debe agregar un campo a la dirección`)
    }

    for (let f of this.fields) {
      if(f.value === '') {
        this.commonService.showWarningMessage(`El campo ${f.field} no puede estar vacío`);
        return
      }
    }

    const data = {
      residencial: this.tipo_residencia,
      aggregates: this.fields,
      idCliente: this.config.data
    }   

    this.addresServices.addResidencialAddress(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) =>{ 
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close(true)
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  //Colonias
  loadColoniaData(){
    this.getColonias()
    this.getCalles()
    this.getCasas()

    this.ColoniaForm.get('Colonia')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredColonias = this._filter(value, this.Colonias);
    });
    this.ColoniaForm.get('Calle')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredCalles = this._filter(value, this.Calles);
    });
    this.ColoniaForm.get('EntreCalle1')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value=>{
      this.filteredEntreCalles1 = this._filter(value, this.Calles)
    })
    this.ColoniaForm.get('EntreCalle2')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value=>{
      this.filteredEntreCalles2 = this._filter(value, this.Calles)
    })
    this.ColoniaForm.get('Numero_Casa')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.filteredNumerosCasa = this._filter(value, this.Casas);
    });
  }

  getColonias(){
    this.addresServices.get("colonia").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.Colonias = res.filter((c: colonia )=> c.status === "Activo")
      }, (err) => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getCalles(){
    this.addresServices.get("calle").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) => {
        this.Calles = res.filter((c: calle)=> c.status === "Activo")
      }, (err) => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getCasas(){
    this.addresServices.get("casa").pipe(takeUntil(this.unsubscribe$)).subscribe(
      (res) =>{
        this.Casas = res.filter((c: casa)=> c.status === "Activo")
      }, (err) =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  clearColoniaData(){
    this.clearValues()
  }

  clearValues(){
    this.Colonias = []
    this.Calles = []
    this.Casas = []
    this.filteredColonias = [];
    this.filteredCalles = [];
    this.filteredEntreCalles1 =[]
    this.filteredEntreCalles2 =[]
    this.filteredNumerosCasa = [];
    this.ColoniaForm.reset({
      Colonia: '',
      Calle: '',
      Numero_Casa: '',
      EntreCalle1: '',
      EntreCalle2: '',
      Descripcion: ''
    });
    
  }

  _filter(value: string, options: BaseType[]): BaseType[] {
    //console.log("value",value, "option",options)
    const filterValue = value.toLowerCase();
    return options.filter(option => option.nombre.toLowerCase().includes(filterValue)); 
  }

  OnAddColoniaAddress() {
    if (this.ColoniaForm.valid) {
      const data = {
        Colonia: this.ColoniaForm.value.Colonia,
        Calle: this.ColoniaForm.value.Calle,
        Numero_Casa:this.ColoniaForm.value.Numero_Casa,
        EntreCalle1:this.ColoniaForm.value.EntreCalle1,
        EntreCalle2:this.ColoniaForm.value.EntreCalle2,
        Descripcion:this.ColoniaForm.value.Descripcion,
        idCliente: this.config.data 
      }
      this.addresServices.addColoniaAddress(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res)=>{
          this.commonService.showSuccessMessage(res.msg)
          this.ColoniaForm.reset({
            Colonia: '',
            Calle:'', 
            Numero_Casa:'',
            EntreCalle1:'',
            EntreCalle2: '',
            Descripcion: ''
          })
          this.ref.close(true)
        },(err)=>{
          this.commonService.showErrorMessage(err)
        }
      )
    }
  }
}
