import { Modules, User } from 'src/app/shared/common.enums';
import { UsersService } from 'src/app/mods/users/users.service';
import { SalesService } from '../sales.service';
import { Accion, assistant, followings, Intereces_Types, Referenced, Salles_Types, TipoClientes } from '../sales.interfaces';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesConfigMailTemplateComponent } from './sales-config-mails/sales-config-mail-template/sales-config-mail-template.component';
import { SalesConfigMailsComponent } from './sales-config-mails/sales-config-mails.component';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { SalesConfigMailsPromocionesComponent } from './sales-config-mails/sales-config-mails-promociones/sales-config-mails-promociones.component';


@Component({
  selector: 'app-sales-config',
  templateUrl: './sales-config.component.html',
  styleUrls: ['./sales-config.component.css']
})
export class SalesConfigComponent implements OnInit, OnDestroy {

  @ViewChild('tipoInteres', { static: false }) tipoInteresInput!: ElementRef;
  @ViewChild('tipoVenta', { static: false }) tipoVentaInput!: ElementRef;
  @ViewChild('referenciado', { static: false }) referenciadoInput!: ElementRef;
  @ViewChild('nombreSeguimiento', { static: false }) seguimientoInput!: ElementRef;
  @ViewChild('duracionSeguimiento', { static: false }) duracionInput!: ElementRef;
  @ViewChild('asistente', { static: false }) asistenteInput!: ElementRef;
  @ViewChild('accion', { static: false }) accionInput!: ElementRef;
  @ViewChild('Tipocliente', { static: false }) TipoclienteInput!: ElementRef;
  
  private destroy$ = new Subject<void>();

  faConfig = faToolbox;

  selectedSeguimiento: number = 0;
  asistentes : assistant[] = [];
  seguimientos: followings[] = [];
  Referenciados: Referenced[] = [];
  Tipos_de_ventas: Salles_Types[] = [];
  Tipos_de_interes: Intereces_Types[] = [];
  frecuencia: any[] = [];
  usuarios: User[] = [];
  acciones: Accion[] = [];
  TiposCliente: TipoClientes[] = [];
  AsistForm: FormGroup = this.fb.group({})

  editSeguimientoId : number = 0;
  editInteresId : number = 0;
  editReferenciadoId : number = 0;
  editVentaId : number = 0;
  editAsistenteId: number = 0;
  editFrecuenciaId: number = 0;
  editAccionId: number = 0;
  editTipoClienteId: number = 0;

  tempName: string = '';
  tempVar: number = 0;

  filteredSeguimientos: followings[] = []
  constructor(
    private salesServices: SalesService,
    public userService: UsersService,
    public commonSerice: CommonService,
    private fb: FormBuilder,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.getReferenciados()
    this.getSeguimientos()
    this.getAsistentes()
    this.getTiposDeVentas()
    this.getTiposDeInteres()
    this.getUsuarios()
    this.getAccion()
    this.getTiposDeCliente()
    this.AsistForm = this.fb.group({
      usuario: [''],
      numero: ['']
  })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //Tablas
  
  getReferenciados(){
    this.salesServices.getReferenciados().pipe(takeUntil(this.destroy$)).subscribe(
      (Referenciados) => {
        this.Referenciados = Referenciados;
      })
  }
  
  getSeguimientos(){
    this.salesServices.getSeguimientos().pipe(takeUntil(this.destroy$)).subscribe(
      (seguimientos) => {
        this.seguimientos = seguimientos;
        this.filteredSeguimientos = this.seguimientos.filter(seg => seg.status === 'Activo');
      })
  }

  getAsistentes(){
    this.salesServices.getAsistentes().pipe(takeUntil(this.destroy$)).subscribe(
      (asistentes) => {
        this.asistentes = asistentes;
        //console.log(this.asistentes)
      })
  }

  getTiposDeVentas(){
    this.salesServices.getTiposDeVentas().pipe(takeUntil(this.destroy$)).subscribe(
      (Tipos_de_ventas) => {
        this.Tipos_de_ventas = Tipos_de_ventas;
      })
  }

  getTiposDeInteres(){
    this.salesServices.getTiposDeInteres().pipe(takeUntil(this.destroy$)).subscribe(
      (Tipos_de_interes) => {
        this.Tipos_de_interes = Tipos_de_interes;
      })
  }

  getUsuarios(){
    this.userService.getUsers().pipe(takeUntil(this.destroy$)).subscribe(
      (usuarios) => {
        this.usuarios = usuarios;
      })
  }

  getFrecuenciaBySeguimiento(Id: number){
    this.salesServices.getFrecuenciaBySeguimiento(Id).pipe(takeUntil(this.destroy$)).subscribe(
      (frecuencia) => {
        this.frecuencia = frecuencia.msg;
        //console.log(this.frecuencia)
      })
  }

  getAccion(){
    this.salesServices.getAccion().pipe(takeUntil(this.destroy$)).subscribe(
      (acciones) => {
        this.acciones = acciones;
        //console.log(this.acciones)
      })
  }

  getTiposDeCliente(){
    this.salesServices.getTiposDeCliente().pipe(takeUntil(this.destroy$)).subscribe(
      (TiposCliente) => {
        this.TiposCliente = TiposCliente;
       //console.log(this.TiposCliente)  
      })
  }

  // Seguimientos
  AgregarSeguimiento(nombre:string, duracion: number){
    //console.log(nombre, duracion)
    if (!nombre.trim() && !duracion) {
      this.commonSerice.showErrorMessage('Los campos de seguimiento no pueden estar vacío');
      return; 
    }
    const data = {
      seguimiento: nombre,
      duracion: duracion,
      status: "Activo"
    }
    this.salesServices.AgregarSeguimiento(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.seguimientoInput.nativeElement.value = '';
        this.duracionInput.nativeElement.value = '';
        this.getSeguimientos();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    );
  }

  editarSeguimiento(values: any) {
    this.tempName = '';
    this.editSeguimientoId = values.id;
    this.tempName = values.seguimiento;
  }

  onSeguimientoChange($event: number){
    this.getFrecuenciaBySeguimiento($event)
  }

  guardarSeguimiento(values: any) {
    if (!values.seguimiento.trim()) {
      this.commonSerice.showErrorMessage('El nombre del seguimiento no puede estar vacío');
      return;
    }
   /*  if (values.seguimiento === this.tempName && values.duracion === this.tempVar) {
      this.commonSerice.showErrorMessage('El seguimiento no puede ser igual al anterior');
      return;
    } */
  
    const data = {
      id: values.id,
      seguimiento: values.seguimiento,
    };
  
    this.salesServices.editarSeguimiento(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editSeguimientoId = 0; 
        this.getSeguimientos();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    );
  }

  cancelarEdicionSeguimientos(){
    this.editSeguimientoId = 0;
    this.getSeguimientos();
    this.tempName = '';
  }

  eliminarSeguimiento(id: number){ 
    let IdSeguimiento = id;
    this.salesServices.eliminarSeguimiento(IdSeguimiento).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getSeguimientos();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  activarSeguimiento(id: number){
    let IdSeguimiento = id;
    this.salesServices.ActivarSeguimiento(IdSeguimiento).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg)
        this.getSeguimientos();
      },err=>{
        this.commonSerice.showErrorMessage(err)
      }
    )
  }

  //Intereses 
  
  AgregarInteres(value: string){

    if (!value.trim()) {
      this.commonSerice.showErrorMessage('El campo de interés no puede estar vacío');
      return; 
    }

      const data = {
        tipoInteres : value,
      };
      this.salesServices.AgregarInteres(data).pipe(takeUntil(this.destroy$)).subscribe(
        res =>{
          this.commonSerice.showSuccessMessage(res.msg);
          this.tipoInteresInput.nativeElement.value = '';
          this.getTiposDeInteres();
        },
        err => {
          this.commonSerice.showErrorMessage(err);
        }
      )
  }

  editarInteres(values: any){
    this.tempName = '';
    this.editInteresId = values.id;
    this.tempName = values.tipoInteres;
  }

  guardarInteres(values: any){
    if (!values.tipoInteres.trim()) {
      this.commonSerice.showErrorMessage('El interés no puede estar vacío');
      return;
    }
    if (values.tipoInteres === this.tempName) {
      this.commonSerice.showErrorMessage('El interés no puede ser igual al anterior');
      return;
    }

    const data = {
      id: values.id,
      tipoInteres: values.tipoInteres,
    }

    this.salesServices.editarInteres(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editInteresId = 0;
        this.getTiposDeInteres();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  cancelarEdicionInteres(){
    this.editInteresId = 0
    this.getTiposDeInteres()
    this.tempName = '';
  }

  eliminarInteres(id: number){
    let IdInteres = id;
    this.salesServices.eliminarInteres(IdInteres).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeInteres();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  activarInteres(id: number){
    let IdInteres = id;
    this.salesServices.ActivarInteres(IdInteres).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeInteres();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  //Referenciados

  AgregarReferenciado(value: string){
    if (!value.trim()) {
      this.commonSerice.showErrorMessage('El campo de referenciado no puede estar vacío');
      return; 
    }
    const data = {
      nombre: value,
    }
    this.salesServices.AgregarReferenciado(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.referenciadoInput.nativeElement.value = '';
        this.getReferenciados();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    );
  }

  editReferenciado(values: any){
    this.tempName = '';
    this.editReferenciadoId = values.id;
    this.tempName = values.nombre;
  }

  guardarReferenciado(values: any){
   if(!values.nombre.trim()){
      this.commonSerice.showErrorMessage('El nombre no puede estar vacío');
      return;
    }
    if(values.nombre === this.tempName){
      this.commonSerice.showErrorMessage('El nombre no puede ser igual al anterior');
      return;
    }
    
    const data = {
      id: values.id,
      nombre: values.nombre,
    }

    this.salesServices.editarReferenciado(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editReferenciadoId = 0;
        this.getReferenciados();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  cancelarEdicionReferenciado(){
    this.editReferenciadoId = 0
    this.getReferenciados()
    this.tempName = '';
  }

  eliminarReferenciado(id: number){
   let IdReferenciado = id;
    this.salesServices.eliminarReferenciado(IdReferenciado).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getReferenciados();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }) 
  }

  ActivarReferenciado(id: number){
    let IdReferenciado = id;
    this.salesServices.ActivarReferenciado(IdReferenciado).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getReferenciados();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }) 
  }

  //Tipos de ventas
  AgregarVentas(value: string){
    if (!value.trim()) {
      this.commonSerice.showErrorMessage('El campo de venta no puede estar vacío');
      return; 
    }

    const data = {
      tipoVenta : value,
    }
    this.salesServices.AgregarVentas(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.tipoVentaInput.nativeElement.value = '';
        this.getTiposDeVentas();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    );
  }

  editarVenta(values: any){
    this.tempName = '';
    this.editVentaId = values.id;
    this.tempName = values.tipoVenta;
  }

  guardarVenta(values: any){
    if (!values.tipoVenta.trim()) {
      this.commonSerice.showErrorMessage('El tipo de venta no puede estar vacío');
      return;
    }
    if (values.tipoVenta === this.tempName) {
      this.commonSerice.showErrorMessage('El tipo de venta no puede ser igual al anterior');
      return;
    }

    const data = {
      id: values.id,
      tipoVenta: values.tipoVenta,
    }

    this.salesServices.editarVenta(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editVentaId = 0;
        this.getTiposDeVentas();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  cancelarEdicionVenta(){
    this.editVentaId = 0
    this.getTiposDeVentas()
    this.tempName = '';
  }

  eliminarVenta(id: number){
    //console.log(id)
    let idVenta = id;
    this.salesServices.eliminarVenta(idVenta).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeVentas();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  activarVenta(id: number){
    let idVenta = id;
    this.salesServices.ActivarVenta(idVenta).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeVentas();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  //Asistentes
  AgregarAsistente(){
    //console.log(this.AsistForm.value)
    const data = {
      Id_usuario: this.AsistForm.value.usuario,
      numero: this.AsistForm.value.numero,
      nombre: this.userService.users.find(user => user.id === this.AsistForm.value.usuario)?.name,
    }
    this.salesServices.AgregarAsistente(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.getAsistentes();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    );
  }

  editarFrecuencia(data: any){
    this.tempVar = 0;
    this.editFrecuenciaId = data.id;
    this.tempVar = data.frecuencia;
  }

  guardarFrecuencia(values: any){
    const data = {
      id : values.id,
      frecuencia : values.frecuencia,
    }
    this.salesServices.editarFrecuencia(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editFrecuenciaId = 0;
        this.getFrecuenciaBySeguimiento(this.selectedSeguimiento);
        this.tempVar = 0;
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      });
  }

  cancelarFrecuencia(){
    this.editFrecuenciaId = 0;
    this.tempVar = 0;
  }

  ConfiguracionTemplate(seguimiento: any){
    this.dialogService.open(SalesConfigMailsComponent, {
      header: `Template's del seguimiento ${seguimiento.seguimiento}`,
      width: '70%',
      data: seguimiento
    })
  }

  guardarAsistente(values: any){
    //console.log(values)
    if (values.Usuario === this.tempName) {
      this.commonSerice.showErrorMessage('El usuario no puede ser el mismo');
      return;
    }
    let data = {
      idUsuario:values.Usuario,
      idAsistente:values.idAsistans,
    }
    //console.log(data)
    this.salesServices.editarAsistente(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editAsistenteId = 0;
        this.getAsistentes();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      });
  }

  editarAsistente(values: any){
    //console.log(values) 
    this.tempName = '';
    this.editAsistenteId = values.idAsistans;
    this.tempName = values.Usuario;
  }

  cancelarEdicionAsistente(){
    this.editAsistenteId = 0
    this.getAsistentes()
    this.tempName = '';
  }

  eliminarAsistente(id: number){
   //console.log(id)
   let idAsistente = id;
   this.salesServices.eliminarAsistente(idAsistente).pipe(takeUntil(this.destroy$)).subscribe(
     res => {
       this.commonSerice.showSuccessMessage(res.msg);
       this.getAsistentes();
     },
     err => {
       this.commonSerice.showErrorMessage(err);
    })
  }

  //Acciones
  AgregarAccion(value: string){
    
    if (!value.trim()) {
      this.commonSerice.showErrorMessage('El campo de acción no puede estar vacío');
      return; 
    }

    const data = {
      tipoAccion : value
    };
    this.salesServices.AgregarAccion(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.accionInput.nativeElement.value = '';
        this.getAccion();
      },err =>{
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  editAccion(values: any){
    this.tempName = '';
    this.editAccionId = values.id;
    this.tempName = values.accion;
  }

  guardarAccion(values: any){
    if (!values.accion.trim()) {
      this.commonSerice.showErrorMessage('El tipo de acción no puede estar vacío');
      return;
    }
    if (values.accion === this.tempName) {
      this.commonSerice.showErrorMessage('El tipo de acción no puede ser igual al anterior');
      return;
    }

    const data = {
      id: values.id,
      accion: values.accion,
    }

    this.salesServices.editarAccion(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editAccionId = 0;
        this.getAccion();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
    })
}

  cancelarEdicionAccion(){
    this.editAccionId = 0
    this.getAccion()
    this.tempName = '';
  }

  eliminarAccion(id: number){
    let idAccion = id;
    this.salesServices.eliminarAccion(idAccion).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getAccion();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  ActivarAccion(id: number){
    let idAccion = id;
    this.salesServices.ActivarAccion(idAccion).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getAccion();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      })
  }

  //Tipos de clientes

  AgregarTipoCliente(value: string){
    if (!value.trim()) {
      this.commonSerice.showErrorMessage('El campo de tipo de cliente no puede estar vacío');
      return; 
    }

    const data = {
      tipoCliente : value
    };
    this.salesServices.AgregarTipoCliente(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonSerice.showSuccessMessage(res.msg);
        this.TipoclienteInput.nativeElement.value = '';
        this.getTiposDeCliente();
      },err =>{
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  editTipoCliente(values: any){
    this.tempName = '';
    this.editTipoClienteId = values.id;
    this.tempName = values.tipoCliente;
  }

  guardarTipoCliente(values: any){
    if (!values.tipoCliente.trim()) {
      this.commonSerice.showErrorMessage('El tipo de cliente no puede estar vacío');
      return;
    }
    if (values.tipoCliente === this.tempName) {
      this.commonSerice.showErrorMessage('El tipo de cliente no puede ser igual al anterior');
      return;
    }

    const data = {
      id: values.id,
      tipoCliente: values.tipoCliente,
    }

    this.salesServices.editarTipoCliente(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.editTipoClienteId = 0;
        this.getTiposDeCliente();
        this.tempName = '';
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  cancelarEdicionTipoCliente(){
    this.editTipoClienteId = 0
    this.getTiposDeCliente()
    this.tempName = '';
  }

  eliminarTipoCliente(id: number){
    let idTipoCliente = id;
    this.salesServices.eliminarTipoCliente(idTipoCliente).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeCliente();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  ActivarTipoCliente(id: number){
    let idTipoCliente = id;
    this.salesServices.ActivarTipoCliente(idTipoCliente).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonSerice.showSuccessMessage(res.msg);
        this.getTiposDeCliente();
      },
      err => {
        this.commonSerice.showErrorMessage(err);
      }
    )
  }

  VerPromociones(){
    this.dialogService.open(SalesConfigMailsPromocionesComponent, {
      header: `Promociones`,
      width: '70%',
    })
  }
}