
import { BaseType } from './sales-search/address.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesAddressService {

  constructor(
    private http: HttpClient,
    public commonService: CommonService
  ) { }

  get(tipo: string):Observable<any>{
    return this.http.get<BaseType>(`${environment.endpoint}ventas/addresses/get/${tipo}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  add(tipo:string, data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/add/${tipo}`, {data}).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  update(tipo:string, data:any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/update/${tipo}`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //villas
  getVillas():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getVillas`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addVilla(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addVilla`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editVilla(data:any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/editVilla`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //casas
  getCasas():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getCasas`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addCasa(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addCasa`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editCasa(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/editCasa`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
  
  //Edificios
  getEdificios():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getEdificios`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addEdificio(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addEdificio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editEdificio(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/editEdificio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  statusEdificio(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/statusEdificio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Condominios
  getCondominios():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getCondominios`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addCondominio(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addCondominio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editCondominio(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/editCondominio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  statusCondominio(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/statusCondominio`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Address 

  getAddresses():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getAddresses`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getAddressClientes(id: number):Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getAddressClientes/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  AddAddressToCliente(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/AddAddressToCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  DeleteAddressToCliente(data: any):Observable<any>{
    return this.http.delete<any>(`${environment.endpoint}ventas/addresses/DeleteAddressToCliente/${data.idCliente}/${data.idAddress}`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  updateCliente(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/updateCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    ) 
  }

  addAddresDesarrollo(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addDesarrolloToCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addDireccionLocal(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addLocalAddress`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addResidencialAddress(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addResidencialAddress`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addVillaToCliente(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addVillaToCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addCasaToCliente(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addCasaToCliente`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
  //Residencial
  getResidenciales():Observable<any>{
    return this.http.get<any>(`${environment.endpoint}ventas/addresses/getResidenciales`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  addResidecial(data: any):Observable<any>{
    return this.http.post<any>(`${environment.endpoint}ventas/addresses/addResidencial`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  editResidencia(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/editResidencia`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  statusResidencia(data: any):Observable<any>{
    return this.http.put<any>(`${environment.endpoint}ventas/addresses/statusResidencial`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Locales
  addColoniaAddress(data: any):Observable<any>{
    return this.http.post(`${environment.endpoint}ventas/addresses/addColoniaAddress`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  //Foraneas 
  addForaneaAddress(data: any):Observable<any>{
    return this.http.post(`${environment.endpoint}ventas/addresses/addForaneaAddress`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }


  //Personalizadas 
  addPersonalizadaAddress(data: any):Observable<any>{
    return this.http.post(`${environment.endpoint}ventas/addresses/addPersonalizadaAddress`, data).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }

  getClienteAddresses(id: number):Observable<any>{
    return this.http.get(`${environment.endpoint}ventas/addresses/getAddressesCliente/${id}`).pipe(
      catchError(this.commonService.handleRequestError)
    )
  }
}