import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArqueoService } from './arqueo.service';
import { RouterModule, Routes } from '@angular/router';
import { ArqueoComponent } from './arqueo.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArqueoSalesComponent } from './arqueo-sales/arqueo.sales.component';
import { ArqueoPositiveBalanceComponent } from './saldo-favor/arqueo.saldo.favor.component';
import { ArqueoClientesComponent } from './arqueo-clientes/arqueo-clientes.component';
import { ClientsImportComponent } from './arqueo-clientes/clients-import/clients-import.component';
import { AddClientComponent } from './arqueo-clientes/add-client/add-client.component';
import { SaldoAddComponent } from './saldo-favor/saldo-add/saldo-add.component';
import { ArqueoFondoFijoComponent } from './fondo-fijo/fondo-fijo.component';
import { ArqueoAccumulatedComponent } from './arqueo-accumulated/arqueo-accumulated.component';
import { ArqueoCatalogosComponent } from './arqueo-catalogos/arqueo-catalogos.component';

export const arqueoRoutes: Routes = [
  {path: "arqueo", canActivate: [AuthGuard], component: ArqueoComponent, children: [
    {path: "ventas", canActivate: [AuthGuard], component: ArqueoSalesComponent},
    {path: "saldo-a-favor", canActivate: [AuthGuard], component: ArqueoPositiveBalanceComponent},
    {path: "clientes", canActivate: [AuthGuard], component: ArqueoClientesComponent},
    {path: "fondo-fijo", canActivate: [AuthGuard], component: ArqueoFondoFijoComponent},
    {path: "acumulados", canActivate: [AuthGuard], component: ArqueoAccumulatedComponent},
    {path: "catalogos", canActivate: [AuthGuard], component: ArqueoCatalogosComponent}
  ]}
];

@NgModule({
  declarations: [
    ArqueoComponent,
    ArqueoSalesComponent,
    ArqueoPositiveBalanceComponent,
    ArqueoClientesComponent,
    ClientsImportComponent,
    AddClientComponent,
    SaldoAddComponent,
    ArqueoFondoFijoComponent,
    ArqueoAccumulatedComponent,
    ArqueoCatalogosComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule
  ],
  exports: [],
  providers: [
    ArqueoService
  ],
})
export class ArqueoModule {}