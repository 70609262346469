import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumKey'
})

export class EnumKeyPipe implements PipeTransform {
  transform(value: any, enumm: any): any {
    const indexOfS = Object.values(enumm).indexOf(value as any);
    const key = Object.keys(enumm)[indexOfS];
    return key;
  }
}
