import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SalesComponent } from './sales.component';
import { SalesClientesComponent } from './sales-clientes/sales-clientes.component';
import { ButtonModule } from 'primeng/button';
import { SalesService } from './sales.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SalesClientesViewComponent } from './sales-clientes/sales-clientes-view/sales-clientes-view.component';
import { SalesClientesAddressComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address.component';
import { SalesClientesNoteEditCreateComponent } from './sales-clientes/sales-clientes-view/sales-clientes-note-edit-create/sales-clientes-note-edit-create.component';
import { SalesClientesNoteDeleteComponent } from './sales-clientes/sales-clientes-view/sales-clientes-note-delete/sales-clientes-note-delete.component';
import { SalesConfigComponent } from './sales-config/sales-config.component';
import { SalesClientesViewFollowingComponent } from './sales-clientes/sales-clientes-view/sales-clientes-view-following/sales-clientes-view-following.component';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { SalesReportsComponent } from './sales-reports/sales-reports.component';
import { ComingSoonComponent } from 'src/app/coming-soon/coming-soon.component';
import { SalesClientesUtilComponent } from './sales-clientes/sales-clientes-util/sales-clientes-util.component';
import { SalesChatsComponent } from './sales-chats/sales-chats.component';
import { SalesChatsWhatsappComponent } from './sales-chats/sales-chats-whatsapp/sales-chats-whatsapp.component';
import { SalesChatsGmailComponent } from './sales-chats/sales-chats-gmail/sales-chats-gmail.component';
import { SalesConfigMailsComponent } from './sales-config/sales-config-mails/sales-config-mails.component';
import { SalesConfigMailTemplateComponent } from './sales-config/sales-config-mails/sales-config-mail-template/sales-config-mail-template.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditorModule } from 'primeng/editor';
import { SalesReportsViewMailComponent } from './sales-reports/sales-reports-view-mail/sales-reports-view-mail.component';
import { SalesClientesCreateComponent } from './sales-clientes/sales-clientes-create/sales-clientes-create.component';
import { SalesClientesEditComponent } from './sales-clientes/sales-clientes-edit/sales-clientes-edit.component';
import { SalesClientesAddressDesarrolloComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address-desarrollo/sales-clientes-address-desarrollo.component';
import { SalesClientesAddressPersonalizadaComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address-personalizada/sales-clientes-address-personalizada.component';
import { SalesClientesAddressLocalComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address-local/sales-clientes-address-local.component';
import { SalesClientesAddressForaneaComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address-foranea/sales-clientes-address-foranea.component';
import { SalesClientesAddressViewClientesComponent } from './sales-clientes/sales-clientes-address/sales-clientes-address-view-clientes/sales-clientes-address-view-clientes.component';
import { SalesDireccionesComponent } from './sales-direcciones/sales-direcciones.component'
import { SalesConfigAddressComponent } from './sales-config-address/sales-config-address.component'
import { SalesConfigAddressColoniasComponent } from './sales-config-address/sales-config-address-colonias/sales-config-address-colonias.component'
import { SalesConfigAddressCondominiosComponent } from './sales-config-address/sales-config-address-condominios/sales-config-address-condominios.component'
import { SalesConfigAddressRecidencialesComponent } from './sales-config-address/sales-config-address-recidenciales/sales-config-address-recidenciales.component';
import { SalesSearchComponent } from './sales-search/sales-search.component'
import { MatMenuModule } from '@angular/material/menu';
import { SalesConfigMailsPromocionesComponent } from './sales-config/sales-config-mails/sales-config-mails-promociones/sales-config-mails-promociones.component';
import { SalesChatsViewComponent } from './sales-chats/sales-chats-view/sales-chats-view.component';
import { SalesChatsGmailCreateComponent } from './sales-chats/sales-chats-gmail/sales-chats-gmail-create/sales-chats-gmail-create.component';

export const salesRoutes : Routes =[
  {path: 'ventas', canActivate: [AuthGuard], component: SalesComponent, children: [
      {path: 'clientes', canActivate: [AuthGuard], component: SalesClientesComponent},
      {path: 'search', canActivate: [AuthGuard], component: SalesSearchComponent},
      {path: 'direcciones', canActivate: [AuthGuard], component: ComingSoonComponent},
      {path: 'chats', canActivate: [AuthGuard], component: SalesChatsComponent},
      {path: 'config', canActivate: [AuthGuard], component: SalesConfigComponent},
      {path: 'reportes', canActivate: [AuthGuard], component: SalesReportsComponent},
      {path: 'venta', canActivate: [AuthGuard], component: ComingSoonComponent},
      {path: 'facturas', canActivate: [AuthGuard], component: ComingSoonComponent},
      {path: 'configAddress', canActivate: [AuthGuard], component: SalesConfigAddressComponent}
    ]
  }
];

@NgModule({
  declarations: [
    SalesClientesComponent,
    SalesClientesViewComponent,
    SalesClientesCreateComponent,
    SalesClientesAddressComponent,
    SalesClientesNoteEditCreateComponent,
    SalesClientesNoteDeleteComponent,
    SalesConfigComponent,
    SalesClientesViewFollowingComponent,
    SalesReportsComponent,
    SalesClientesUtilComponent,
    SalesChatsComponent,
    SalesChatsWhatsappComponent,
    SalesChatsGmailComponent,
    SalesConfigMailTemplateComponent,
    SalesConfigMailsComponent,
    SalesReportsViewMailComponent,
    SalesClientesEditComponent,
    SalesClientesAddressDesarrolloComponent,
    SalesClientesAddressPersonalizadaComponent,
    SalesClientesAddressLocalComponent,
    SalesClientesAddressForaneaComponent,
    SalesClientesAddressViewClientesComponent,
    SalesDireccionesComponent,
    SalesConfigAddressComponent,
    SalesConfigAddressColoniasComponent,
    SalesConfigAddressCondominiosComponent,
    SalesConfigAddressRecidencialesComponent,
    SalesSearchComponent,
    SalesConfigMailsPromocionesComponent,
    SalesChatsViewComponent,
    SalesChatsGmailCreateComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ButtonModule,
    MatTableModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    EditorModule,
    MatMenuModule
  ],
  exports: [],
  providers: [SalesService],
})
export class SalesModule { }
