import { AuthService } from './../../auth/auth.service';
import { CommonService } from './../../shared/common.service';
import { MessageService } from 'primeng/api';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css', '../../shared/scss/structure.columns.scss'],
  providers: [MessageService]
})
export class TagsComponent implements OnInit {
  
  fullSize = false
  showTools = false
  viewSettings ={
    menuCollapsed: false
  }
  featuresAcces = {
    ashley: false,
    hds: false,
    sleep: false,
    outlet: false
  }
 
  constructor(
    public alertService: AlertService,
    private router : Router,
    private messageService: MessageService,
    private commonService: CommonService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.commonService.fullSize$.subscribe(fullSize => {this.fullSize = fullSize})

    this.setModuleAcces();
    this.redirectToModulePage();  
  }

  setModuleAcces(){
    const access = this.authService.user.value?.access || []
    this.featuresAcces.ashley = access.includes('tags:ashley:view')
    this.featuresAcces.hds = access.includes('tags:hds:view')
    this.featuresAcces.outlet = access.includes('tags:outlet:view')
    this.featuresAcces.sleep = access.includes('tags:sleep:view')
  }

  redirectToModulePage(){
    if (this.featuresAcces.ashley){
      this.router.navigate(['/tags/Ashley'])
    }else if (this.featuresAcces.hds){
      this.router.navigate(['/tags/hds'])
    }else if (this.featuresAcces.outlet){
      this.router.navigate(['/tags/outlet'])
    }else if (this.featuresAcces.sleep){
      this.router.navigate(['/tags/sleep'])
    }else{
      this.router.navigate(['/tags'])
    }
  }

  expandCollapseSubmenu(){
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }



  ngOnDestroy(): void {
  }
}
