import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { HistoryRecord } from 'src/app/shared/common.enums';
import { PricesService } from '../prices.service';
import { CommonService } from 'src/app/shared/common.service';
import { Subject } from 'rxjs';
import { faHistory, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'primeng/dynamicdialog';
import { PricesViewDataComponent } from '../prices-view-data/prices-view-data.component';

@Component({
  selector: 'app-prices-history',
  templateUrl: './prices-history.component.html',
  styleUrls: ['./prices-history.component.css']
})
export class PricesHistoryComponent implements OnInit {
  private destroy$ = new Subject();
  
  loading = false;
  historyIcon = faHistory;
  faSpinner = faSpinner;
  history: HistoryRecord[] = []

  constructor(
    private pricesService: PricesService,
    private commonService: CommonService,
    private dialogService: DialogService
    
  ) { }

  ngOnInit(): void {
    this.getHistory()
  }

  getHistory(){
    this.pricesService.getPrices_history().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        //console.log(res)
        this.history = res
      },err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  view_data(data: any){
    this.dialogService.open(PricesViewDataComponent,{
      data: data  
    })
  }
}
