import { Component, OnDestroy, OnInit } from '@angular/core';
import { faEdit, faMoneyBillWave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';
import { ArqueoService } from '../arqueo.service';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

//import * as _moment from 'moment';
import { 
  InvoiceStatus, 
  InvoiceStatusLabel, 
  TableTotals, 
  Sale, 
  SalesSum, 
  Stores, 
  Transaction, 
  ExchangeRate,
  Item,
  Customer,
  SaldoTransaction,
  SaldoType,
  InvoiceStatuses,
  SaleSTypes,
  SaldoOrigin,
  Store
} from 'src/app/shared/common.enums';
import { map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { of, Subscription } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sales',
  templateUrl: './arqueo.sales.component.html',
  styleUrls: ['./arqueo.sales.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class ArqueoSalesComponent implements OnInit, OnDestroy {

  confirmationBox = {
    title: "Confirmación Requerida",
    defaultFocus: "reject",
    icon: "", //"pi pi-exclamation-triangle"
  }

  loading = false;
  reportGenerated = false;
  salesIcon = faMoneyBillWave;
  loadingIcon = faSpinner;
  editIcon = faEdit;
  showPublicNote = false;
  publicNote = "";
  stores = [...Stores];
  stypes = [...SaleSTypes];
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };
  invoiceStatus = InvoiceStatus;
  invoiceStatuses = InvoiceStatuses;
  invoiceStatusLabels = InvoiceStatusLabel;
  salesSum: SalesSum = {
    shipping: 0,
    tax: 0,
    net: 0,
    advance: 0,
    cxc: 0,
    discount: 0,
    saf: 0,
    scommission: 0,
    contract: 0,
    gcnc: 0,
    services: 0
  };
  salesPersons: {[key: string]: number} = {};
  dayPayments: Transaction[] = [];
  sameDayPayments: Transaction[] = [];
  paymentsTotals: TableTotals[] = [];
  promoTransactions: Transaction[] = [];
  salesTotals: {[key:string]: {[key: string]: any}} = {
    "sales": {label: 'Ventas', total: 0},
    "cxc": {label: 'Cobranza', total: 0},
    "sum": {label: 'Suma', total: 0}
  };
  cxcSum: number = 0;
  cxcSumGC: number = 0;
  cxcFromDay: number = 0;
  dayPaymentsSum: number = 0;
  sameDayPaymentsSum: number = 0;
  promoSum: number = 0;
  promoSumCXC: number = 0;
  corteCaja: {[key: string]: any}[] = [];
  corteSum: number = 0;
  corteConciliacion: number = 0;
  saldoTransactionsActiveSales: {[key: number]: SaldoTransaction[]} = {};
  pdfLoading = false;

  //Exchange rate
  exchangeRate?: ExchangeRate;
  exchangeInput = "";
  exchangeModifyInput = "";
  exchangeSaving = false;
  showEditExchangeRate = false;

  //SAF
  saf_deposits: {[key: string]: Transaction[]} = {};
  saf_deposits_transactions: Transaction[] = [];

  aliveSubscription?: Subscription;

  constructor(
    public arqueoService: ArqueoService, 
    public commonService: CommonService, 
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    //this.filters.date.setValue('2022-07-30');
    //this.filters.store = 'hds';
    //this.filters.date.setValue('2022-06-16');
    this.filters.store = this.commonService.currentStore$.getValue();
    this.onSearchSales();
    //this.commonService.fullSize$.next(true);
  }

  getSales() {
    if (!this.loading) {
      this.resetVariables();
      this.filters.date.disable();
      this.loading = true;
      this.aliveSubscription = this.arqueoService.getSalesFromFilters(this.filters).pipe(
        tap(sales => {
          // recalculate total when sale includes taxes
          this.arqueoService.sales = this.recalculateTotalWithTaxes(sales);
        }),
        switchMap(() => {
          //Get GC / VC sales from SAF
          return this.arqueoService.getSalesFromSAF(this.filters);
        }),
        tap((SAF: Sale[]) => {
          if (SAF.length > 0) {
            // for (let sale of SAF) {
            //   this.arqueoService.sales.push(sale);
            // }
            //this.arqueoService.sales.push(...SAF);
          }
        }),
        //map(sales => sales.map((sale: { id_customer: string; }) => sale.id_customer)),
        switchMap(() => {
          // GET sales customers
          const customers_ids = this.arqueoService.sales.map((sale: Sale) => sale.id_customer);
          return this.arqueoService.getCustomers(customers_ids)
        }),
        tap(customers => {
          // SET sales customers
          this.setSalesCustomers(customers);
        }),
        switchMap(() => {
          // GET sales items
          const sales_ids = this.arqueoService.sales.map((sale: {id_sb: string}) => sale.id_sb);
          return this.arqueoService.getSalesItems(sales_ids);
        }),
        tap(items => {
          // SET sales items, calculate discount
          this.setSalesItems(items);
        }),
        switchMap(() => {
          // GET sales transactions
          const sales_ids = this.arqueoService.sales.map((sale: {id_sb: string}) => sale.id_sb);
          return this.arqueoService.getSalesTransactions(sales_ids);
        }),
        tap(transactions => {
          // SET sales transactions, calculate cxc and paid in advance
          this.setSalesTransactions(transactions);
        }),
        switchMap(() => {
          const cancelled_invoices = this.arqueoService.sales.filter(sale => sale.status === 15).map(sale => sale.invoice);
          if (cancelled_invoices.length > 0) {
            return this.arqueoService.getSaldoTransactionsFromInvoices(cancelled_invoices);
          }
          return of([]);
        }),
        tap(transactions => {
          //console.log(`saldoTransactionsFromInvoices`, transactions);
          this.setSaldoToCancelledInvoices(transactions);
        }),
        switchMap(() => {
          const sb_ids = this.arqueoService.sales.filter(sale => sale.status !== 15).map(sale => sale.invoice);
          return this.arqueoService.getSaldoTransactionsFromInvoices(sb_ids);
        }),
        tap(transactions => {
          this.setSaldoTransactions(transactions);
        }),
        switchMap(() => {
          // GET all sales transactions on selected day
          return this.arqueoService.getSalesTransactions(null, this.filters.dateFormated)
        }),
        map(transactions => {
          // Filter to get only CXC transactions (transacciones de cobranza)
          const sales_ids = this.arqueoService.sales.map((sale: {id_sb: string}) => sale.id_sb);
          const cxc_transactions = transactions.filter((transaction: any) => {
            return !sales_ids.includes(transaction.sale_id) && transaction.amount !== '0'
          });
          return cxc_transactions;
        }),
        tap(cxc_transactions => {
          // SET CXC transactions (cobranza)
          this.arqueoService.cxcTransactions = cxc_transactions;
        }),
        switchMap(cxc_transactions => {
          // GET sales related to CXC transactions (cobranza)
          const sales_ids = cxc_transactions.map((transaction: {sale_id: string}) => transaction.sale_id);
          return this.arqueoService.getSalesFromIds(this.filters.store, sales_ids);
        }),
        tap(cxc_sales => {
          // SET CXC sales and transactions, sum cxc total
          this.setCXCSales(cxc_sales);
        }),
        switchMap(cxc_sales => {
          // GET customers for CXC transactions
          const customers_ids = cxc_sales.map((sale: {id_customer: string}) => sale.id_customer);
          return this.arqueoService.getCustomers(customers_ids);
        }),
        tap(cxc_customers => {
          // SET customers on CXC sales
          this.setCXCCustomers(cxc_customers);

          // SET sales persons totals
          this.setSalesPersonsTotals();

          // SET totals on global variables
          this.setSalesTotals();

          // SET payments totals, GC y VC totals
          this.setPayments();
          this.setPaymentsTotals(this.dayPayments);

          // Set corte de caja totals
          this.setCorteCaja();
        }),
        switchMap(() => {
          // GET exchange rate of selected date
          return this.arqueoService.getExchangeRate(this.filters.dateFormated);
        }),
        tap(rate => {
          // SET exchange rate of selected date
          this.exchangeRate = rate;
        })
      ).subscribe(() => {
        //console.log(`sales =>`, this.arqueoService.sales);
        //console.log(`cxc =>`, this.arqueoService.cxcSales);
        this.commonService.showSuccessMessage(`Arqueo generado correctamente`, this.messageService);
        this.loading = false;
        this.reportGenerated = true;
        this.filters.date.enable();
      }, error => {
        console.log('on error!');
        this.loading = false;
        this.commonService.showErrorMessage(error, this.messageService, 4500);
      });
    }
  }

  resetVariables() {
    this.reportGenerated = false;
    this.salesSum = {
      shipping: 0,
      tax: 0,
      net: 0,
      advance: 0,
      cxc: 0,
      discount: 0,
      saf: 0,
      scommission: 0,
      contract: 0,
      gcnc: 0,
      services: 0
    };
    this.salesPersons = {};
    this.cxcSum = 0;
    this.dayPayments = [];
    this.dayPaymentsSum = 0;
    this.paymentsTotals = [];
    this.salesTotals = {
      "sales": {label: 'Ventas', total: 0},
      "cxc": {label: 'Cobranza', total: 0},
      "sum": {label: 'Suma', total: 0}
    };
    this.sameDayPayments = [];
    this.sameDayPaymentsSum = 0;
    this.promoTransactions = [];
    this.promoSum = 0;
    this.exchangeRate = {price: '', date: ''};
    this.corteCaja = [];
    this.corteSum = 0;
    this.corteConciliacion = 0;
    this.cxcSumGC = 0;
    this.cxcFromDay = 0;
    this.promoSumCXC = 0;
    this.saf_deposits = {};
    this.saf_deposits_transactions = [];
    this.saldoTransactionsActiveSales = {};

    //Reset arqueo service data
    this.arqueoService.sales = [];
    this.arqueoService.cxcSales = [];

    this.reportGenerated = false;
    this.saldoTransactionsActiveSales = {};
    this.pdfLoading = false;
  }

  onShowPublicNote(sale: Sale) {
    this.publicNote = sale.public_note || "";
    this.showPublicNote = true;
  }

  onSearchSales() {
    this.filters.dateFormated = moment(this.filters.date.value).format("YYYY-MM-DD") || "";
    this.arqueoService.pendingLocalUpdate = false;
    this.getSales();
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  onSaveExchangeRate() {
    if (this.exchangeInput !== "") {
      this.confirmationService.confirm({
        message: `¿Está seguro de establecer el tipo de cambio en $${this.exchangeInput}?`,
        accept: () => {
          this.saveExchangeRate();
        }
      });
    }
  }

  saveExchangeRate() {
    this.exchangeSaving = true;
    this.arqueoService.setExchangeRate({date: this.filters.dateFormated, price: this.exchangeInput}).subscribe(
      (rate: ExchangeRate) => {
        if (rate) {
          this.exchangeRate = rate;
          this.commonService.showSuccessMessage(`Tipo de cambio guardado correctamente`, this.messageService);
        } else {
          this.commonService.showErrorMessage(`Algo ha salido mal, por favor inténtelo nuevamente`, this.messageService);
        }
        this.exchangeSaving = false;
        this.exchangeInput = "";
      }, (error) => {
        this.commonService.showErrorMessage(error, this.messageService);
        this.exchangeSaving = false;
        this.exchangeInput = "";
      }
    )
  }

  onEditExchangeRate() {
    if (this.exchangeRate?.price) {
      this.exchangeModifyInput = this.exchangeRate.price;
    }
    this.showEditExchangeRate = true;
  }

  confirmExchangeChange() {
    if (this.exchangeModifyInput == "") {
      return;
    }

    this.exchangeSaving = true;
    this.arqueoService.setExchangeRate({date: this.filters.dateFormated, price: this.exchangeModifyInput}).subscribe(
      (rate: ExchangeRate) => {
        if (rate) {
          this.exchangeRate = rate;
          this.commonService.showSuccessMessage(`Tipo de cambio guardado correctamente`, this.messageService);
        } else {
          this.commonService.showErrorMessage(`Algo ha salido mal, por favor inténtelo nuevamente`, this.messageService);
        }
        this.exchangeSaving = false;
        this.showEditExchangeRate = false;
        this.exchangeModifyInput = "";
      }, (error) => {
        this.commonService.showErrorMessage(error, this.messageService);
        this.exchangeSaving = false;
        this.showEditExchangeRate = false;
        this.exchangeModifyInput = "";
      }
    )
  }

  syncDataFromDate() {
    if (!this.arqueoService.syncingDate) {
      this.arqueoService.syncingDate = true;
      this.arqueoService.syncDate(this.filters.dateFormated, this.filters.store).subscribe(response => {
        this.commonService.showSuccessMessage(response.msg, this.messageService, 5000);

        if (this.arqueoService.SYNC_IMMEDIATELY && response.testeable) {
          //Execute a pending task inmediately
          this.arqueoService.DEV_syncDateNow(this.filters.store).subscribe(response => {
            //console.log(`pending task executed`, response);
          }, error => {
            this.commonService.showErrorMessage(error, this.messageService);
          });
        }

      }, error => {
        this.arqueoService.syncingDate = false;
        this.commonService.showErrorMessage(error, this.messageService);
      })
    }
  }

  onChangeStore() {
    this.arqueoService.syncingDate = false;
    this.commonService.currentStore$.next(this.filters.store);
    this.resetVariables();
  }

  onArqueoDateChange() {
    this.resetVariables();
  }

  onSpecialTypeChange(sale: Sale) {
    this.arqueoService.updateSpecialType(sale, sale.stype || '').subscribe(response => {
      this.arqueoService.sales.forEach(sale => {
        if (sale.id === response.sale.id) {
          sale.salesPersons = {};
          sale.samount = response.sale.samount || '0'
        }
      });
      this.salesPersons = {};
      this.setSalesTotals();
      this.setSalesPersonsTotals();
      this.commonService.showSuccessMessage('Venta actualizada correctamente', this.messageService);
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
    });
  }

  updateSAmount(sale: Sale) {
    this.arqueoService.setSamount(sale.id, sale.samount || '').subscribe(response => {
      this.arqueoService.sales.forEach(sale => {
        if (sale.id === response.sale.id) {
          sale.salesPersons = {};
          sale.samount = response.sale.samount || '0'
        }
      });
      this.salesPersons = {};
      this.setSalesTotals();
      this.setSalesPersonsTotals();
      this.commonService.showSuccessMessage(`Cantidad sin comisión actualizada a ${response.sale.samount}`, this.messageService);
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
    })
  }

  // sales calculations

  recalculateTotalWithTaxes(sales: Sale[]) {
    sales.filter((sale: Sale) => parseFloat(sale.tax || '0') > 0 || parseFloat(sale.tax2 || '0') > 0).forEach((sale: Sale) => {
      const sum = (parseFloat(sale.tax || '0') > 0) ? parseFloat(sale.total) + parseFloat(sale.tax || '0') : 
        parseFloat(sale.total) + parseFloat(sale.tax2 || '0');
      //console.log('Total + tax', `SUBTOTAL: ${sale.total} + ${sale.tax} | ${sale.tax2} = ${sum}`);
      sale.total = sum.toString();
    });
    return sales;
  }

  setWithoutCommissionSales() {
    // this.arqueoService.sales.filter(sale => sale.stype === 'scommission').forEach(sale => {
    //   sale.isScommission = true;
    // });
  }

  getDiscountPercent() {
    if (Math.abs(this.salesSum.discount) > 0 ) {
      const total = this.arqueoService.sales.filter(sale => sale.status !== 15 || sale.saf_cancelled_total).map(sale => {
        if (sale.saf_cancelled_total) return sale.saf_cancelled_total;
        return parseFloat(sale.items_price_no_dicount || '0');
      }).reduce((prev, curr) => {
        return prev + curr;
      }, 0);
      const discount = Math.abs(this.salesSum.discount);
      const percent = (discount * 100) / total;
      return `(${(percent < 1) ? percent.toFixed(1) : percent.toFixed(0)}%)`;
    }
    return '';
  }

  getSaleDiscountPercent(sale: Sale) {
    const discount = Math.abs(parseFloat(sale.discount || '0'));
    const total = (sale.saf_cancelled_total) ? sale.saf_cancelled_total : parseFloat(sale.items_price_no_dicount || '0');
    if (discount > 0) {
      const percent = (discount * 100) / total;
      return `(${(percent < 1) ? percent.toFixed(1) : percent.toFixed(0)}%)`;
    }
    return '';
  }

  setSalesCustomers(customers: Customer[]) {
    const customersHash = this.commonService.getHashFromArray(customers, 'sale_id');
    this.arqueoService.sales.forEach((sale: Sale) => {
      if (customersHash[sale.id_customer]) {
        sale.customer = customersHash[sale.id_customer];
      }
    });
  }

  setSalesItems(items: Item[]) {
    this.arqueoService.sales.forEach((sale: Sale) => {
      sale.items = items.filter(item => item.sale_id === sale.id_sb)
      //sale.items_other = sale.items.filter(item => item.type === 'other' && item.name?.toLowerCase().indexOf('discount') === -1);
      sale.items_other = sale.items.filter(item => item.type === 'other' && parseFloat(item.price) > 0);
      sale.items_price = sale.items.filter(item => item.type === 'item').map(item => item.price).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');
      //Changed to item.price_fixed to match invoice 6385 HDS
      const discount = sale.items.filter(item => parseFloat(item.price) < 0).map(item => parseFloat(item.price)).reduce((prev, curr) => {
        return prev + curr;
      }, 0).toFixed(2);
      sale.discount = discount;
      sale.items_price_no_dicount = sale.items_price;
      sale.items_price = (parseFloat(sale.items_price) + parseFloat(discount)).toString();
      //Sum of services
      if (sale.items_other.length > 0) {
        sale.items_other_sum = sale.items_other.map(item => parseFloat(item.price)).reduce((prev, curr) => {
          return prev + curr;
        }, 0);
      }

      //validate not greater than sale total
      if (parseFloat(sale.items_price) > parseFloat(sale.total)) {
        sale.items_price = sale.total;
      }
    });
  }

  setSalesTransactions(transactions: Transaction[]) {
    this.arqueoService.sales.forEach((sale: Sale) => {
      sale.transactions = transactions.filter(transaction => transaction.sale_id === sale.id_sb);
      sale.transactions_paid_same_date = sale.transactions.filter(transaction => moment(transaction.date).format("YYYY-MM-DD") === this.filters.dateFormated && parseFloat(transaction.amount || '0') > 0 );
      sale.paid_same_date = sale.transactions_paid_same_date.map(trans => trans.amount).reduce((prev, curr) => {
        const sum = parseFloat(prev) + parseFloat(curr);
        return sum.toString();
      }, '0');
      if (sale.status !== 15) {
        if (parseFloat(sale.paid_same_date).toFixed(2) !== parseFloat(sale.total).toFixed(2)) {
          if (sale.transactions_paid_same_date.length === 0) {
            //all is cxc
            sale.cxc = sale.total;
          } else {
            sale.paid_in_advance = sale.paid_same_date;
            sale.cxc = (parseFloat(sale.total) - parseFloat(sale.paid_same_date)).toString();
          }
        }

        //Cambio de lógica para buscar abonos no solo anticipos del mismo día
        // if (parseFloat(sale.paid) < parseFloat(sale.total)) {
        //   if (parseFloat(sale.paid)) {
        //     sale.paid_in_advance = sale.paid;
        //     sale.cxc = (parseFloat(sale.total) - parseFloat(sale.paid)).toString();
        //   } else {
        //     sale.cxc = sale.total; //all is cxc
        //   }
        // }

        //Calculate total paid with NC/GC
        // Se vio con Hector que al pagar con NC/GC la venta se refleja como venta normal en la columna de la vendedora
        // let totalPromo = 0;
        // for (let transaction of sale.transactions_paid_same_date || []) {
        //   if (this.isPromo(transaction)) {
        //     totalPromo += parseFloat(transaction.amount);
        //   }
        // }
        // sale.totalPromo = parseFloat(totalPromo.toFixed(2));
      }
    });
  }

  setCXCSales(cxc_sales: Sale[]) {
    cxc_sales.forEach((sale: Sale) => {
      sale.transactions = this.arqueoService.cxcTransactions.filter(transaction => transaction.sale_id == sale.id_sb);
      this.cxcSum += sale.transactions.filter(transaction => !this.isPromo(transaction)).map(transaction => parseFloat(transaction.amount)).reduce((prev, curr) => {
        return prev + curr;
      }, 0);
      this.cxcSumGC += sale.transactions.filter(transaction => this.isPromo(transaction)).map(transaction => parseFloat(transaction.amount)).reduce((prev, curr) => {
        return prev + curr;
      }, 0);
    });
    this.arqueoService.cxcSales = cxc_sales;
    this.arqueoService.cxcTransactions = [];
  }

  setCXCCustomers(cxc_customers: Customer[]) {
    const customersHash = this.commonService.getHashFromArray(cxc_customers, 'sale_id');
    this.arqueoService.cxcSales.forEach((sale: Sale) => {
      if (customersHash[sale.id_customer]) {
        sale.customer = customersHash[sale.id_customer];
      }
    });
  }

  setSalesPersonsTotals() {
    const filterNoCancelled = (sale: Sale) => sale.status !== 15 && sale.items_price && parseFloat(sale.items_price) > 0 && sale.stype !== 'contract';

    //Set sales persons sums
    for (let sale of this.arqueoService.sales.filter(filterNoCancelled)) {

      if ((sale.totalPromo || 0) > parseFloat(sale.items_price || '0')) {
        continue;
      }

      let item_price = parseFloat(sale.items_price || '0') - (sale.totalPromo || 0);

      if (sale.stype === 'scommission') {
        const samount = parseFloat(sale.samount || '0');
        if (!sale.samount || samount === 0 || sale.samount == sale.total) {
          continue;
        }
        //console.log(item_price, sale.items_price);
        item_price = parseFloat(sale.items_price || '0') - samount;
      }

      if (sale.attention.indexOf('/') !== -1) {
        //shared sale
        let persons = sale.attention.split('/');
        //const amount = (parseFloat(sale.items_price || '0') / 2).toFixed(2);
        const amount = (item_price / persons.length).toFixed(2);
        for (let person of persons) {
          if (!this.salesPersons[person]) this.salesPersons[person] = 0;
          this.salesPersons[person] += parseFloat(amount);
          sale.salesPersons[person] = amount;
        }
      } else {
        if (!this.salesPersons[sale.attention]) this.salesPersons[sale.attention] = 0;
        
        this.salesPersons[sale.attention] += item_price;
        sale.salesPersons[sale.attention] = item_price.toString();
        // this.salesPersons[sale.attention] += parseFloat(sale.items_price || '0');
        // sale.salesPersons[sale.attention] = sale.items_price || '';
        
      }
    }
  }

  setSalesTotals() {
    const filterNoCancelled = (sale: Sale) => sale.status !== 15;

    //Set sums
    const sum_net = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.total)).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_shipping = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.shipping_fee || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_tax = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.tax || '0') + parseFloat(sale.tax2 || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_advance = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.paid_in_advance || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_cxc = this.arqueoService.sales.filter(filterNoCancelled).map(sale => parseFloat(sale.cxc || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_discount = this.arqueoService.sales.filter(filterNoCancelled).filter(sale => parseFloat(sale.total) > 0).map(sale => parseFloat(sale.discount || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_saf_cancelled = this.arqueoService.sales.filter(sale => sale.saf_cancelled_total).map(sale => sale.saf_cancelled_total || 0).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_saf = this.arqueoService.sales.filter(sale => sale.saf_total).map(sale => sale.saf_total || 0).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_scommission = this.arqueoService.sales.filter(sale => sale.stype === 'scommission').map(sale => parseFloat(sale.samount || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_contract = this.arqueoService.sales.filter(sale => sale.stype === 'contract').map(sale => parseFloat(sale.total || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_services = this.arqueoService.sales.filter(sale => sale.items_other_sum || 0 > 0).map(sale => sale.items_other_sum || 0)
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    const sum_ncgc = this.arqueoService.sales.filter(sale => sale.totalPromo || 0 > 0).map(sale => sale.totalPromo || 0)
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    this.cxcFromDay = this.arqueoService.sales.filter(sale => parseFloat(sale.cxc || '0') > 0).map(sale => parseFloat(sale.cxc || '0')).reduce((prev, curr) => {
      return prev + curr;
    }, 0);
    this.salesSum = {
      shipping: sum_shipping,
      tax: sum_tax,
      net: sum_net,
      advance: sum_advance,
      cxc: sum_cxc,
      discount: sum_discount,
      saf: sum_saf_cancelled + sum_saf,
      scommission: sum_scommission,
      contract: sum_contract,
      gcnc: sum_ncgc,
      services: sum_services
    };

    //Sum SAF to net sum
    this.salesSum.net += sum_saf_cancelled;

    this.salesTotals["sales"] = {
      label: "Ventas",
      total: this.salesSum.net
    };
    this.salesTotals["cxc"] = {
      label: "Cobranza",
      total: this.cxcSum
    };
    this.salesTotals["sum"] = {
      label: "Suma",
      total: this.salesSum.net + this.cxcSum
    };
    //console.log(this.salesTotals);
  }

  setPayments() {
    //Day sales
    this.arqueoService.sales.filter(sale => (sale.status !== 15 || sale.saf_cancelled_total) ).forEach(sale => {
      sale.transactions_paid_same_date?.filter(trans => parseFloat(trans.amount) > 0).forEach(trans => {
        trans.invoice = sale.invoice;
        trans.client = sale.customer?.name || '';
        if (!this.isPromo(trans)) {
          this.dayPayments.push(trans);
          this.sameDayPayments.push(trans);
          this.sameDayPaymentsSum += parseFloat(trans.amount);
        } else {
          //console.log('promo', sale);
          this.promoTransactions.push(trans);
          this.promoSum += parseFloat(trans.amount);
        }
      });
    });
    //CXC
    this.arqueoService.cxcSales.filter(sale => sale.status !== 15).forEach(sale => {
      sale.transactions.filter(trans => parseFloat(trans.amount) > 0).forEach(trans => {
        trans.invoice = sale.invoice;
        trans.client = sale.customer?.name || '';
        if (!this.isPromo(trans)) {
          this.dayPayments.push(trans);
          //Show cxc on payments table
          this.sameDayPayments.push(trans);
          this.sameDayPaymentsSum += parseFloat(trans.amount);
        } else {
          //console.log('cxc promo', trans);
          this.promoTransactions.push(trans);
          this.promoSumCXC += parseFloat(trans.amount);
        }
      })
    });
  }

  isPromo(trans: Transaction) {
    let isPromo = false;
    for (let key of ['coupon', 'gc', ' nc', 'vc', 'vale']) {
      if (trans.reference?.toLowerCase().indexOf(key) !== -1){
        isPromo = true;
        break;
      }
    }
    return isPromo;    
  }

  setPaymentsTotals(transactions: Transaction[]) {
    let hash: {[key: string]: number} = {};
    transactions.forEach((transaction: Transaction) => {
      if (transaction.reference) {
        const ref = transaction.reference.toLowerCase();
        if (!hash[ref]) {
          hash[ref] = 0;
        }
        hash[ref] += parseFloat(transaction.amount);
      }
    });
    for (let ref in hash) {
      this.paymentsTotals.push({
        type: ref,
        total: hash[ref]
      });
      this.dayPaymentsSum += hash[ref];
    }
  }

  setSaldoToCancelledInvoices(transactions: SaldoTransaction[]) {
    //filter only deposits
    transactions = transactions.filter(trans => trans.type.toString() === 'deposit');
    if (transactions.length > 0) {

      //let hash: {[key: number]: Transaction[]} = {};
      let hash: {[key: number]: any} = {};
      for (let transaction of transactions) {
        // if (!hash[transaction.invoice]) hash[transaction.invoice] = [];
        // hash[transaction.invoice].push({
        //   invoice: transaction.invoice,
        //   amount: transaction.amount,
        //   date: transaction.date,
        //   reference: `SAF: ${transaction.reference}`,
        //   client: transaction.client_name,
        //   sale_id: '0',
        //   type: 'deposit'
        // });
        let origin = transaction.origin;
        if (origin) {
          if (!this.saf_deposits[origin]) {
            this.saf_deposits[origin] = [];
          }
          if (!hash[transaction.invoice]) hash[transaction.invoice] = {total: 0, trans: []};
          let trans = {
            invoice: transaction.invoice,
            amount: transaction.amount,
            date: transaction.date,
            reference: `SAF: ${transaction.reference}`,
            client: transaction.client_name,
            sale_id: '0'
          };
          this.saf_deposits[origin].push(trans);
          this.saf_deposits_transactions.push(trans);
          hash[transaction.invoice]['total'] += parseFloat(transaction.amount);
          hash[transaction.invoice]['trans'].push(trans);
        }
      }

      this.arqueoService.sales.filter(sale => sale.status === 15).forEach(sale => {
        if (hash[sale.invoice]) {
          sale.saf_cancelled_total = hash[sale.invoice]['total'];
          sale.saf_transactions = hash[sale.invoice]['trans'];
        }
      });


    //   this.arqueoService.sales.filter(sale => sale.status === 15).forEach(sale => {
    //     if (hash[sale.invoice]) {
    //       sale.transactions.push(...hash[sale.invoice]);
    //       sale.transactions_paid_same_date?.push(...hash[sale.invoice]);
          
    //       let saf_total = 0;
    //       // sale.transactions.filter(trans => trans.type && trans.type === 'deposit').forEach(trans => {
    //       //   saf_total += parseFloat(trans.amount);
    //       // });
    //       for (let transaction of sale.transactions) {
    //         saf_total += parseFloat(transaction.amount);
    //       }
    //       sale.saf_total = saf_total;
    //     }
    //   });
    }
  }

  setSaldoTransactions(transactions: SaldoTransaction[]) {
    transactions.filter(trans => trans.type === SaldoType.deposit && trans.origin === SaldoOrigin.vc).forEach(transaction => {
      if (transaction.origin) {
        if (!this.saldoTransactionsActiveSales[transaction.invoice]) this.saldoTransactionsActiveSales[transaction.invoice] = [];
        // let trans = {
        //   invoice: transaction.invoice,
        //   amount: transaction.amount,
        //   date: transaction.date,
        //   reference: `SAF: ${transaction.reference}`,
        //   client: transaction.client_name,
        //   sale_id: '0'
        // };
        this.saldoTransactionsActiveSales[transaction.invoice].push(transaction);
      }
    });

    this.arqueoService.sales.forEach(sale => {
      if (this.saldoTransactionsActiveSales[sale.invoice]) {
        if (!sale.saf_transactions) sale.saf_transactions = [];
        let saf_total = sale.saf_total || 0;
        for (let trans of this.saldoTransactionsActiveSales[sale.invoice].filter(saldo => saldo.origin === SaldoOrigin.vc)) {
          saf_total += parseFloat(trans.amount);
          sale.saf_transactions.push({
            invoice: trans.invoice,
            amount: trans.amount,
            date: trans.date,
            reference: `SAF: ${trans.reference}`,
            client: trans.client_name,
            sale_id: '0'
          });
        }
        sale.saf_total = saf_total;
        sale.total = (parseFloat(sale.total) + saf_total).toString();
      }
    });
  }

  setCorteCaja() {
    let promoSum = this.promoSum + this.salesSum['saf'];
    this.corteCaja = [
      {label: 'Cuentas x cobrar', amount: this.cxcFromDay},
      {label: 'GC / NC / VC', amount: promoSum}
    ];
    for (let payment of this.paymentsTotals) {
      this.corteCaja.push({
        label: payment.type,
        amount: payment.total
      });
    }
    this.corteSum = this.cxcFromDay + promoSum + this.dayPaymentsSum;
    this.corteConciliacion = parseFloat(this.salesTotals['sum']['total']) - this.corteSum;
    //this.corteConciliacion = parseFloat(this.salesTotals['sum']['total']) - this.corteSum + this.salesSum['saf'];
    //console.log('Corte sum', this.corteSum);
    //console.log('TOTAL SAF', this.salesSum['saf']);
  }

  downloadArqueoPDF() {
    if (!this.pdfLoading) {
      this.pdfLoading = true;
      let el = document.querySelector('#arqueo-wrapper') as HTMLElement;
      let self = this;
      html2canvas(el).then(function(canvas) {
        let dataURL = canvas.toDataURL("image/png");

        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'cm',
          format: 'letter'
        });

        //dataURL = doc.output('datauristring');

        const now = moment().format("D MMM yyyy");
        const reportDate = moment(self.filters.date.value).format("D-MMMM-yyyy");
        const reportDateArqueo = moment(self.filters.date.value).format("D MMMM yyyy");

        doc.addImage(dataURL, 'PNG', .5, .5, 20, 26);
        doc.setFontSize(9);
        doc.text(`${self.filters.store} - Reporte generado el ${now.toUpperCase()} (Arqueo del ${reportDateArqueo})`, 1, 27);
        doc.save(`${self.filters.store}-arqueo-${reportDate}`);

        self.pdfLoading = false;
        //document.body.appendChild(canvas);
      });
    }
  }

  ngOnDestroy(): void {
    this.aliveSubscription?.unsubscribe();
  }
}
