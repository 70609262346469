import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Agenda, AgendaStatus, AgendaSubType, AgendaType } from '../../rtm.enums';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { CommonService } from 'src/app/shared/common.service';
import { Item } from 'src/app/shared/common.enums';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'hds-agenda-table',
  templateUrl: './agenda-table.component.html',
  styleUrls: ['./agenda-table.component.css']
})
export class AgendaTableComponent implements OnInit {

  @Input() agendas: Agenda[] = [];
  @Input() items: Item[] = [];
  @Input() loading = false;
  @Input() alert!: AlertV2Component;
  @Input() paginator = true;
  @Input() showTotalItems = false;
  @Input() showInvoice = true;
  @Input() dispatched = false;
  @Input() msgService?: MessageService;
  @Output() statusDone = new EventEmitter();
  @Output() refresh = new EventEmitter();

  agendaTypesEnum = AgendaType;
  agendaSubTypesEnum = AgendaSubType;
  agendaStatusEnum = AgendaStatus;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  onAgendaDone(agenda: Agenda) {
    const listedAgenda = this.agendas.find(a => a.id === agenda.id);
    if (listedAgenda) {
      listedAgenda.status = agenda.status;
    }
    this.commonService.showSuccessMessage(`Agenda completada correctamente`, this.msgService);
    this.statusDone.emit();
  } 

  onActionError(error: string) {
    this.commonService.showErrorMessage(error, this.msgService);
  }

  onDateConfirmed(agenda: Agenda) {
    const listedAgenda = this.agendas.find(a => a.id === agenda.id);
    if (listedAgenda) {
      listedAgenda.expected_date = agenda.expected_date;
      listedAgenda.expected_time = agenda.expected_time;
      listedAgenda.status = agenda.status;
    }
    this.commonService.showSuccessMessage(`Fecha promesa confirmada correctamente`, this.msgService);
  }

  onAgendaDeleted(agenda: Agenda) {
    this.commonService.showSuccessMessage(`Agenda eliminada correctamente`, this.msgService);
    this.refresh.emit();
  }

}
