import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { RtmService } from '../rtm.service';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { Store, Stores } from 'src/app/shared/common.enums';
import { faListAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Invoice } from '../rtm.enums';
import { DialogService } from 'primeng/dynamicdialog';
import { RtmInvoiceComponent } from './rtm-invoice/rtm-invoice.component';

@Component({
  selector: 'app-rtm-invoices',
  templateUrl: './rtm-invoices.component.html',
  styleUrls: ['./rtm-invoices.component.scss'],
  providers: [
    DialogService
  ]
})
export class RtmInvoicesComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  currentStore?: Store;
  invoicesIcon = faListAlt;
  fullSize: boolean = false;
  loading = false;
  loadingIcon = faSpinner;
  invoices: Invoice[] = [];

  filters = {
    store: Store.hds,
    invoice: ""
  };
  stores = [...Stores];

  constructor(
    private rtmService: RtmService,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    this.commonService.currentStore$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(store => {
      this.currentStore = store;
      this.filters.store = store;
    });
    this.commonService.fullSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(fullSize => {
      this.fullSize = fullSize;
    });
  }

  ngOnInit(): void {
    this.getInvoices();
  }

  getInvoices() {
    this.loading = true;
    this.rtmService.getInvoices(this.currentStore!, this.filters.invoice).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: invoices => {
        this.invoices = invoices;
        this.loading = false;
      },
      error: errorMessage => {
        this.commonService.showErrorMessage(errorMessage);
        this.loading = false;
      }
    })
  }

  onOpenInvoiceModal(invoice: Invoice) {
    const ref = this.dialogService.open(RtmInvoiceComponent, {
      data: {
        invoice
      },
      header: `Invoice ${invoice.invoice}`,
      width: '80%'
    });
    ref.onClose.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      //this.getInvoices();
    });
  }

  onUpdateInvoices() {
    this.getInvoices();
  }

  onChangeStore() {
    this.commonService.currentStore$.next(this.filters.store);
    this.getInvoices();
  }

  onSizeChange() {
    this.commonService.fullSize$.next(!this.fullSize);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
