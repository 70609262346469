import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtmComponent } from './rtm.component';
import { RtmService } from './rtm.service';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { RtmAgendaComponent } from './rtm-agenda/rtm-agenda.component';
import { RtmInvoicesComponent } from './rtm-invoices/rtm-invoices.component';
import { RtmInvoiceComponent } from './rtm-invoices/rtm-invoice/rtm-invoice.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { PickListModule } from 'primeng/picklist';
import { InputNumberModule } from 'primeng/inputnumber';
import { MatStepperModule } from '@angular/material/stepper';
import { AgendaEntregaComponent } from './rtm-agenda/agenda-entrega/agenda-entrega.component';
import { AgendaActionsComponent } from './rtm-agenda/agenda-actions/agenda-actions.component';
import { AgendaItemsDetailsComponent } from './rtm-agenda/agenda-items-details/agenda-items-details.component';
import { RtmTemplatesComponent } from './rtm-templates/rtm-templates.component';
import { TemplateModalComponent } from './rtm-templates/template-modal/template-modal.component';
import { QuillModule } from 'ngx-quill';
import { AgendaConfirmarFechaComponent } from './rtm-agenda/agenda-actions/agenda-confirmar-fecha/agenda-confirmar-fecha.component';
import { AgendaAddButtonComponent } from './rtm-agenda/agenda-add-button/agenda-add-button.component';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { RtmTemplateFieldComponent } from './rtm-templates/rtm-template-field/rtm-template-field.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AgendaTableComponent } from './rtm-agenda/agenda-table/agenda-table.component';
import { AgendaInvoicesStepperComponent } from './rtm-agenda/agenda-invoices-stepper/agenda-invoices-stepper.component';

export const rtmRoutes: Routes = [
  {
    path: 'agenda', canActivate: [AuthGuard], component: RtmComponent, children: [
      { path: 'lista', canActivate: [AuthGuard], component: RtmAgendaComponent },
      { path: 'invoices', canActivate: [AuthGuard], component: RtmInvoicesComponent },
      { path: 'plantillas', canActivate: [AuthGuard], component: RtmTemplatesComponent }
    ]
  }
]

@NgModule({
  declarations: [
    RtmComponent,
    RtmAgendaComponent,
    RtmInvoicesComponent,
    RtmInvoiceComponent,
    AgendaEntregaComponent,
    AgendaActionsComponent,
    AgendaItemsDetailsComponent,
    RtmTemplatesComponent,
    TemplateModalComponent,
    AgendaConfirmarFechaComponent,
    AgendaAddButtonComponent,
    RtmTemplateFieldComponent,
    AgendaTableComponent,
    AgendaInvoicesStepperComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    OverlayPanelModule,
    ListboxModule,
    PickListModule,
    InputNumberModule,
    MatStepperModule,
    MatListModule,
    QuillModule,
    DragDropModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatMenuModule,
    NgxMatTimepickerModule
  ],
  providers: [
    RtmService
  ]
})
export class RtmModule { }
