import { Component, OnInit } from '@angular/core';
import { AlertButton, AlertService, ConfirmationAlert } from '../alert/alert.service';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-alert-v2',
  templateUrl: './alert-v2.component.html',
  styleUrls: ['./alert-v2.component.css']
})
export class AlertV2Component implements OnInit {

  faSpinner = faSpinner;
  showAlert: Boolean = false;

  size: string = "modal-md";
  showFooter: boolean = false;
  title: string = "";
  loading: boolean = false;
  body: string = "";
  title_icon?: any = null;
  title_color: string = "";
  syncing: boolean = false;

  faCheck = faCheckCircle;
  faTimesCircle = faTimesCircle;

  buttons: AlertButton[] = [];

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
  }

  setConfirmAlertData(data: ConfirmationAlert) {
    this.size = "modal-md";
    this.title = data.title;
    this.body = data.body;
    this.buttons = [
      {
          title: data.cancelLabel || "Cancelar",
          btn_class: "btn-default",
          onClick: ()=>{
              this.closeAlert(data.onClose);
          },
          focus: false
      }
    ];
    if (data.secondaryLabel) {
      this.buttons.push({
        title: data.secondaryLabel,
        btn_class: "btn-warning",
        onClick: () => {
          if (data.onSecondaryAction) {
            this.syncing = true;
            data.onSecondaryAction();
          }
        },
        focus: false
      });
    }
    this.buttons.push({
      title: data.confirmLabel,
      btn_class: data.confirmDanger ? "btn-danger": "btn-primary",
      onClick: () => {
          if( data.onConfirm ){
              this.syncing = true;
              data.onConfirm();
          }
      },
      focus: data.confirmFocus || false
    });
    this.showFooter = true;
    this.showAlert = true;
  }

  closeAlert(onClose?: any, forceClose: boolean = false){
    if (!this.syncing || forceClose) {
      if (onClose) {
        onClose();
      }
      this.resetAlert();
    }
  }

  resetAlert(){
    this.size = "modal-md";
    this.showFooter = false;
    this.title = "";
    this.loading = false;
    this.body = "";
    this.title_icon = null;
    this.title_color = "";
    this.buttons = [];
    this.syncing = false;
    this.showAlert = false;
}

  onClose(){
      this.closeAlert();
  }

}
