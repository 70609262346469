import { AfterContentInit, Component, OnInit } from '@angular/core';
import { faHistory, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { HistoryRecord } from 'src/app/shared/common.enums';
import { CommonService } from 'src/app/shared/common.service';
import { HistoryService } from './history.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss', '../../shared/scss/structure.columns.scss'],
  providers: [MessageService]
})
export class HistoryComponent implements OnInit {

  records: HistoryRecord[] = [];
  loading = false;
  historyIcon = faHistory;
  faSpinner = faSpinner;
  moduleAccess = false;

  constructor(
    public historyService: HistoryService,
    private commonService: CommonService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.moduleAccess = this.authService.user.value?.access.includes("history:view") || false;
    if (this.moduleAccess) {
      this.getRecords();
    }
  }

  getRecords() {
    this.loading = true;
    this.historyService.getRecords().subscribe(records => {
      this.loading = false;
      this.records = records;
    }, error => {
      this.commonService.showErrorMessage(error, this.messageService);
      this.loading = false;
    });
  }

}
