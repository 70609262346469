import { Component, OnDestroy, OnInit } from '@angular/core';
import { faArrowDown, faArrowUp, faScaleUnbalancedFlip, faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';

import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as moment from 'moment';
import { Client, SaldoOriginLabels, SaldoTransaction, Store, Stores } from 'src/app/shared/common.enums';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ArqueoService } from '../arqueo.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SaldoAddComponent } from './saldo-add/saldo-add.component';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'arqueo-positive-balance',
  templateUrl: './arqueo.saldo.favor.component.html',
  styleUrls: ['./arqueo.saldo.favor.component.scss'],
  providers: [
    ConfirmationService,
    DialogService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class ArqueoPositiveBalanceComponent implements OnInit, OnDestroy {

  loading = false;
  balanceIcon = faScaleUnbalancedFlip;
  loadingIcon = faSpinner;
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };
  stores = [...Stores];
  searchText = "";
  customersNames: string[] = [];
  searchSubscription?: Subscription;
  search$: Subject<any> = new Subject();
  client = new FormControl('');
  searchClients: {[key: string]: any}[] = [];
  lastSearch = "";

  clients: Client[] = [];
  arrowUpIcon = faArrowUp;
  arrowDownIcon = faArrowDown;

  saldoOriginLabels = SaldoOriginLabels;
  saldoTotal: number = 0;

  constructor(
    public commonService: CommonService,
    private arqueoService: ArqueoService,
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
    moment.locale('es');

    this.initSearchSubscription();
    this.client.valueChanges.pipe(
      filter(name => name !== '')
    ).subscribe(name => {
      this.search$.next(name);
    });
    this.getClientsWithSaldo();
  }

  initSearchSubscription() {
    this.searchSubscription = this.search$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      switchMap((searchText: string) => this.arqueoService.quickSearchClient(searchText))
    ).subscribe((clients: {[key: string]: any}[]) => {
      this.searchClients = clients;
    }, error => {
      this.commonService.showErrorMessage(error);
    });
  }

  searchCustomer(event: any) {
    this.search$.next(event.query);
  }

  onSearchClient() {
    if (!this.loading) {
      if (this.client.value.id) {
        this.lastSearch = this.client.value.name;
      } else {
        this.lastSearch = "";
        this.client.setValue("");
      }
      this.updateClients();
    } else {
      this.commonService.showWarningMessage("Busqueda en curso, por favor espere");
    }
  }

  updateClients() {
    this.resetVariables();
    if (this.lastSearch === '') {
      this.getClientsWithSaldo();
    } else {
      //Update clients with filter
      this.getClientsWithSaldo(this.client.value.id);
    }
  }

  resetVariables() {
    this.saldoTotal = 0;
  }

  getClientsWithSaldo(client_id?: number) {
    this.loading = true;
    this.arqueoService.getClientsWithSaldo(client_id).pipe(
      map((clients: Client[]) => {
        clients.forEach(client => {
          client.transactionsSum = this.getTransactionsSum(client.transactions || []);
          this.saldoTotal += client.transactionsSum;
        });
        if (this.lastSearch === '') {
          return clients.filter(client => client.transactionsSum !== 0);
        }
        return clients;
      }),
    ).subscribe((clients: Client[]) => {
      //console.log(clients);
      this.clients = clients;
      this.loading = false;
    }, error => {
      this.loading = false;
      //silent error
    });
  }

  // getClientWithSaldo() {
  //   this.loading = true;
  //   this.arqueoService.getClientWithSaldo(this.client.value.id).subscribe((clients: Client[]) => {
  //     this.clients = clients;
  //     this.loading = false;
  //   }, error => {
  //     this.loading = false;
  //     this.commonService.showErrorMessage(error);
  //   });
  // }

  getTransactionIcon(transaction: SaldoTransaction): IconDefinition {
    if (transaction.type.toString()==='charge') {
      return this.arrowUpIcon;
    }
    return this.arrowDownIcon;
  }

  getTransactionsSum(transactions: SaldoTransaction[]): number {
    let total = 0;
    if (transactions && transactions.length > 0) {
      for (let transaction of transactions) {
        transaction.isCharge = (transaction.type.toString() === 'charge');
        const sign = (transaction.isCharge) ? -1 : 1;
        total += (parseFloat(transaction.amount) * sign);
      }
    }
    return total;
  }

  onChangeStore() {

  }

  displayClientName(client: any) {
    if (client) {
      return client.name;
    }
  }

  showAddViews() {
    const ref = this.dialogService.open(SaldoAddComponent, {
      header: 'Agregar transacción',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.updateClients();
    });
  }

  showEditViews(client: Client, transaction: SaldoTransaction) {
    const ref = this.dialogService.open(SaldoAddComponent, {
      data: {
        client: client,
        transaction: transaction
      },
      header: 'Modificar transacción',
      width: '70%'
    });
    ref.onClose.subscribe(() => {
      this.updateClients();
    });
  }

  printTransactions(client: Client) {
    
    let el = document.querySelector(`#client-${client.id}`) as HTMLElement;
    let width = 20;
    let height = (width * el.offsetHeight) / el.offsetWidth;

    html2canvas(el).then(function(canvas) {
      let dataURL = canvas.toDataURL("image/png");

      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'cm',
        format: [width + 4, height + 3]
      });

      //dataURL = doc.output('datauristring');

      const now = moment().format("D MMM yyyy");
      // const reportDate = moment(self.filters.date.value).format("D-MMMM-yyyy");
      // const reportDateArqueo = moment(self.filters.date.value).format("D MMMM yyyy");

      doc.addImage(dataURL, 'PNG', 1, 1, width, height);
      doc.setFontSize(9);
      doc.text(`Reporte generado el ${now.toUpperCase()}`, 1, height + 2);
      doc.save(`${client.name}.pdf`);

      //document.body.appendChild(canvas);
    });
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

}
