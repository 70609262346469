import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UsersService } from '../users.service';
import { CommonService } from 'src/app/shared/common.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Dpto } from 'src/app/shared/common.enums';
import { faEdit, faListAlt, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'primeng/dynamicdialog';
import { DptoFormComponent } from '../dpto-form/dpto-form.component';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';

@Component({
  selector: 'app-dptos-list',
  templateUrl: './dptos-list.component.html',
  styleUrls: ['./dptos-list.component.scss'],
  providers: [
    MessageService, DialogService
  ]
})
export class DptosListComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  loading = false;
  dptos: Dpto[] = [];
  listIcon = faListAlt;
  addIcon = faPlusCircle;
  editIcon = faEdit;
  loadingIcon = faSpinner;

  constructor(
    public usersService: UsersService, 
    public commonService: CommonService, 
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
    public dialogService: DialogService
  ) {
    this.usersService.dptos$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(dptos => {
      this.dptos = dptos;
    });
  }

  ngOnInit(): void {
    this._validateAccess();
    this.getDptos();
  }

  getDptos() {
    if (!this.loading) {
      this.loading = true;
      this.usersService.getDptos(true).pipe(
        takeUntil(this.destroy$)
      ).subscribe(dptos => {
        this.loading = false;
      }, error => {
        this.commonService.showErrorMessage(error, this.messageService);
        this.loading = false;
      });
    }
  }

  onAddDpto() {
    const ref = this.dialogService.open(DptoFormComponent, {
      header: 'Agregar departamento',
      width: '50%'
    });
    ref.onClose.subscribe(() => {
      //this.getDptos();
    });
  }

  onModifyDpto(dpto: Dpto) {
    const ref = this.dialogService.open(DptoFormComponent, {
      header: `${dpto.name}`,
      width: '50%',
      data: {
        dpto
      }
    });
    ref.onClose.subscribe(() => {
      //this.getDptos();
    });
  }

  private _validateAccess() {
    const access = this.authService.user.value?.access || [];
    if (!access.includes("users:manage-dptos")) {
      this.router.navigate(["/"]);
    }
  }

  ngOnDestroy(): void {
    this.usersService.dptos$.next([]);
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
