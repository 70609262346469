import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { TagsComponent } from './tags.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TagsAshleyComponent } from './tags-ashley/tags-ashley.component';
import { TagHdsComponent } from './tag-hds/tag-hds.component';
import { TagSleepComponent } from './tag-sleep/tag-sleep.component';
import { TagOutletComponent } from './tag-outlet/tag-outlet.component';
import { TagSleepTagsProducts } from './tag-sleep/tag-sleep-products/tag-sleep-products.component';
import { TagsProcessProdutService } from './tags-process-product.service';
import { TagsCSVService } from './tags-csv.service';
import { TagsEditProductComponent } from './tags-edit-product/tags-edit-product.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { TagsAshleyProductsComponent } from './tags-ashley/tags-ashley-products/tags-ashley-products.component';
import { TagHdsProductsComponent } from './tag-hds/tag-hds-products/tag-hds-products.component';
import { TagOutletProductsComponent } from './tag-outlet/tag-outlet-products/tag-outlet-products.component';

export const tagsRoutes: Routes = [
  {path:"tags", canActivate: [AuthGuard], component: TagsComponent, children:[
    {path: "Ashley", canActivate: [AuthGuard], component: TagsAshleyComponent},
    {path: "hds", canActivate: [AuthGuard], component: TagHdsComponent},
    {path: "outlet", canActivate: [AuthGuard], component: TagOutletComponent},
    {path: "sleep", canActivate:[AuthGuard], component: TagSleepComponent}
  ]}
];

@NgModule({
  declarations: [
    TagsAshleyComponent,
    TagHdsComponent,
    TagSleepComponent,
    TagOutletComponent,
    TagSleepTagsProducts,
    TagsEditProductComponent,
    TagsAshleyProductsComponent,
    TagHdsProductsComponent,
    TagOutletProductsComponent  
  ],
  imports: [
    RouterModule.forChild(tagsRoutes),
    CommonModule,
    SharedModule,
    DynamicDialogModule
  ],
  exports: [RouterModule],
  providers: [
    TagsProcessProdutService,
    TagsCSVService,
    DialogService
  ],
  entryComponents: [
    TagSleepTagsProducts,
    TagsEditProductComponent
  ]
})
export class TagsModule { }
