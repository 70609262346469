import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntregaData } from '../../rtm.enums';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'hds-agenda-invoices-stepper',
  templateUrl: './agenda-invoices-stepper.component.html',
  styleUrls: ['./agenda-invoices-stepper.component.scss']
})
export class AgendaInvoicesStepperComponent implements OnInit {

  @Input() invoicesData: EntregaData[] = [];
  @Input() selectedInvoiceId: number | null = null;
  @Output() invoiceSelected = new EventEmitter<EntregaData>();
  @Output() addNew = new EventEmitter();
  @Output() create = new EventEmitter();
  @Output() delete = new EventEmitter<EntregaData>();

  closeIcon = faTimesCircle;

  constructor() { }

  ngOnInit(): void {
  }

  onLoadData(invoiceData: any) {
    this.invoiceSelected.emit(invoiceData);
  }

  addNewInvoice() {
    this.addNew.emit();
  }

  createAgenda() {
    this.create.emit();
  }

  deleteInvoice(invoiceData: EntregaData) {
    this.delete.emit(invoiceData);
  }

}
